@import '../../../../css/mixins.scss';

.sla-by-bp-container {
  background: transparent;
  max-height: 700px;
  .chart-app-filter {
    display: flex;
    max-width: 100%;
    max-height: 41px;
    margin-bottom: 10px;
    .filter-item-left {
      display: flex;
      margin-right: auto;
      align-items: center;
      .group,
      .favorite {
        width: 18px;
        height: 18px;
      }
      .personal {
        width: 13px;
        height: 18px;
      }
      .all {
        font-size: 12px;
      }
      div {
        padding: 10px;
      }
      label {
        font-weight: bold;
      }
    }
    .filter-item-right {
      display: flex;
      margin-left: auto;
      div {
        margin: 4px;
      }
    }
    @include breakpoints(1070px, 1366px) {
      .filter-item-left {
        .group,
        .favorite {
          width: 17px;
          height: 17px;
        }
        .personal {
          width: 12px;
          height: 17px;
        }
        .all {
          font-size: 13px;
        }
        div {
          padding: 8px 9px;
        }
      }
    }
    @include breakpoints(480px, 1070px) {
      .filter-item-left {
        .group,
        .favorite {
          width: 15px;
          height: 15px;
        }
        .personal {
          width: 10px;
          height: 15px;
        }
        .all {
          font-size: 12px;
        }
        div {
          padding: 6px 7px;
        }
      }
    }
    @include breakpoints(240px, 480px) {
      .filter-item-left {
        .group,
        .favorite {
          width: 14px;
          height: 14px;
        }
        .personal {
          width: 10px;
          height: 14px;
        }
        .all {
          font-size: 11px;
        }
        div {
          padding: 5px 6px;
        }
      }
    }
  }
  .chart-bp-list {
    max-height: 490px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 25px;
    grid-column-gap: 30px;
    &.four,
    &.five {
      grid-template-columns: repeat(5, 1fr);
      @include breakpoints(1366px, 1700px) {
        grid-column-gap: 20px;
        .chart-bp-container {
          min-width: 200px;
          .bp-title {
            margin-top: 8px;
            padding: 8px;
            span {
              font-size: 15px;
            }
          }
          .bp-content {
            margin: 12px 0px 18px;
            .circle {
              margin-bottom: 20px;
              height: 90px;
              width: 95px;
              font-size: 30px;
              font-weight: 800;
            }
            .count {
              span {
                font-size: 19px;
                margin: 0px 8px;
              }
            }
          }
          .gauge-filter-container {
            margin: 0px 0px 23px;
            .group,
            .favorite {
              width: 17px;
              height: 17px;
            }
            .personal {
              width: 12px;
              height: 17px;
            }
            .all {
              font-size: 12px;
            }
            div {
              margin: 0px 10px;
            }
          }
        }
      }
    }
    &.three,
    &.four {
      grid-template-columns: repeat(4, 1fr);
      @include breakpoints(1070px, 1366px) {
        grid-column-gap: 20px;
        .chart-bp-container {
          min-width: 190px;
          .bp-title {
            margin-top: 6px;
            padding: 6px;
            span {
              font-size: 14px;
            }
          }
          .bp-content {
            margin: 10px 0px 15px;
            .circle {
              margin-bottom: 12px;
              height: 80px;
              width: 85px;
              font-size: 27px;
            }
            .count {
              span {
                font-size: 17px;
                margin: 0px 6px;
              }
            }
          }
          .gauge-filter-container {
            margin: 0px 0px 20px;
            .group,
            .favorite {
              width: 15px;
              height: 15px;
            }
            .personal {
              width: 10px;
              height: 15px;
            }
            .all {
              font-size: 12px;
            }
            div {
              margin: 0px 10px;
            }
          }
        }
      }
    }
    &.one,
    &.two,
    &.three {
      grid-template-columns: repeat(3, 1fr);
      @include breakpoints(480px, 1070px) {
        grid-column-gap: 20px;
        .chart-bp-container {
          min-width: 165px;
          max-width: 165px;
          .bp-title {
            margin-top: 3px;
            padding: 6px;
            span {
              font-size: 13px;
            }
          }
          .bp-content {
            margin: 10px 0px 15px;
            .circle {
              margin-bottom: 10px;
              height: 75px;
              width: 80px;
              font-size: 25px;
            }
            .count {
              span {
                font-size: 17px;
                margin: 0px 6px;
              }
            }
          }
          .gauge-filter-container {
            margin: 0px 0px 20px;
            .group,
            .favorite {
              width: 14px;
              height: 14px;
            }
            .personal {
              width: 9px;
              height: 14px;
            }
            .all {
              font-size: 11px;
            }
            div {
              margin: 0px 8px;
            }
          }
        }
      }
    }
    &.one,
    &.two {
      grid-template-columns: repeat(2, 1fr);
      .chart-bp-container {
        min-width: 160px;
        max-width: 160px;
      }
    }
    &.one {
      grid-template-columns: repeat(1, 1fr);
      @include breakpoints(240px, 480px) {
        grid-column-gap: 20px;
        .chart-bp-container {
          .bp-title {
            margin-top: 2px;
            padding: 6px;
            span {
              font-size: 12px;
            }
          }
          .bp-content {
            margin: 10px 0px 15px;
            .circle {
              margin-bottom: 10px;
              height: 70px;
              width: 75px;
              font-size: 22px;
            }
            .count {
              span {
                font-size: 17px;
                margin: 0px 6px;
              }
            }
          }
          .gauge-filter-container {
            margin: 0px 0px 20px;
            .group,
            .favorite {
              width: 14px;
              height: 14px;
            }
            .personal {
              width: 9px;
              height: 14px;
            }
            .all {
              font-size: 11px;
            }
            div {
              margin: 0px 7px;
            }
          }
        }
      }
    }
    .chart-bp-container {
      max-height: 370px;
      min-width: 220px;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(240, 240, 240, 1) 52%,
        rgba(239, 239, 239, 1) 66%,
        rgba(238, 238, 238, 1) 81%
      );
      .gauge-filter-container {
        display: flex;
        justify-content: center;
        margin: 20px 0px 25px;
        .group,
        .favorite {
          width: 18px;
          height: 18px;
        }
        .personal {
          width: 13px;
          height: 18px;
        }
        .all {
          font-size: 12px;
        }
        div {
          margin: 0px 10px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            margin-bottom: 10px;
            cursor: pointer;
          }
          label {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
      .bp-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        padding: 10px;
        .logo {
          width: 30px;
          height: 25px;
        }
        span {
          font-size: 16px;
        }
        .seq {
          height: 25px;
          position: relative;
          top: 3px;
        }
        .name {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 60%;
        }
      }
      .bp-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0px 25px;
        .count {
          span {
            margin: 0px 10px;
            font-size: 22px;
            font-weight: bolder;
          }
        }
        .circle {
          height: 100px;
          border-radius: 100%;
          margin-bottom: 20px;
          min-width: 40%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-weight: 900;
          font-size: 35px;
          color: #fff;
          width: 105px;
          cursor: pointer;
        }
      }
      .bp-color {
        height: 5px;
        width: 100%;
        display: flex;
        flex-direction: row;
        div {
          width: 100%;
        }
      }
    }
  }
}
