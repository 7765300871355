#label-config-modal {
  width: 450px;
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  background-color: #ffffff;
  z-index: 50;
  small {
    font-style: italic;
    color: #808080;
  }
  .bp-selected-header {
    padding: 8px 12px;
    background-color: #3a86a7;
    color: #ffffff;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
  }
  .btn-cancel {
    width: 20px;
    position: absolute;
    right: 20px;
    top: 17px;
    cursor: pointer;
  }
  .header-label {
    display: flex;
    justify-content: space-between;
    label.required {
      &::after {
        margin: -0.2em 0 0 0.2em;
        content: "*";
        color: #db2828;
      }
    }
  }
  .initial {
    border: 1px dashed #9c9c9c;
    background: #f8f8f8;
    min-height: 38px;
    max-height: 38px;
  }
  .content {
    padding: 10px !important;
    max-height: 650px;
    overflow-y: auto;
    .form {
      .field {
        label {
          margin: 5px 0px !important;
        }
      }
      input {
        background-color: #f3f3f3;
        border: none;
        height: 38px !important;
      }
    }
  }
  .header-container {
    margin-top: 10px;
    .header-label {
      .label {
        margin-right: 4px;
      }
    }
    .header-field {
      > label {
        font-weight: 700;
        color: #3a86a7;
        font-size: 13px;
      }
      &.error {
        border-color: #ff8585 !important;
      }
    }
  }
  .content-field-main {
    &:not(:first-child) {
      margin-top: 7px;
    }
    cursor: grab;
    .content-field-container {
      display: grid;
      grid-template-columns: auto 40px;
      grid-column-gap: 6px;
      .field-label {
        cursor: pointer;
        padding: 5px 11px;
        display: flex;
        align-items: center;
        border: 1px solid #0087a9;
        border-radius: 3px;
        .slash {
          position: absolute;
        }
      }
      .content-field {
        color: #3a86a7;
        padding: 7px;
        padding-right: 8px;
        border: 1px solid #3b85a8;
        border-radius: 3px;
        min-height: 35px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        img {
          width: 11px;
          height: 13px;
          cursor: grab;
        }
        img.close {
          width: 12px;
          height: 12px;
          margin-left: 10px;
          cursor: pointer;
        }
        label {
          color: #000000;
          margin-left: 8px;
          overflow: hidden;
          width: 90%;
          text-overflow: ellipsis;
          position: relative;
          top: 1px;
        }
      }
    }
  }
  .selected-field-container {
    margin-top: 15px;
    .header-label {
      color: #808080;
      font-size: 11px;
      .label {
        margin-right: 7px;
      }
    }
    .selected-field {
      max-height: 200px;
      overflow: auto;
      &.error {
        border-color: #ff8585 !important;
      }
    }
  }
  .metadata-container {
    margin-top: 15px;
    .header-label {
      color: #808080;
      font-size: 11px;
      .label {
        margin-right: 7px;
      }
    }
    .metadata {
      display: grid;
      grid-gap: 5px;
      .selection.dropdown {
        padding: 8px !important;
        margin-left: 0px !important;
        border-radius: 2px !important;
        border: 1px dashed #9c9c9c;
        width: 100%;
        &.error {
          background: #ff8585 !important;
        }
        div.text {
          top: 4px;
          white-space: nowrap;
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        input {
          padding: 8px !important;
        }
        .dropdown.icon {
          &.clear {
            &::before {
              font-size: 25px;
              content: "\00d7";
              right: 12px;
              top: 1px;
            }
          }
          &::before {
            position: relative;
            content: "";
          }
        }
        .menu {
          margin-top: 1px;
          max-height: 200px;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          &::-webkit-scrollbar {
            width: 7px !important;
          }
        }
      }
      .field-container {
        display: grid;
        grid-template-columns: calc(100% / 2 - 20px) 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        .metadata-fields {
          position: relative;
          .selection {
            max-width: 180px;
            &.active {
              .field-label.eye {
                right: 0px;
              }
            }
          }
          &.grid {
            display: grid;
            grid-template-columns: auto 40px;
            grid-column-gap: 5px;
            .field-label.eye {
              right: 40px;
            }
            .selection {
              max-width: 170px;
              &.active {
                .dropdown.icon {
                  &.clear {
                    &::before {
                      right: 0px;
                    }
                  }
                }
              }
              .dropdown.icon {
                &.clear {
                  &::before {
                    right: 10px;
                    top: 1px;
                  }
                }
              }
            }
          }
          .field-label.eye {
            cursor: pointer;
            padding: 8px 11px;
            display: flex;
            align-items: center;
            border-radius: 3px;
            justify-content: center;
            position: absolute;
            top: 5px;
            right: -1px;
            .slash {
              position: absolute;
            }
          }
          .field-label.del {
            cursor: pointer;
            padding: 6px 11px;
            display: flex;
            align-items: center;
            border: 1px dashed #9c9c9c;
            background: #f8f8f8;
            border-radius: 3px;
            justify-content: center;
            font-style: italic;
            color: #9c9c9c;
          }
        }
      }
      .btn-add {
        cursor: pointer;
        padding: 6px 11px;
        display: flex;
        align-items: center;
        border: 1px dashed #9c9c9c;
        background: #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        font-style: italic;
        color: #9c9c9c;
      }
    }

    .selected-field {
      &.initial {
        margin-top: 5px;
        max-height: 32px;
        min-height: 32px;
      }
      &.error {
        border-color: #ff8585 !important;
      }
    }
  }
  .content-fields-container,
  .header-fields-container {
    .selection.dropdown {
      margin-left: 0px !important;
      margin-right: 7px !important;
      border-radius: 2px !important;
      border: 1px dashed #9c9c9c;
      background: #f8f8f8;
      &.active {
        z-index: 15;
      }
      &.error {
        background: #ff8585 !important;
      }
      .menu {
        margin-top: 1px;
        .item {
          &.disabled {
            text-decoration: line-through; 
          }
        }
      }
      .dropdown.icon {
        &.clear {
          &::before {
            font-size: 25px;
            top: -3px;
            content: "\00d7";
            right: -5px;
          }
        }
        &::before {
          position: relative;
          content: "";
        }
      }
      a.ui.label {
        background: #4b6a77 !important;
        border-radius: 100px !important;
        color: #fff;
        font-weight: normal !important;
        font-size: 12px;
        padding: 7px 10px;
        .delete.icon {
          position: relative;
          top: -4px;
          float: right;
          &::before {
            content: "\00d7";
            font-size: 20px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .content-fields-container,
  .header-fields-container {
    margin-top: 5px;
    .field-draft {
      display: grid;
      grid-template-columns: auto 40px;
      grid-column-gap: 5px;
      &.selected {
        margin-top: 5px;
      }
    }
    .header-field {
      width: 100%;
    }
    .add {
      margin: unset;
      max-height: 38px;
      font-size: 13px;
      background-color: #3a86a7;
    }
  }
  .field-selection {
    display: flex;
    margin-top: 10px;
    margin-bottom: 5px;
    .selection.dropdown {
      margin-left: 0px !important;
      margin-right: 7px !important;
      background-color: #f3f3f3 !important;
      border: unset !important;
      border-radius: 2px !important;
      .dropdown.icon {
        &.clear {
          &::before {
            font-size: 15px;
            top: 2px;
          }
        }
        &::before {
          font-size: 20px;
          position: relative;
          top: 1px;
        }
      }
    }
    .add {
      min-width: 36px;
      max-height: 38px;
      font-size: 12px;
      background-color: #3a86a7;
    }
    &.multiple {
      a.ui.label {
        background: #4b6a77 !important;
        border-radius: 100px !important;
        color: #fff;
        font-weight: normal !important;
        font-size: 12px;
        padding: 7px 10px;
        .delete.icon {
          position: relative;
          top: -4px;
          float: right;
          &::before {
            content: "\00d7";
            font-size: 20px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .action {
    margin-top: 15px;
    button {
      float: right;
      padding: 7px 11px;
      width: 80px;
      color: white;
      &.btn-del {
        margin-right: 10px;
      }
    }
  }
  .toggle-checkbox-container {
    display: flex;
    display: flex;
    margin-top: 20px;
    .toggle-checkbox {
      display: flex;
      align-items: center;
      &:first-child {
        margin-right: 30px;
      }
    }
    .ui.toggle.checkbox {
      label:before {
        background-color: #c4c4c4 !important;
        width: 40px;
        height: 18px;
      }
      input:checked ~ label:before {
        background-color: #0089d0 !important;
      }
      input:checked ~ label:after {
        width: 17px;
        height: 16px;
        top: 1px;
        right: 5px;
        left: 1.56rem;
      }
      input ~ label:after {
        width: 17px;
        height: 16px;
        top: 1px;
        right: 2px;
        left: 1px;
      }
    }
  }
}
