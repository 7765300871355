@import '../../../../css/mixins.scss';

.sla-by-app-container {
  width: 100%;
  height: calc(100vh - 98px);
  &::-webkit-scrollbar {
    width: 6px;
  }
  .chart-app-container {
    display: grid;
    grid-template-columns: repeat(5, 280px);
    grid-row-gap: 25px;
    grid-column-gap: 30px;
    padding: 0;
    &.four,
    &.five {
      @include breakpoints(1700px, 1880px) {
        grid-template-columns: repeat(5, 235px);
        .chart-title {
          height: 75px !important;
          font-size: 19px !important;
        }
        .chart-app {
          height: 430px;
          .pie-chart {
            margin-top: -20px;
            margin-bottom: -15px;
          }
        }
      }
      @include breakpoints(1400px, 1700px) {
        grid-template-columns: repeat(5, 215px);
        grid-column-gap: 20px;
        .chart-title {
          height: 63px !important;
          font-size: 17px !important;
        }
        .chart-app {
          height: 370px;
          .pie-chart {
            margin-top: -35px;
            margin-bottom: -35px;
          }
          .gauge-filter-container {
            div {
              .group,
              .favorite {
                width: 19px;
                height: 19px;
              }
              .personal {
                width: 12px;
                height: 19px;
              }
              .all {
                font-size: 14px;
              }
              margin: 0px 10px;
              label {
                margin-bottom: 10px;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    &.three,
    &.four {
      grid-template-columns: repeat(4, 205px);
      grid-column-gap: 20px;
      @include breakpoints(1070px, 1400px) {
        grid-template-columns: repeat(4, 200px);
        .chart-title {
          height: 55px !important;
          font-size: 16px !important;
        }
        .chart-app {
          height: 310px;
          .pie-chart {
            margin-top: -45px;
            margin-bottom: -45px;
          }
          .gauge-filter-container {
            div {
              .group,
              .favorite {
                width: 18px;
                height: 18px;
              }
              .personal {
                width: 11px;
                height: 18px;
              }
              .all {
                font-size: 13px;
              }
              margin: 0px 8px;
              label {
                margin-bottom: 10px;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    &.one,
    &.two,
    &.three {
      grid-template-columns: repeat(3, 180px);
      grid-column-gap: 20px;
      grid-row-gap: 15px;
      @include breakpoints(690px, 1070px) {
        .chart-title {
          height: 50px !important;
          font-size: 14px !important;
        }
        .chart-app {
          height: 270px;
          .pie-chart {
            margin-top: -60px;
            margin-bottom: -55px;
          }
          .gauge-filter-container {
            div {
              .group,
              .favorite {
                width: 15px;
                height: 15px;
                margin-bottom: 4px !important;
              }
              .personal {
                width: 12px;
                height: 15px;
                margin-bottom: 4px !important;
              }
              .all {
                font-size: 12px;
                margin-bottom: 4px !important;
              }
              margin: 0px 7px;
              label {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    &.two {
      grid-template-columns: repeat(2, 170px);
    }
    &.one {
      grid-template-columns: repeat(1, 185px);
      grid-row-gap: 20px;
      @include breakpoints(320px, 690px) {
        .chart-title {
          height: 50px !important;
          font-size: 14px !important;
        }
        .chart-app {
          height: 270px;
          .pie-chart {
            margin-top: -60px;
            margin-bottom: -50px;
          }
          .gauge-filter-container {
            div {
              .group,
              .favorite {
                width: 16px;
                height: 16px;
                margin-bottom: 5px !important;
              }
              .personal {
                width: 13px;
                height: 16px;
                margin-bottom: 4px !important;
              }
              .all {
                font-size: 13px;
                margin-bottom: 4px !important;
              }
              margin: 0px 8px;
              label {
                font-size: 13px;
              }
            }
          }
        }
      }
      @include breakpoints(240px, 320px) {
        grid-template-columns: repeat(1, 160px);
        .chart-title {
          height: 45px !important;
          font-size: 13px !important;
        }
        .chart-app {
          height: 240px;
          .pie-chart {
            margin-top: -68px;
            margin-bottom: -64px;
          }
          .gauge-filter-container {
            div {
              .group,
              .favorite {
                width: 15px;
                height: 15px;
                margin-bottom: 4px !important;
              }
              .personal {
                width: 12px;
                height: 15px;
                margin-bottom: 4px !important;
              }
              .all {
                font-size: 12px;
                margin-bottom: 4px !important;
              }
              margin: 0px 7px;
              label {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  div {
    .chart-active {
      background: linear-gradient(
        180deg,
        rgba(196, 196, 196, 1) 43%,
        rgba(201, 201, 201, 1) 60%,
        rgba(219, 219, 219, 1) 83%
      ) !important;
      .chart-title {
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90px !important;
        font-size: 20px;
        font-weight: bolder;
        background-color: #2f2f2f;
      }
    }
    .chart-app {
      background: linear-gradient(
        180deg,
        rgba(240, 240, 240, 1) 52%,
        rgba(239, 239, 239, 1) 66%,
        rgba(238, 238, 238, 1) 81%
      );
      height: 560px;
      .chart-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75px;
        font-size: 19px;
        font-weight: bolder;
      }
      .pie-chart {
        z-index: -1;
      }
      .gauge-filter-container {
        display: flex;
        justify-content: center;
        * {
          z-index: 10;
        }
        div {
          .group,
          .favorite {
            width: 21px;
            height: 22px;
            margin-bottom: 10px;
          }
          .personal {
            width: 14px;
            height: 21px;
            margin-bottom: 10px;
          }
          .all {
            font-size: 15px;
          }
          margin: 0px 13px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          label {
            cursor: pointer;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 15px;
          }
          i {
            font-size: 8px;
            margin: unset !important;
            box-shadow: 0 0 0 0.1em rgb(0, 0, 0) inset !important;
          }
        }
      }
      .div-no-records {
        height: 260px;
        text-align: center;
        align-items: center;
        display: grid;
      }
    }
  }
}
.sla-by-bp-container {
  .chart-app-filter {
    .filter-item-left {
      div {
        img {
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
    }
  }
}
.slaByBp-table-container {
  .sla-table-header {
    div {
      .sla-filter-1 {
        img {
          cursor: pointer;
        }
        div {
          label {
            cursor: pointer;
            font-weight: bolder;
          }
        }
      }
    }
  }
}
