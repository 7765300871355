.form-builder-field-type {
  background-color: #3b86a8;
  border-bottom-left-radius: 5px;
  padding: 15px 13px;
  color: white;
  cursor: grab;
  overflow-y: auto;
  height: calc(100vh - 75px - 120px - 65px - 27px);
  .add-section {
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    span {
      border: 1px dashed #ffffff;
      width: 10px;
      color: transparent;
      border-radius: 5px;
    }
  }
  .field-type-tab {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    label {
      padding: 5px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    label.field {
      text-align: left;
    }
    label.references {
      text-align: right;
    }
    label.selected {
      text-decoration: underline;
    }
  }
  .field-type {
    margin-top: 5px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-size: 13px;
    .sequence-icon {
      width: 20px;
      color: #3b86a8;
    }
    > div.field-type-image {
      > img {
        width: 20px;
        height: 20px;
      }
      padding: 5px;
      float: left;
    }
    > div.field-type-title {
      padding: 5px;
      text-align: left;
      border-left: 2px solid #91b1c6;
      margin-left: 30px;
    }
  }
  .referenced-field-container {
    background-color: #ffffff;
    margin-top: 5px;
    border-radius: 10px;
    margin-left: -5px;
    margin-right: -5px;
    padding: 10px;
    min-height: 502px;
    .field-type {
      border: #3c86a8 solid 1px;
      div.field-type-title {
        color: #3c86a8;
      }
    }
    label {
      color: #3c86a8;
    }
  }
}
