@import "../../../../css/theme-variables";

#e-upload-file-list-wrap {
  line-height: unset;
  height: fit-content;
  font-size: 13px;
  width: fit-content;
  margin-top: 15px;
  border-radius: 2px;
  padding: 0px;
  max-width: 300px;
  min-width: 300px;
  &.empty {
    max-width: unset;
    min-width: unset;
    padding: 2px 2px 0px;
  }
}
#e-upload-file-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-family: inherit;
  font-size: 14px;
  height: 100%;
  line-height: normal;
  min-height: 60px;
  position: relative;
  &.empty {
    min-height: unset;
    padding: 4px 15px;
    font-size: 11px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .e-icons {
    font-family: "e-icons";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
    &.e-file-delete {
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-size: 11px;
      height: 18px;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0 4px 12px;
      margin-top: -19px;
      padding: 18px;
      position: absolute;
      right: 0;
      top: 50%;
      vertical-align: middle;
      width: 18px;
      font-size: 11px;
      opacity: 1;
      color: #000;
      &::before {
        content: "\e94a";
      }
    }
  }
  .e-file-container {
    display: block;
    height: 100%;
    min-height: 35px;
    position: relative;
    top: 3px;
    width: 90%;
    padding: 8px 12px;
    .e-file-name {
      color: rgba(0, 0, 0, 0.87);
      float: left;
      font-family: inherit;
      font-size: 14px;
      max-width: 85%;
      overflow: hidden;
      padding-top: 7px;
      position: relative;
      text-overflow: ellipsis;
      top: 0;
      white-space: nowrap;
      margin-right: 5px;
      &::before {
        content: attr(data-tail);
        float: right;
      }
    }
    .e-file-type {
      display: block;
      font-family: inherit;
      font-size: 10px;
      padding-top: 25px;
      color: rgba(0, 0, 0, 0.87);
      font-style: italic;
    }
    .e-file-size {
      color: rgba(0, 0, 0, 0.54);
      display: block;
      font-size: 11px;
      padding: 5px 0 10px;
    }
    .e-file-status {
      display: block;
      font-family: inherit;
      font-size: 11px;
      padding-bottom: 10px;
      &.e-upload-success {
        color: #22b24b;
      }
      &.e-upload-error {
        color: #e3165b;
      }
    }
    .react-sweet-progress {
      .react-sweet-progress-line {
        margin-bottom: 8px;
      }
      .react-sweet-progress-line-inner {
        max-height: 1px;
        min-height: 1px !important;
      }
      .react-sweet-progress-symbol {
        width: 20px;
        height: 20px;
        padding-left: 5px;
        padding-bottom: 5px;
        font-size: 10px;
        position: absolute;
        bottom: 15px;
        right: 25px;
      }
    }
  }
}
.data-tables-record-container {
  background-color: #ffffff;
  border-radius: 5px;
  max-width: 100%;
  height: calc(100vh - 75px - 86px - 68px);
  .e-pager {
    border-top: 0px;
    .e-numericcontainer {
      .e-numericitem.e-active {
        background-color: #3a85a8 !important;
        color: #ffffff !important;
      }
    }
  }
  .e-grid {
    &.batch-edit {
      .e-toolbar-items {
        .e-edit-btn {
          display: none;
        }
      }
    }
    .e-spinner-pane {
      display: none;
    }
    .e-input-group {
      &::after,
      &::before {
        background: #3a85a8 !important;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
    .e-toolbar-items {
      .e-import {
        transform: scaleX(-1);
      }
      .e-search-toolbar {
        position: absolute;
        right: 0;
      }
      .e-batch-btn {
        &.batch-edit {
          button {
            background-color: #0000002e !important;
          }
        }
        &.normal-edit {
          button {
            &:hover,
            &:focus {
              background: none !important;
            }
          }
        }
      }
      .e-toolbar-right {
        .e-clear-btn {
          position: absolute;
          right: 16px;
          bottom: 4px;
          min-width: unset !important;
          button {
            background-color: unset;
            .e-btn-icon {
              min-width: unset !important;
              min-height: unset !important;
            }
            .e-close {
              font-size: 11px;
              &:hover {
                filter: alpha(opacity=75);
                opacity: 0.75;
              }
              &.e-focus {
                color: #000 !important;
              }
            }
          }
        }
      }
    }
    .e-gridcontent {
      max-height: calc(100vh - 75px - 86px - 350px);
      overflow: auto;
    }
    .e-pagesizes {
      .e-pagerdropdown {
        input.e-input {
          min-height: 18px !important;
        }
      }
    }
    .e-gridheader {
      padding-right: unset !important;
      .e-sortfilterdiv {
        color: #fff !important;
      }
      .e-headercontent {
        border-right-width: 0px !important;
        .e-headercell {
          background-color: #3a85a8 !important;
          font-size: 13px !important;
          color: #ffffff !important;
        }
      }
      .e-filterbar {
        .e-filterbarcell {
          position: relative;
          input {
            border-bottom: 0px;
          }
        }
        .filter-icon {
          position: absolute;
          font-size: 18px !important;
          top: 30%;
          left: 5%;
          color: rgba(0, 0, 0, 0.54) !important;
        }
      }
    }
    .e-rowcell {
      position: relative;
      &.not-allowed {
        cursor: not-allowed;
      }
      .field-enum-container {
        max-width: 90%;
      }
      .e-chip-list {
        border: 1px solid #457b90 !important;
        &.e-chip {
          height: 20px !important;
          padding: 0px !important;
          width: fit-content;
          margin: 2px !important;
        }
        .e-chip {
          background-color: #d4e0e7;
          margin: 2px;
          font-size: 12px !important;
        }
      }
      .e-chip-list-wrap {
        display: inline-block;
        width: fit-content;
        .show-more {
          font-size: 11px;
          cursor: pointer;
          margin-left: 8px;
        }
      }
    }
    .tooltip-error {
      position: absolute;
      left: 50%;
      top: 50%;
    }
    .file-upload-wrap {
      border: 1px dashed #c3c3cc;
      text-align: center;
      min-width: 250px;
      .dropzone-area {
        padding: 0 !important;
        position: relative;
        top: 0;
        left: 0;
        .dropzone-wrap {
          width: unset;
          border-radius: 12px;
          border: 2px dashed #fff;
          min-height: 28px;
          flex-direction: row !important;
          min-width: 250px;
          label {
            margin-right: 5px;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.7;
          }
        }
        .file-cnt {
          font-size: 10px;
          position: absolute;
          top: -3px;
          left: -20px;
          z-index: 1;
          cursor: pointer;
          font-weight: 500;
        }
        .btn-upload {
          padding: 3px 10px 1px !important;
          font-size: 12px !important;
          margin-left: auto;
        }
        .e-camera-btn {
          position: absolute;
          right: 6px;
          cursor: pointer;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .data-tables-record-section {
    margin: 0px 20px !important;
    overflow: unset;
  }
  .table-record-heading {
    margin-bottom: 5px;
    padding-bottom: 5px;
    .e-arrow-left {
      color: #3a85a8;
      font-size: 20px;
      padding-top: 5px;
      margin: 0px 5px;
      cursor: pointer;
      &::before {
        content: "\e85b";
      }
    }
    h2 {
      color: #3a85a8;
      margin-left: 10px !important;
    }
    button {
      color: #3a85a8;
    }
    .add-button {
      margin-left: auto !important;
    }
    .export-data-table {
      margin-left: auto !important;
      margin-right: 10px;
      width: 33px;
      height: 35px;
      float: right;
      padding: 5px;
      border: 1px solid rgb(58, 133, 168) !important;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .pagination-container {
    text-align: center;
  }
}
