#enum-sort-popup {
  padding: 8px 13px;
  filter: unset;
  .enum-sort-item {
    padding: 2px 0px;
    cursor: pointer;
    &.selected {
      color: #3d87ab;
    }
    label {
      margin-left: 3px;
    }
  }
}

#enum-config-popup {
  filter: unset;
  padding: 5px 10px;
  z-index: 50;
  min-width: 280px;
  .enum-config-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
    &.sub-checkbox {
      flex-direction: column;
      align-items: baseline;
      text-align: left;
      width: 100%;
      .checkbox-wrap {
        width: 100%;
        margin: 3px 0px;
        padding: 0px 25px;
      }
    }
    &.multiple-select {
      flex-direction: column;
      padding: 0px 20px;
      .dropdown-wrap {
        width: 100%;
        padding: 2px 0px;
      }
      span.title {
        margin-right: 20px;
      }
      .dropdown {
        float: right;
        .text {
          text-transform: capitalize;
        }
        .item {
          padding: 8px 15px !important;
        }
        .icon {
          margin-left: 10px;
          font-size: 14px;
          top: 1px;
        }
      }
    }
  }
  .checkbox {
    > label {
      &:before {
        border: 1px solid #3d87ab;
        width: 20px;
        height: 20px;
      }
      &:after {
        color: #3d87ab;
        width: 20px;
        height: 20px;
        font-size: 16px;
        margin-top: 2px;
      }
    }
  }
}
#custom-field-form {
  .separator-container {
    grid-template-columns: 1fr;
    display: grid;
    row-gap: 10px;
    margin-bottom: unset !important;
    .enum-config-separator {
      display: flex;
      height: 32px;
      .title {
        display: grid;
        line-height: 16px;
        margin-right: 5px;
        font-size: 16px;
        .info {
          font-style: italic;
          font-size: 11px;
          color: gray;
        }
      }
      .input-text {
        position: relative;
        display: flex;
        align-items: center;
        .clear {
          position: absolute;
          right: 10px;
          cursor: pointer;
        }
        input {
          width: 90px;
          margin-left: 5px;
          border: 1px solid #3d87ab;
          padding-left: 5px !important;
          padding-right: 25px !important;
          height: 32px;
        }
      }
    }
    .checkbox {
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  .display-field-from {
    border: 1px solid #c2d3db;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    .filter-icon {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
    .sort-icon {
      position: absolute;
      cursor: pointer;
      right: 30px;
      top: 6px;
      color: #3d87ab;
      font-size: 16px;
    }
    .dropdown.multiple {
      min-height: 100px;
    }
    .dropdown-type {
      display: grid;
      row-gap: 7px;
    }
    .field-type {
      margin: unset !important;
    }
    label {
      font-weight: 400;
      font-size: 16px;
    }
    .checkbox {
      label {
        padding-left: 40px !important;
        padding-top: 8px;
        height: 34px;
      }
      > label {
        &:before {
          border: 1px solid #3d87ab;
          width: 30px;
          height: 30px;
          margin-top: 3px;
        }
        &:after {
          color: #3d87ab;
          width: 30px;
          height: 30px;
          font-size: 20px;
          margin-top: 10px;
        }
      }
    }
    .display-field-checkbox {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      grid-column-gap: 25px;
      position: relative;
      padding-bottom: 15px;
      padding-left: 10px;
      margin-top: 30px;
      .horizontal-border {
        position: absolute;
        width: 1px;
        background: #c2d3db;
        top: -7px;
        left: 155px;
        height: 100%;
      }
      .enum-config {
        display: flex;
        margin-bottom: 5px;
        .icon.cog {
          &.active {
            color: #3d87ab;
          }
          cursor: pointer;
          margin-left: 10px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
  .dropdown {
    background-color: #f3f3f3;
    border: none !important;
    min-height: 41px;
    &.no-margin {
      margin: 0px !important;
    }
    i.dropdown.icon {
      &:before {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid #3d87ab;
        content: "";
        top: 15px;
        right: 10px;
        position: absolute;
      }
    }
    i.dropdown.icon.clear {
      &:before {
        content: "\f00d";
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        border-top: 0px solid #3d87ab;
        position: absolute;
        top: 15px;
        right: 23px;
      }
    }
  }
  .error {
    .dropdown {
      background-color: #ff8585 !important;
    }
  }
}
