#properties-container {
  border: 0;
  margin-top: 27px;
  i.dropdown.icon {
    &:before {
      width: 0;
      height: 2px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 16px solid #3d87ab;
      content: "";
      position: absolute;
    }
    color: #3d87ab !important;
    padding-right: 20px;
    background-color: transparent;
  }
  .tab-selection {
    label {
      margin-right: 8px;
      color: #dcdcdc;
      cursor: pointer;
    }
    .active {
      color: #3d87ab;
    }
  }
  .title {
    font-weight: 500 !important;
    font-size: 15px !important;
    width: fit-content;
    justify-content: space-between;
  }
  .active.title {
    font-weight: 500 !important;
    font-size: 15px !important;
    width: fit-content;
  }
  .item {
    border: 1px solid #c2d3db;
    border-radius: 1em;
    min-width: 430px;
    padding: 12px 15px;
    min-height: 45px;

    .title {
      font-weight: 500 !important;
      font-size: 15px !important;
      width: 100%;
      i.dropdown.icon {
        color: #3d87ab !important;
        padding-right: 20px;
        background-color: transparent;
        &:before {
          width: 0;
          height: 2px;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 16px solid #3d87ab;
          content: "";
          position: absolute;
        }
      }
      i.cog.icon {
        margin-left: auto;
        color: #3d87ab;
      }
      i.chevron.down {
        font-size: 18px;
        color: #3d87ab !important;
      }
    }
    .active.title {
      font-weight: 500 !important;
      font-size: 15px !important;
      width: 100%;
      i.dropdown.icon {
        color: #3d87ab !important;
        padding-right: 20px;
        background-color: transparent;
      }
      i.chevron.up {
        margin: unset;
        font-size: 21px;
        color: #3d87ab !important;
      }
    }
    .content.active {
      margin-top: 15px;
    }
  }
  #collapse-form-group {
    > div {
      > input {
        max-width: 180px !important;
        background-color: #f3f3f3 !important;
        border: unset !important;
        box-shadow: unset !important;
      }
    }
    label {
      font-weight: 500 !important;
      display: inline-block;
      width: 130px;
      text-align: left !important;
      margin-left: 10px !important;
    }
  }
  #collapse-button-group {
    > div {
      display: inline-block !important;
      vertical-align: middle;
      > label {
        font-weight: 500 !important;
        font-size: 15px !important;
        width: 85px !important;
        text-align: center !important;
        display: inline-block !important;
        margin-left: 3px !important;
        vertical-align: middle;
      }
    }
    .buttons.format {
      margin-left: 16px;
      label {
        width: unset !important;
      }
      button {
        padding: 7px !important;
        margin-left: 10px;
        font-size: 11px;
        border: 1px solid #ececec !important;
        border-radius: 4px;
        color: #3b85a8;
        height: 37px !important;
      }
    }
    .ui.input.disabled {
      opacity: 1 !important;
    }
  }
}
