.file-viewer-container {
  background: #fff;
  padding: 15px !important;
  height: 840px;
  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
