.setting-module-container{
  .setting-checkbox{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .ui.label{
    background:unset;
  }
  .column-container{
    display: inline-grid;
    .column-label{
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  .ui.selection.dropdown{
    color:rgba(0,0,0,.87)!important;
    margin: unset!important;
    border: 1px solid #d4d4d5;
    i.dropdown.icon {
      &::before {
        top: unset;
      }
      color:rgba(0,0,0,.87)!important;
      top: unset;
      background: transparent !important;
    }
  }
  .ui.selection.visible.dropdown > .text:not(.default) {
    font-weight: 400;
    color:rgba(0,0,0,.87)!important
  }
  .ui.dropdown .menu .active.item {
    color:rgba(0,0,0,.87)!important;
    background-color: #d8e7ee;
  }
  .ui.selection.active.dropdown {
    border-color: #d4d4d5;
  }
}

