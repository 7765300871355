.portal-sh-profile-container {
  .portal-sh-profile-filter {
    display: flex;
    align-items: center;
    .add-button {
      padding: 7px 15px !important;
      background-color: #61a7b3 !important;
      margin-left: auto !important;
      height: 35px !important;
      width: 150px !important;
      color: #fff !important;
      font-size: 14px !important;
      font-weight: normal;
      margin-left: auto;
    }
  }
  .portal-sh-profile-main {
    height: calc(100vh - 75px - 86px - 65px - 122px);
    margin-top: 25px;
  }
  .portal-sh-profile-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    .sh-profile-detail {
      border-top: 8px solid #61a7b3;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      height: max-content;
      .sh-profile-header {
        display: flex;
        justify-content: space-between;
        .sh-profile-header-name {
          font-size: 16px;
          font-weight: 500;
        }
        .sh-profile-header-action {
          cursor: pointer;
          i {
            color: #8a9296;
            font-size: 16px;
            width: unset;
          }
        }
      }
      .sh-profile-content {
        margin: 20px 0px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        color: #8a9296;
        .info {
          display: flex;
          img {
            width: 20px;
            margin-right: 10px;
          }
          .name {
            font-size: 14px;
          }
        }
      }
    }
  }
}
