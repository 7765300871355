#dropdown-section {
  border: 1px solid #c2d3db;
  border-radius: 10px !important;
  min-width: 430px !important;
  height: max-content !important;
  margin-left: 5px !important;
  padding: 10px !important;
  position: relative;
  .sort-icon {
    position: absolute;
    cursor: pointer;
    right: 7px;
    top: 10px;
    color: #3d87ab;
    font-size: 16px;
  }
  .display-field-checkbox {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-column-gap: 25px;
    position: relative;
    margin-top: 15px;
    font-size: 16px;
    .enum-config {
      display: flex;
      margin-bottom: 5px;
      .icon.cog {
        &.active {
          color: #3d87ab;
        }
        cursor: pointer;
        margin-left: 10px;
        position: relative;
        top: -1px;
      }
    }
  }
  .dropdown-type {
    display: inline-block;
    div.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
    > div {
      > label {
        &:before {
          width: 35px !important;
          height: 35px !important;
          border: 1px solid #3d87ab !important;
          border-radius: 5px !important;
          margin: 10px 0px 0px 0px !important;
        }
        font-size: 18px !important;
        text-align: left !important;
        padding: 20px 0px 15px 45px !important;
      }
      > input {
        &:checked ~ label {
          &:before {
            width: 35px !important;
            height: 35px !important;
            border: 1px solid #3d87ab !important;
            border-radius: 5px !important;
            margin: 10px 0px 0px 0px !important;
          }
          &:after {
            width: 35px !important;
            height: 35px !important;
            font-size: 27px !important;
            margin: 20px 0px 0px 0px !important;
            color: #3d87ab !important;
          }
        }
      }
    }
    .first {
      margin-right: 50px;
    }
  }
  .dropdown-header {
    margin-bottom: 5px;
  }
  > .dropdown-content {
    background-color: #f3f3f3 !important;
    border: unset !important;
    box-shadow: unset !important;
    height: max-content !important;
    margin-top: 10px !important;
    > div {
      > div {
        background-color: #f3f3f3 !important;
        border: transparent !important;
        box-shadow: unset !important;
        > div {
          font-size: 15px !important;
          > div.css-1rhbuit-multiValue {
            background: transparent;
            padding: 0px 5px 0px 5px !important;
            border: 1px solid #3d87ab;
            margin: 5px 3px 5px 3px !important;
            font-size: 16px !important;
            border-radius: 5px !important;
            width: auto !important;
            > div {
              > svg {
                border: 1px solid #3d87ab;
                border-radius: 8px;
                color: #3d87ab;
              }
            }
            > div.css-xb97g8 {
              border-radius: 20px;
              color: #3d87ab;
              margin: 1px 0px 1px 5px;
            }
          }
          > div {
            > .css-6q0nyr-Svg {
              color: #3d87ab;
            }
          }
        }
      }
    }
  }
}
