@import "../../../../css/theme-variables";
.datatable-record-list-container {
  .table-header {
    img.close-record {
      float: right;
      width: 18px;
      margin-right: -8px;
      cursor: pointer;
    }
  }
  .table-detail-content {
    overflow: auto;
    padding: 10px 0px;
    .table-content {
      overflow: auto;
      height: calc(100vh - 75px - 105px);
    }
    .datatable-detail-list {
      thead {
        tr {
          &.filter-row {
            &:hover {
              background: none;
            }
            th {
              background-color: #fff !important;
              border: 0;
              padding: 0.2em 1em !important;
              text-align: left;
              &:first-child {
                text-align: center;
                max-width: $col-fix-width-xs !important;
              }
              .react-datepicker-wrapper {
                div {
                  input {
                    background-size: 0;
                    border-bottom: 1px solid #6b6b6b !important;
                    border-radius: 0;
                    font-family: initial;
                    padding-top: 10px !important;
                    padding-bottom: 0px !important;
                    margin-bottom: 7px !important;
                  }
                }
              }
              .ui.input {
                input {
                  background-size: 0;
                  border-bottom: 1px solid #6b6b6b !important;
                  border-radius: 0;
                  font-family: initial;
                  width: 100% !important;
                  padding-bottom: 0px !important;
                  padding-top: 10px !important;
                  margin-bottom: 7px !important;
                }
              }
              .selection.dropdown {
                background-size: 0 !important;
                border-bottom: 1px solid #6b6b6b !important;
                border-radius: 0;
                min-height: unset !important;
                padding: 6px !important;
                padding-bottom: 7px !important;
                margin-bottom: 7px !important;
                i {
                  &.dropdown {
                    top: unset !important;
                    padding: 9px !important;
                    padding-top: 10px !important;
                  }
                }
                input {
                  padding: 8px 2px !important;
                }
                .item {
                  padding: 7px 15px !important;
                }
                .text {
                  position: unset !important;
                }
              }
              .multiple.selection.dropdown {
                background-size: 0 !important;
                border-bottom: 1px solid #6b6b6b !important;
                border-radius: 0;
                min-height: unset !important;
                padding: 0px !important;
                margin-bottom: 7px !important;
                .ui.label {
                  padding: 4px 10px;
                }
                i {
                  &.dropdown {
                    top: unset !important;
                    padding: 15px 10px !important;
                  }
                }
                input {
                  padding: 0px 4px !important;
                }
              }
              span {
                position: absolute;
              }
            }
          }
          th {
            z-index: 12;
            &:first-child {
              max-width: $col-fix-width-xs !important;
            }
            &:last-child {
              background-color: #fff;
            }
          }
          &:nth-child(1) {
            th {
              position: sticky;
              top: 0;
              background-color: #f1f1f1;
              max-height: 58px;
            }
          }
        }
        .border {
          th {
            position: sticky;
            border-bottom: 1px solid black !important;
            z-index: 5;
            padding: unset !important;
            &:last-child {
              border: none !important;
              background-color: #fff !important;
            }
          }
        }
      }
    }
  }
  .datatable-detail-list {
    margin-left: 0px !important;
    margin-right: 0px !important;
    tr {
      &:hover {
        td {
          background: $table-row-hover !important;
          &:last-child {
            background: #fff !important;
          }
        }
      }
      input {
        line-height: 1.21428571em !important;
        padding: 0.67857143em 0em !important;
      }
    }
    tr {
      td {
        &.File.Upload {
          min-width: 180px;
          .upload-button {
            min-height: unset !important;
            button {
              width: 100%;
              padding: 8px;
              background: none;
              border: none;
              cursor: pointer;
            }
          }
          .uploaded-file-icon {
            max-width: 27px;
          }
          .uploaded-file-details-container {
            margin: 5px 0px;
          }
          .uploaded-file-details {
            width: 90%;
            .file-name {
              font-size: 12px;
              margin-bottom: unset;
            }
            .file-size {
              font-size: 9px !important;
            }
          }
        }
        &.Referenced,
        &.Dropdown {
          min-width: 150px !important;
        }
        &.detail-record {
          * div {
            text-align: center !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        i {
          cursor: pointer;
        }
        .ui.input {
          input {
            padding: 0;
            font-family: inherit;
          }
        }
        &:first-child {
          text-align: center;
          max-width: $col-fix-width-xs !important;
          .field-input {
            input {
              text-align: center !important;
            }
          }
        }
        &:last-child {
          img {
            max-width: unset !important;
          }
          width: 20px;
          position: sticky;
          right: 0;
          background-color: #fff;
          text-align: center;
        }
      }
      th {
        &:last-child {
          width: 20px;
          position: sticky;
          right: 0;
        }
      }
      .field-label {
        display: none;
      }
      .info-icon {
        display: none;
      }
      .field-input {
        display: block !important;
        width: 100%;
        background-color: #ffffff !important;
        border: none;
        background-color: transparent !important;
        font-weight: normal !important;
        input {
          background-color: #ffffff !important;
          border: none;
          background-color: transparent !important;
          text-align: left !important;
          &::placeholder {
            color: lightgrey !important;
          }
        }
        textarea {
          background-color: #ffffff !important;
          border: none;
          background-color: transparent !important;
        }
      }
      .react-datepicker-wrapper {
        background-color: #ffffff !important;
        border: none;
        background-color: transparent !important;
        div {
          input {
            margin: 0;
            max-width: 100%;
            flex: 1 0 auto;
            outline: 0;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            text-align: left;
            line-height: 1.21428571em;
            font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
            padding: 0.67857143em 1em;
            &::placeholder {
              color: lightgrey !important;
            }
          }
          > input.valid {
            background: linear-gradient(to right, #f1f1f1 0%, #84d94a 50%, #f1f1f1 100%);
            background-size: 100% 2px;
            background-position: bottom 0 left 0, bottom 5px left 0;
            background-repeat: no-repeat;
          }
          > input.error {
            background: linear-gradient(to right, #f1f1f1 0%, #e34b22 50%, #f1f1f1 100%);
            background-size: 100% 2px;
            background-position: bottom 0 left 0, bottom 5px left 0;
            background-repeat: no-repeat;
          }
        }
      }
      .react-datepicker-popper {
        z-index: 30;
      }
      .ui.selection.dropdown {
        border: none !important;
        background: linear-gradient(to right, #f1f1f1 0%, #bdbdbd 50%, #f1f1f1 100%);
        background-size: 100% 1px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
      .ui.multiple.selection.dropdown {
        input.search {
          line-height: 24px;
          margin: 1px;
          max-width: 235px;
          text-align: center !important;
        }
        box-shadow: unset !important;
        z-index: 30;
        text-align: center;
      }
      input {
        background: linear-gradient(to right, #f1f1f1 0%, #bdbdbd 50%, #f1f1f1 100%);
        background-size: 100% 1px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
      textarea {
        background: linear-gradient(to right, #f1f1f1 0%, #bdbdbd 50%, #f1f1f1 100%);
        background-size: 100% 1px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
      .checklist-container {
        background: linear-gradient(to right, #f1f1f1 0%, #bdbdbd 50%, #f1f1f1 100%);
        background-size: 100% 1px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
      div.valid {
        > input {
          background: linear-gradient(to right, #f1f1f1 0%, #84d94a 50%, #f1f1f1 100%);
          background-size: 100% 1px;
          background-position: bottom 0 left 0, bottom 5px left 0;
          background-repeat: no-repeat;
        }
        > textarea {
          background: linear-gradient(to right, #f1f1f1 0%, #84d94a 50%, #f1f1f1 100%);
          background-size: 100% 1px;
          background-position: bottom 0 left 0, bottom 5px left 0;
          background-repeat: no-repeat;
        }
      }
      .checklist-container.valid {
        background: linear-gradient(to right, #f1f1f1 0%, #84d94a 50%, #f1f1f1 100%);
        background-size: 100% 1px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
      .ui.selection.dropdown.valid {
        background: linear-gradient(to right, #f1f1f1 0%, #84d94a 50%, #f1f1f1 100%);
        background-size: 100% 1px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
      div.error {
        > input {
          background: linear-gradient(to right, #f1f1f1 0%, #e34b22 50%, #f1f1f1 100%);
          background-size: 100% 1px;
          background-position: bottom 0 left 0, bottom 5px left 0;
          background-repeat: no-repeat;
        }
        > textarea {
          background: linear-gradient(to right, #f1f1f1 0%, #e34b22 50%, #f1f1f1 100%);
          background-size: 100% 1px;
          background-position: bottom 0 left 0, bottom 5px left 0;
          background-repeat: no-repeat;
        }
      }
      .checklist-container.error {
        background: linear-gradient(to right, #f1f1f1 0%, #e34b22 50%, #f1f1f1 100%);
        background-size: 100% 1px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
      .ui.selection.dropdown.error {
        background: linear-gradient(to right, #f1f1f1 0%, #e34b22 50%, #f1f1f1 100%);
        background-size: 100% 1px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
    }
  }
  .datatable-record-list-action {
    padding: 20px;
    float: right;
    label {
      border-bottom: 1px solid #000000;
      padding: 0px 20px 5px;
      margin-right: 10px;
      cursor: pointer;
    }
    label.save {
      color: #9ed1b3;
      border-color: #9ed1b3;
    }
    label.cancel {
      color: #989898;
      border-color: #989898;
    }
  }
  .table-add-record {
    td {
      position: sticky;
      bottom: 0px;
      background-color: #fff !important;
      z-index: 25;
      .field-input {
        text-align: left;
        width: 100%;
      }
    }
  }
  .datatable-record-list-pagination {
    text-align: center;
    .pagination-container {
      margin-top: 16px;
    }
  }
  .dtr-bulk-actions {
    display: flex;
    margin: -10px 0px !important;
    height: 27px;
    .bulk-action-trigger {
      background-color: transparent !important;
      width: 45px;
      height: inherit !important;
      padding: 0px 7px !important;
      border-radius: 4px !important;
      margin-right: unset !important;
      margin-left: auto;
      &.active {
        background-color: #cacbcd !important;
      }
      .dynamic-actions-collapse {
        font-size: 28px;
        display: block;
        justify-content: center;
        height: inherit;
        cursor: pointer;
      }
      &:hover {
        background-color: #cacbcd !important;
      }
      i {
        height: 10px !important;
      }
    }
  }
}

#dtr-bulk-actions-popup {
  padding: 0px;
  border-radius: 5px !important;
  top: -9px !important;
  .bulk-actions-popup-container {
    min-width: 150px;
    width: 100%;
  }
  .bulk-actions-header {
    margin-bottom: 5px;
    padding: 10px 15px;
    height: 40px;
    background-color: #e6e6e6;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }
  .bulk-actions-content {
    padding: 9px 15px;
    cursor: pointer;
    &:hover {
      background-color: #f3f3f3 !important;
    }
  }
  .bulk-actions-separator {
    border: 0;
    height: 1px;
    background: linear-gradient(to right, #ffffff -30%, #e6e6e6 50%, #ffffff 130%);
  }
  &.mobile {
    .bulk-actions-popup-container {
      width: 120px !important;
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .bulk-actions-content {
      font-size: 12px !important;
      padding: 7px 10px !important;
      div {
        column-gap: 7px !important;
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
