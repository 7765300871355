@import '../../../../css/theme-variables';
.group-table-container {
  height: calc(100vh - 75px - 86px - 65px - 125px);
}

.group-table {
  min-width: 500px;
  width: 100%;
  text-align: center;
  tbody {
    tr {
      td {
        &:first-child {
          width: $col-fix-width-xs;
          max-width: $col-fix-width-xs;
          min-width: $col-fix-width-xs;
        }
        padding: 3px !important;
        border-right: .5px solid #fff;
        font-size: 10pt;
        text-align: center;
        color: #000;
      }
      padding: 20px 5px !important;
      border-right: .5px solid #fff;
      font-size: 10pt;
      text-align: center;
      color: #000;
    }
    td {
      &:last-child {
        position: -webkit-sticky;
        position: sticky;
        right: 0;
        top: 0;
        background-color: #fff;
        width: 30px;
        font-size: 12px;
      }
    }
  }
  tr {
    th {
      &:first-child {
        max-width: 35px !important;
      }
    }
    &:nth-child(even) {
      background: #F3F3F3;
    }
    &:nth-child(odd) {
      background: #FFF;
    }
  }
  thead {
    .header-name {
      th {
        position: -webkit-sticky;
        position: sticky;
        z-index: 2;
        top: 0;
      }
    }
    th.th-group {
      font-size: 13px !important;
      padding: 3px 10px !important;
      background-color: #3A85A8 !important;
      color: #FFFFFF !important;
      height: 30px !important;
    }
    th {
      &:last-child {
        position: -webkit-sticky;
        position: sticky;
        right: 0;
        text-align: center;
        background-color: #fff;
        border-color: #fff;
        width: 30px;
      }
    }
  }
  .table-action-icon {
    font-size: 13px !important;
    color: #3A85A8;
  }
}