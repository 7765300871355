@import "../../../../css/theme-variables";
@import "../../../../css/mixins";
.reports-main-container {
  .container-col {
    &.filter {
      .row {
        .col {
          flex: 0 0 25%;
          max-width: 25%;
          padding-right: 15px;
          padding-left: 0;
          &:last-child {
            padding-right: 0;
          }
          &.datepicker-wrapper {
            .ui.input {
              min-width: 105px;
              width: calc( 50% - 32px);
            }
          }
          @include breakpoints(filter-breakpoint-m, filter-breakpoint) {
            .form-group {
              margin-bottom: 1.2em;
            }
            &.datepicker-wrapper {
              .form-group {
                margin-bottom: 0;
              }
            }
            flex: 0 0 50%;
            max-width: 50%;
          }
          @include breakpoints(small, filter-breakpoint-m) {
            padding-right: 0;
            .form-group {
              margin-bottom: 1.2em;
              &:last-child {
                margin-bottom: 0;
              }
            }
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
    .form-group {
      span {
        margin: .4em;
      }
      .action-clear {
        width: 14px;
        height: 14px;
        margin: .8em .6em;
      }
    }
  }
  .documents-filter-container {
    width: 100%;
    padding: 1em;
    background-color: #EAEAEA;
    border-radius: .3em;
    .ui.input {
      i {
        &.icon {
          z-index: 2;
          opacity: 1;
          font-size: 1.2em;
          width: 2em;
        }
      }
    }
    .flex-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
    }
    div {
      label {
        font-size: 13px;
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
      div {
        input {
          border: none;
          border-radius: .28571429rem;
          padding: 10.5px 10px;
          padding-left: 2.4em;
          font-size: 13px;
          width: 100%;
        }
      }
    }
    .react-datepicker-popper {
      left: -2.4em !important;
    }
    div.status-filter-field{
      label {
        min-width: 40px;
      }
      .ui.selection.dropdown {
        border: 1px solid transparent !important;
        margin: 0 !important;
        font-size: 13px;
        background-color: #FFFFFF;
        width: 100%;
        div {
          color: #000 !important;
          border-color: transparent;
          div {
            color: #000 !important;
            border-color: transparent;
            font-size: 13px;
          }
        }
        i {
          color: #000 !important;
          border-color: transparent;
        }
      }
    }
    div.sla-filter-field {
      min-width: 140px;
      width: calc( 50% - 32px);
      padding-right: 15px;
      label {
        min-width: 40px;
      }
      .ui.selection.dropdown {
        border: 1px solid transparent !important;
        margin: 0 !important;
        font-size: 13px;
        background-color: #FFFFFF;
        width: 100%;
        min-width: 104px;
        div {
          color: #000;
          border-color: transparent;
          div {
            color: #000;
            border-color: transparent;
            font-size: 13px;
          }
        }
        i {
          color: #000;
          border-color: transparent;
        }
      }
    }
    div.urnNo-filter-field {
      min-width: 140px;
      width: calc( 50% - 32px);
      label {
        min-width: 55px;
      }
      div {
        width: 100%;
        input {
          height: 35px;
          border: none;
          max-width: 100%;
          &:focus {
            outline: none;
            border: none;
          }
        }
      }
    }
    div.doc-updated-field {
      label {
        min-width: 115px;
        margin-right: 10px;
      }
      div.calendar-icon {
        margin-right: 15px;
        min-width: 50px;
      }
      div {
        img {
          width: 20px;
          height: 20px;
          margin: 0px 3px;
        }
      }
    }
    div.doc-created-field {
      label {
        min-width: 115px;
        margin-right: 10px;
      }
      div.calendar-icon {
        margin-right: 15px;
        min-width: 50px;
      }
      div {
        img {
          width: 20px;
          height: 20px;
          margin: 0px 3px;
        }
      }
    }
    .react-datepicker-popper {
      z-index: 20;
    }
  }
  .custom-filter-container {
    .ui.selection.dropdown {
      div {
        color: #000 !important;
        border-color: transparent;
        div {
          color: #000 !important;
          border-color: transparent;
        }
      }
      i {
        color: #000 !important;
        border-color: transparent;
      }
    }
    .ui.selection.visible.dropdown {
      div {
        color: #000 !important;
        border-color: transparent;
      }
    }
    div {
      div {
        .field-icon-container {
          img {
            width: 20px;
            height: 20px;
            margin: 0px 3px;
          }
        }
      }
    }
    .ui.input {
      input {
        &:focus {
          outline: none !important;
          border: none;
        }
      }
    }
  }
}