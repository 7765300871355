#range-field-modal {
  width: 650px !important;
  .dropdown {
    margin-left: unset !important;
  }
  .range-field-container {
    flex-direction: column;
    margin-bottom: 0px;
    label.field {
      display: block;
      margin: 0 0 0.28571429rem 0.1rem;
      color: rgba(0, 0, 0, 0.87);
      font-size: 0.92857143em;
      text-transform: none;
      font-weight: 700;
    }
    .flex-wrapper {
      &.date {
        input {
          padding-left: 32px;
        }
      }
      &.date-time {
        input {
          padding-left: 36px;
        }
      }
    }
    .range-field-config {
      width: 100%;
      padding: 0px 18px;
      .field-range {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        .range-separator {
          margin-top: 8px;
        }
        .field-info {
          display: none;
        }
        .with-icon {
          padding-right: 20px;
          top: 15px;
          position: absolute;
          left: 10px;
        }
      }
      .fields {
        margin-bottom: 0px;
      }
      .flex-wrapper {
        width: 100%;
        position: relative;
      }
    }
  }
}

.react-datepicker-popper {
  .react-datepicker {
    display: flex !important;
  }
}
