.document-form-container {
  .field-dropdown-container {
    width: calc(100% - 40px) !important;
    &.inside-table {
      width: 100% !important;
    }
    &.with-shortcut {
      display: grid;
      grid-template-columns: 1fr 35px;
      align-items: center;

      .shortcut-btn-container {
        border-radius: 5px;
        border: 1px solid black;
        height: 30px;
        margin-left: 5px;
        cursor: pointer;
        align-items: center;
        display: flex;
        justify-content: center;

        .plus.icon {
          font-size: unset;
          background-color: unset;
          color: unset;
          height: unset;
          margin: unset;
          margin-top: 2px;
        }
      }
    }
  }
}
