.download-popup-container {
  height: 30px;
  margin-left: auto;
  display: grid;
  .btn-download {
    width: 50px;
    height: 100% !important;
    padding: 0px 7px !important;
    border-radius: 4px !important;
    background-color: #e6e6e6;
    margin-right: unset !important;
    margin-left: auto;
  }
}

#download-popup {
  padding: 0px;
  border-radius: 5px !important;
  top: -8px !important;
  max-width: 250px !important;
  min-width: 120px !important;
  transform: translate3d(1681px, 227px, 0px);
  .checkbox {
    width: 100% !important;
  }
  .flex-container {
    img {
      margin-right: 15px;
    }
  }
  .download-header {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .border {
    border-bottom: 1px solid #eaeaea;
    margin: 0px 15px 0px 15px;
  }
  .download-container {
    width: 100%;
    min-width: 200px;
    max-height: 400px;
  }
  .download-content {
    .ui.fitted.checkbox .box,
    .ui.fitted.checkbox label {
      padding-left: 3.2em !important;
    }
    .action-label {
      font-weight: bolder;
      cursor: pointer;
      padding: 9px 15px;
      &.include-table {
        padding: 15px 15px 9px;
      }
    }
    .separator {
      border: 0;
      height: 0.05em;
      background: linear-gradient(to right, #ffffff -30%, #e6e6e6 50%, #ffffff 130%);
    }
  }
  .table-list-container {
    margin-top: 7px;
    margin-left: 25px;
    .list-label {
      font-weight: 400 !important;
    }
  }
  .action-container {
    text-align: right;
    right: 0;
    margin: 20px 0px 15px 0px;
    .confirm-action {
      cursor: pointer;
      background-color: #24b933;
      color: #fff;
      padding: 6px 15px;
      border-radius: 3px;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #24b933 !important;
    height: 75%;
    margin-top: 0.2rem;
  }
  .ui.toggle.checkbox input ~ .box:after,
  .ui.toggle.checkbox input ~ label:after {
    left: 0.2rem;
  }
  .ui.toggle.checkbox .box:after,
  .ui.toggle.checkbox label:after {
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    top: 0.1rem;
  }
  .ui.toggle.checkbox input:checked ~ .box:after,
  .ui.toggle.checkbox input:checked ~ label:after {
    left: 1.2rem;
    top: 0.1rem;
  }
  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    width: 2.5rem;
    height: 75%;
    margin-top: 0.2rem;
  }
  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    color: #fff;
  }
  .table-list-container {
    .ui.checkbox .box:after,
    .ui.checkbox label:after {
      background-color: #24b933;
      border: 1px solid #24b933;
    }
    .ui.checkbox .box:before,
    .ui.checkbox label:before {
      background-color: #eaeaea;
      border: 1px solid #eaeaea;
    }
  }
}
