.contraint-button {
  float: left;
  cursor: pointer;
  &.active {
    color: #3a85a8;
  }
}
#constraint-modal {
  width: 150px;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #000000;
  z-index: 15;

  .constraint-list-container {
    .custom-constraint {
      max-height: 100px;
      overflow: auto;

      .created-constraint {
        border-radius: 3px;
        color: #ffffff;
        background-color: #000000;
        text-align: center;
        padding: 2px;
        margin-bottom: 5px;
        cursor: pointer;
        width: 100%;
      }
    }

    .add-new-button {
      margin-top: 20px;
      border-radius: 3px;
      color: #000000;
      text-align: center;
      padding: 2px;
      background-color: white;
      border: 1px solid #3a85a8;
      cursor: pointer;
    }
  }

  .custom-constraint-container {
    > .user-reference-field-selection {
      min-width: auto !important;
      min-height: auto !important;
      width: 130px !important;
      margin: 5px 0px 0px 0px !important;
      padding: 5px !important;
      height: 25px !important;
      background-color: #ffffff;
      &.error {
        background-color: lightcoral;
      }

      > input {
        padding: 1px;
      }
      > .text {
        text-overflow: ellipsis;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
      }
      &.disabled {
        filter: grayscale(0.8);
      }
      i.dropdown.icon {
        height: 25px !important;
        margin-top: -3px;
        &:before {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid #3d87ab;
          content: '';
        }
        &.clear {
          &:before {
            content: '\f00d' !important;
            width: 0 !important;
            height: 0 !important;
            border-left: 0px solid transparent !important;
            border-right: 0px solid transparent !important;
            border-top: 0px solid #3d87ab !important;
            position: absolute !important;
            top: 5px !important;
            right: 22px !important;
            background-color: transparent !important;
            margin-top: -5px;
            margin-right: -5px;
          }
        }
      }
      .item {
        padding: 3px !important;
      }
    }

    .custom-constraint-name {
      background-color: #ffffff;
      border: #e2e2e2 solid 1px;
      border-radius: 3px;
      margin: auto;
      padding-left: 5px;
      width: 100%;
      height: 25px;
      &.error {
        background-color: lightcoral;
      }
    }

    .add-constraint-button {
      margin-top: 20px;
      border-radius: 3px;
      color: #ffffff;
      text-align: center;
      padding: 2px;
      background-color: #5cab67;
      cursor: pointer;
    }

    .cancel-constraint-button {
      margin-top: 5px;
      border-radius: 3px;
      color: #ffffff;
      text-align: center;
      padding: 2px;
      background-color: #d07f7a;
      cursor: pointer;
    }
    .flex-container {
      margin-top: 10px;
      .action-modal-btn {
        width: 20px;
        margin: 3px 0px;
        cursor: pointer;
      }
      .action-modal-btn.cancel {
        margin-right: auto;
      }
    }
  }
}
