.multiselect-dropdown {
  width: inherit;
  .custom-icon {
    position: absolute !important;
    right: 0;
    z-index: 1;
    cursor: pointer;
    left: unset !important;
    background-color: unset !important;
  }
  .customize-dropdown-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #525252;
    z-index: 1;
    &.dropdown.icon {
      cursor: default;
      &:before {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 12px solid #525252;
        content: "";
        top: 5px;
        right: 5px;
        position: absolute;
      }
    }
  }

  .ui.button.floating.labeled.dropdown.icon {
    width: 100%;
    border-bottom: #030303 1px solid;
    background-color: #ffffff;
    color: #525252;
    padding-left: 10px !important;
    padding-bottom: 4px;
    border-radius: 0px;
    margin-top: -8px;

    > .text {
      font-weight: 500;
      white-space: nowrap;
      width: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > .dropdown.icon {
      right: 0;
      left: unset;
      display: none;
      background-color: transparent;

      &:before {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 12px solid #525252;
        content: "";
        top: 18px;
        right: 10px;
        position: absolute;
      }
    }
  }
  .menu.multiselect-dropdown-popup {
    background-color: #ffffff;
    min-width: 150px !important;
    box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.75);
    &::-webkit-scrollbar {
      width: 7px !important;
    }

    .search-filter {
      width: calc(100% - 20px);
      margin: 10px;
      border: gray 1px solid;
      padding: 5px !important;
      border-radius: 3px;
      &:focus {
        outline: 0;
      }
    }

    .selected-option-container {
      display: block;
      padding: 10px;
      .selected-option-title {
        color: #a3a3a3;
        font-size: 13px;
        font-weight: 500;
        .icon.angle {
          float: right;
          cursor: pointer;
        }
      }
      .clear-selected {
        text-decoration: underline;
        color: #a3a3a3;
        font-size: 10px;
        float: right;
        position: unset !important;
        cursor: pointer;
      }
    }

    .option-title {
      color: #a3a3a3;
      font-size: 13px;
      font-weight: 500;
      padding: 10px 10px 0px;
    }

    > .menu {
      margin-top: 1px !important;
    }

    .option-list {
      padding: 10px 15px;
      border-top: none;
      box-shadow: none !important;
      &.selection {
        max-height: 200px !important;
      }
      .ui.fitted.checkbox {
        display: block;
        margin-top: 10px;

        label {
          padding-left: 25px !important;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000000;
          font-weight: 500;

          &:before {
            border-color: #3a85a8;
          }
          &:after {
            color: #3a85a8;
          }
        }
      }
    }
  }
}
