.form-filter-configuration-modal {
  .content {
    min-height: 400px;
    max-height: 400px;
    overflow: auto;
    label {
      font-weight: 400;
    }
  }
  .condition-join-method {
    grid-template-columns: 100px 1fr;
    display: grid;
    .condition-container {
      label {
        padding: 3px 10px;
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        background-color: #f9f9f9;
        cursor: pointer;
      }
      label.selected {
        border: 1px solid #7ca7b9;
        background-color: #3c86a8;
        color: #ffffff;
      }
    }
    .checkbox {
      margin-left: 5px;
      label {
        padding-left: 26px !important;
        font-weight: 500;
      }
    }
  }
  .remove-condtion,
  .add-new-condtion {
    float: right;
    color: #fff;
    background: #3d87ab;
    float: right;
    margin-left: 5px;
    margin-top: 30px;
    cursor: pointer;
  }
  .condition-field {
    border-bottom: 1px solid #376f8d;
    padding-bottom: 10px;
    .condition-configuration {
      margin-top: 15px;
      grid-template-columns: 35px 350px 1fr 350px;
      display: grid;
      column-gap: 5px;
      .field-container {
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .label {
        cursor: pointer;
      }
      .selection-id {
        .selection-id-label {
          margin-left: 2px;
          color: #376f8d;
          padding-left: 5px;
        }
        .checkbox-id {
          .ui.checkbox {
            min-width: 35px;
          }
          > div {
            > label {
              &:before {
                width: 35px !important;
                height: 38px !important;
                border: 1px solid #3d87ab !important;
                border-radius: 5px !important;
              }
              font-size: 18px !important;
              text-align: left !important;
            }
            > input {
              &:checked ~ label {
                &:before {
                  width: 35px !important;
                  height: 38px !important;
                  border: 1px solid #3d87ab !important;
                  border-radius: 5px !important;
                }
                &:after {
                  width: 35px !important;
                  height: 38px !important;
                  font-size: 23px !important;
                  margin-top: 10px !important;
                  color: #3d87ab !important;
                }
              }
            }
          }
        }
      }
      .selection-field-source {
        .source-field-label {
          white-space: nowrap;
          max-width: 350px;
          display: flex;
          .label {
            margin-right: 5px;
          }
          label {
            color: #dcdcdc;
            &:last-child {
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          label.selected {
            color: #376f8d;
          }
        }
      }
      .selection-field-value {
        .selection-field-value-label {
          white-space: nowrap;
          max-width: 350px;
          display: flex;
          .label {
            margin-right: 5px;
          }
          label {
            color: #dcdcdc;
            &:last-child {
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          label.selected {
            color: #376f8d;
          }
        }
        .condition-value {
          .input {
            width: 100%;
          }
          .react-datepicker-wrapper {
            width: 100%;
            input {
              width: 100%;
            }
          }
          input {
            border: #376f8d 1px solid;
            height: 100%;
            margin: 0;
            max-width: 100%;
            flex: 1 0 auto;
            outline: 0;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            text-align: left;
            line-height: 1.21428571em;
            font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
            padding: 0.67857143em 1em;
            background: #fff;
            color: rgba(0, 0, 0, 0.87);
            border-radius: 0.28571429rem;
            transition: box-shadow 0.1s ease, border-color 0.1s ease;
            box-shadow: none;
          }
        }
        .error.dropdown.selection {
          background-color: #ff8585 !important;
        }
      }
      .dropdown {
        margin-left: 0px !important;
      }
    }
    .condition-config {
      margin-top: 10px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      display: grid;
      column-gap: 5px;
      margin-top: 30px;
      &.five {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
      }

      .source-field-container {
        margin-top: -24px;
        .source-field-label {
          margin-right: 5px;
          cursor: pointer;
          &.active {
            border-bottom: 1px solid #376f8d;
            color: #3d87ab;
          }
        }
        .dropdown.selection {
          margin-top: 5px !important;
        }
      }
      .selection-field-value-config {
        .selection-field-value {
          margin-top: -26px;
        }
        .condition-value {
          margin-top: 7px !important;
          .input {
            width: 100%;
          }
          .react-datepicker-wrapper {
            width: 100%;
          }
          input {
            border: #376f8d 1px solid;
            height: 100%;
            margin: 0;
            max-width: 100%;
            flex: 1 0 auto;
            outline: 0;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            text-align: left;
            line-height: 1.21428571em;
            font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
            padding: 0.67857143em 1em;
            background: #fff;
            color: rgba(0, 0, 0, 0.87);
            border-radius: 0.28571429rem;
            transition: box-shadow 0.1s ease, border-color 0.1s ease;
            box-shadow: none;
          }
        }
        .dropdown.selection {
          margin-top: 7px !important;
        }
        .error.dropdown.selection {
          background-color: #ff8585 !important;
        }
      }
      .selection-field-value {
        label {
          color: #dcdcdc;
          border-bottom: 1px solid #dcdcdc;
          padding-bottom: 2px;
        }
        label.selected {
          color: #376f8d;
          border-bottom: 1px solid #376f8d;
          padding-bottom: 2px;
        }
      }
      .checkbox {
        margin-left: 10px;
        label {
          padding-left: 26px !important;
        }
      }
      .dropdown {
        margin-left: 0px !important;
      }
    }
    .condition-field-2 {
      .dropdown {
        margin-left: 0px !important;
      }
      margin-top: 10px;
      grid-template-columns: 60px 1fr 140px 1fr 1fr;
      display: grid;
      column-gap: 5px;
      .checkbox {
        label {
          padding-left: 26px !important;
          font-weight: 500;
        }
        margin-left: 5px;
      }
    }
  }
  .condition-field-2 {
    .field-value-selection {
      grid-template-columns: 50px 1fr;
      display: grid;
    }
  }
  .condition-value {
    > div {
      > span {
        > label {
          display: none;
        }
      }
    }
  }
}
