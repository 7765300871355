.task-view-config {
  overflow: auto;
  height: calc(100vh - 75px - 120px - 65px - 18px);
  display: grid;
  grid-template-columns: 1fr 200px;

  .main-view {
    padding: 20px;
    height: calc(100vh - 75px - 120px - 65px - 18px);
    overflow: auto;
  }

  .side-view {
    background-color: #edf0f2;
    height: calc(100vh - 75px - 120px - 65px - 18px);
    overflow: auto;
  }
}
