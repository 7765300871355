@import "../../../../../../css/theme-variables";
.automation-main-container {
  padding: 25px 20px !important;
  background: #ffffff;
  margin-top: 14px;
  border-radius: 8px !important;
  height: calc(100vh - 75px - 86px - 157px);

  .button-container {
    background: transparent;
    min-height: 65px;
    padding: 10px;
    padding-right: 0px;
    position: relative;

    > button {
      float: right;
      width: 120px;
      color: #ffffff;
      margin-right: 10px;
      font-size: 12px;
    }
  }
}

.business-rule-list-container {
  background-color: #ffffff;
  border-radius: 10px;
  margin: unset;
  height: calc(100vh - 75px - 120px - 86px - 113px);

  .search-filter {
    input {
      height: 30px;
      width: 150px;
      margin: 2px 10px 0px -10px;
      border-radius: 5px;
      border: 1px solid #3a85a8;
      padding: 4px 4px;
      &::placeholder {
        color: #3a85a8;
      }
    }

    button {
      color: #ffffff;
      background-color: #3a85a8;
      border: none;
      height: 30px;
      font-size: 18px;
      border-radius: 5px;
      padding: 0px 2px 0px 5px;
      cursor: pointer;
    }
  }
  .business-rule-table {
    width: 100%;
    tbody {
      td {
        padding: 3px !important;
        border-right: 0.5px solid #fff;
        font-size: 10pt;
        text-align: center;
        color: #000;
        overflow: hidden;
        i {
          font-size: 13px !important;
          color: #3a85a8;
        }
        &:last-child {
          background-color: #ffffff;
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          width: 60px;
          font-size: 12px;
        }
        &.owner-icon {
          vertical-align: middle;
          img {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: inline;
          }
        }
        &.description-column {
          text-align: left;
          max-width: 300px;
          width: 300px;
          p {
            width: 100px;
            max-width: 100px;
          }
        }
        .table-checkbox-toggle {
          z-index: 0;
          input {
            ~ label {
              &:before {
                width: 30px;
                height: 16px;
                margin-right: 3px;
              }
              &:after {
                width: 12px;
                margin-top: 2px;
                margin-left: 3px;
                height: 12px;
              }
            }
            &:checked {
              ~ label {
                &:before {
                  width: 30px;
                  height: 16px;
                }
                &:after {
                  width: 12px;
                  height: 12px;
                  margin-top: 2px;
                  margin-left: -14px;
                }
              }
            }
          }
        }
      }
      tr {
        &:nth-child(odd) {
          background-color: #f3f3f3;
        }
      }
    }
    thead {
      th {
        &:first-child {
          max-width: 35px !important;
        }
        &:last-child {
          background-color: #ffffff;
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          top: 0;
          z-index: 2;
        }
      }
      th.th-group {
        font-size: 13px !important;
        padding: 3px 10px !important;
        background-color: #3a85a8 !important;
        color: #ffffff !important;
        height: 30px !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
      }
    }
  }
}
