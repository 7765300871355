.create-document,
.create-user-action,
.send-sms-action,
.edit-form-action {
  .ui.button.floating.labeled.dropdown.icon {
    width: 100%;
    border: #3a85a8 1px solid;
    background-color: #ffffff;
    border-radius: 5px;
    color: rgba(191, 191, 191, 0.87);
    padding-left: 10px !important;

    &.value {
      color: #3a85a8;
    }

    > .text {
      font-weight: 500;
    }

    > .dropdown.icon {
      right: 0;
      left: unset;
      background-color: transparent;

      &:before {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #3d87ab;
        content: "";
        top: 18px;
        right: 13px;
        position: absolute;
      }
    }

    .option-title {
      color: #a3a3a3;
      font-size: 13px;
      font-weight: 500;
      padding: 10px 10px 0px;
    }

    > .menu {
      margin-top: 1px !important;
      max-height: unset !important;
    }

    .option-list {
      padding: 10px 15px;
      border-top: none;
      box-shadow: none !important;
      .ui.fitted.checkbox {
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;

        label {
          padding-left: 25px !important;
          width: 250px;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #000000;
          font-weight: 500;

          &:before {
            border-color: #3a85a8;
          }
          &:after {
            color: #3a85a8;
          }
        }
      }
    }
  }
  .select-field-container {
    .close-button {
      float: right;
      margin-top: 10px;
      cursor: pointer;
      font-weight: 500;
    }
    .dropdown-text-cover {
      position: absolute;
      color: white;
      background-color: transparent;
      display: block;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      margin-top: -40px;
      z-index: 10;
      cursor: pointer;
      > label {
        font-weight: 700;
        width: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .ui.button.floating.labeled.dropdown.icon {
      background-color: #3a85a8;
    }
    .button-container {
      z-index: 21;
    }
    .option-list {
      .option-title {
        color: #6b6868 !important;
        font-weight: 700 !important;
        padding: 5px 0px !important;
        margin-top: 10px;
      }
    }
    .btn-action-collapse {
      &.open {
        background-color: #3a85a8 !important;
        i {
          color: white;
        }
      }
    }
    input {
      min-height: 38px;
    }
    input.search {
      min-height: unset !important;
    }
    .uploaded-file-list {
      width: 70%;
    }
    .Phone {
      width: 100%;
      input {
        &:focus {
          outline: none;
        }
      }
    }
    .URL,
    .Email {
      input {
        width: calc(100% - 38px);
      }
    }
    .multi-line-container {
      input {
        width: calc(100% - 38px);
      }
      .react-datepicker-wrapper {
        input {
          width: calc(100%);
        }
      }
    }
    .uploaded-file-list {
      width: 70%;
      .file-name {
        margin-bottom: unset;
      }
    }
  }
  .react-tel-input {
    max-height: 36px;
    min-height: 36px;
    .phone-input-field {
      padding-left: 58px !important;
      width: 100%;
    }
    .flag-dropdown {
      min-height: 38px;
      .search-box {
        width: 96% !important;
      }
      .selected-flag {
        width: 50px;
      }
      .flag {
        margin-left: 2px;
        .arrow {
          left: 23px;
          &:not(.up) {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid #555;
          }
          &.up {
            border-bottom: 5px solid #555;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            top: 48% !important;
          }
        }
      }
    }
    .country-list {
      span {
        display: inline-block !important;
      }
    }
  }
}
