@import "../../../component/css/mixins.scss";

#confirmation-popup {
  border-radius: unset !important;
  max-height: 465px;
  min-height: 250px;
  border-left: 20px solid;
  min-width: 600px;
  padding: 0em 1.5em;
  background: #ffffff;
  &.mobile {
    min-width: unset;
    width: 85vw;
    min-height: unset;
    @include breakpoints(240px, 480px) {
      padding: 0px 15px !important;
      border-left: 15px solid;
      .header {
        padding: 0px 10px !important;
        padding-bottom: 10px !important;
        h1 {
          font-size: 20px !important;
        }
        .sad-icon {
          width: 30px;
        }
      }
      .content {
        padding: 10px !important;
        .confirmation-message {
          font-size: 13px !important;
        }
      }
      .actions {
        padding: 10px !important;
        margin: 10px 0px 5px !important;
        font-size: 12px;
      }
      .close-icon {
        width: 20px;
        cursor: pointer;
        margin: unset;
        margin-left: auto;
        margin-top: 10px;
      }
    }
  }
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #f7f7f7 28%,
    #e8e8e8 65%,
    #eaeaea 76%,
    #eaeaea 76%,
    #e6e6e6 87%,
    #dddddd 100%
  );
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #f7f7f7 28%,
    #e8e8e8 65%,
    #eaeaea 76%,
    #eaeaea 76%,
    #e6e6e6 87%,
    #dddddd 100%
  );
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #f7f7f7 28%,
    #e8e8e8 65%,
    #eaeaea 76%,
    #eaeaea 76%,
    #e6e6e6 87%,
    #dddddd 100%
  );
  .confirmation-message {
    text-align: left !important;

    img {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    p {
      display: inline-block;
      vertical-align: top;
    }
  }
  &.error {
    border-color: #dc3e27;
  }
  &.info {
    border-color: #66b283;
  }
  &.warning {
    border-color: #dda749;
  }
  .no-close-icon {
    height: 40px;
  }
  .close-icon {
    width: 25px;
    cursor: pointer;
    margin: 30px 20px 0px auto;
  }
  .sad-icon {
    width: 50px;
    margin-left: 10px;
  }
  .header {
    padding: 0.3rem 1.5rem !important;
  }
  .sub-header {
    padding: 0.8rem 1.5rem 0 !important;
    margin: unset;
  }
  .header,
  .actions,
  .content {
    background: transparent !important;
  }
  .actions {
    margin: 15px 0px;
    padding: 15px 30px;
    padding-right: 1.5rem;
    display: flex;
    position: relative;
    &.reload {
      cursor: pointer;
      padding-bottom: 10px;
      .reload-btn {
        margin-left: auto;
        border-bottom: 2px solid #7ebb61;
        padding: 5px 3px;
        span {
          margin-right: 5px;
        }
        i {
          font-size: 14px;
          color: #7ebb61;
        }
      }
    }
    .btn-cancel {
      border-bottom: 2px solid #9a9a9a;
      padding-bottom: 5px;
      width: 55px;
      margin-left: auto;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
    }
    .btn-confirm {
      border-bottom: 2px solid #7ebb61;
      padding-bottom: 5px;
      width: 55px;
      margin-left: 20px;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
