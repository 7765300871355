@import "../../../../css/mixins.scss";

.task-grid-main {
  background: #f1f1f1;
  overflow: hidden auto;
  height: calc(100vh - 50px - 75px - 50px);
  margin: 10px 0px 0px;
  padding: 20px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 4px !important;
  }
  &.two,
  &.three {
    padding: 15px;
    .task-grid-row {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 18px;
      .task-grid-col {
        .task-data {
          &.footer {
            .user-photo {
              width: 24px;
              height: 24px;
              margin-right: 10px !important;
              svg {
                width: 100%;
                height: 92%;
              }
            }
            .icons {
              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
      }
    }
  }
  &.one {
    padding: 12px 10px;
    .task-grid-row {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 15px;
      @include breakpoints(240px, 480px) {
        &.portrait {
          .task-grid-col {
            padding: 10px 15px;
            .task-data {
              .link {
                font-size: 12px;
              }
              .status {
                .status-container {
                  padding: 2px 12px;
                  font-size: 10px !important;
                }
                .aging {
                  font-size: 11px;
                  margin: 1px 3px;
                }
              }
              &.grid {
                grid-template-columns: 35px auto;
                .logo {
                  min-height: 35px;
                  max-height: 35px;
                }
                .info {
                  .name {
                    font-size: 12px;
                  }
                  .desc,
                  .labels {
                    font-size: 11px;
                  }
                }
              }
              &.border {
                height: 0.1em;
                margin: 7px 0px;
              }
              &.footer {
                margin-top: -3px;
                .update-info {
                  font-size: 11px;
                  .user-info-avatar {
                    .user-photo {
                      margin-right: 0.7em;
                      width: 22px;
                      height: 22px;
                      svg {
                        width: 100%;
                        height: 93%;
                      }
                    }
                  }
                }
                .icons {
                  .comment-icon {
                    .mention {
                      font-size: 13px;
                    }
                    .chat-wrapper {
                      max-width: 23px;
                    }
                  }
                  svg {
                    width: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.three {
    .task-grid-row {
      grid-template-columns: repeat(3, 1fr);
      @include breakpoints(small, 1280px) {
        .task-grid-col {
          padding: 10px 15px;
          .task-data {
            .link {
              font-size: 12px;
            }
            &.grid {
              grid-template-columns: 40px auto;
            }
            .logo {
              min-height: 40px;
              max-height: 40px;
            }
            .status {
              .status-container {
                padding: 2px 10px;
              }
              .aging {
                margin: 1px 3px;
                font-size: 11px;
              }
            }
            .info {
              .name {
                font-size: 12px;
              }
              .desc,
              .labels {
                font-size: 12px;
              }
            }
            .icons {
              .chat-wrapper {
                max-width: 25px !important;
                margin-left: 5px !important;
              }
            }
            .icons,
            .update-info {
              * {
                font-size: 12px !important;
              }
              .user-info-avatar {
                margin-top: 0px;
              }
            }
          }
        }
      }
    }
  }
  .task-grid-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    .task-grid-col {
      background: #fff;
      height: 100%;
      min-height: 215px;
      border-radius: 10px;
      padding: 10px 20px;
      -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
      -moz-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
      box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
      .task-data {
        width: 100%;
        height: max-content;
        &.flex {
          &.footer {
            align-items: center;
          }
        }
        &.grid {
          display: grid;
          grid-template-columns: 50px auto;
          grid-column-gap: 10px;
        }
        &.border {
          background: #e2e2e2;
          height: 0.13em;
          margin: 9px 0px;
        }
        .link {
          margin-right: auto;
          color: #4083c4;
          font-size: 15px;
          margin-top: 5px;
          cursor: pointer;
          .user-info-avatar {
            justify-content: center;
            .user-photo.default {
              border: 1px solid black;
            }
            .user-photo.default.user-svg {
              border-radius: 500rem;
              margin-right: 0.25em;
            }
            .user-name {
              font-weight: 500;
            }
          }
        }
        .status {
          display: grid;
          .status-container {
            padding: 4px 15px;
            background: #66b282;
            color: white;
            font-size: 14px;
            border-radius: 6px;
            max-width: 150px;
            font-style: normal !important;
          }
          .aging {
            color: #808080;
            text-align: right;
            margin: 5px 3px;
            margin-bottom: 0px;
          }
        }
        .logo {
          padding: 7px;
          border: 1px solid #e2e2e2;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 50px;
          max-height: 50px;
          .seq {
            font-size: 14px;
            font-weight: 500;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          .name {
            font-weight: bold;
            font-size: 15px;
          }
          .desc {
            font-size: 13px;
            padding-right: 15%;
            display: -webkit-box !important;
            margin: unset !important;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            cursor: default;
            line-height: 1.3em;
            color: #808080;
          }
          .labels {
            font-size: 12px;
            width: 70%;
          }
          .labels::first-line {
            display: block;
            font-weight: 900;
          }
        }
        .update-info {
          .header {
            color: #808080;
          }
          .user-info-avatar {
            justify-content: center;
            margin-top: 5px;
            .user-photo {
              margin-right: 1em;
              &.default {
                border: 1px solid black;
                &.user-svg {
                  border-radius: 500rem;
                }
              }
            }
            .user-name {
              font-weight: 600;
            }
          }
        }
        .icons {
          .comment-icon {
            display: flex;
            align-items: center;
            .mention {
              color: #737373;
              font-size: 16px;
            }
            .chat-wrapper {
              &.light {
                background: #f1f1f1;
              }
              &.dark {
                background: #737373;
              }
              padding: 3px;
              border-radius: 50%;
              max-width: 28px;
              margin-left: 10px;
              .chat {
                width: 100%;
              }
            }
          }
          .img-ownership-type {
            margin: 8px 5px 0px auto;
          }
        }
      }
    }
  }
}
