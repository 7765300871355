.user-signature-info {
	.signature-img-container {
		border: 1px solid black;
	}
	.signature-img {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 140px;
	}
}
.user-signature-field {
	.signature-field-container {
		display: grid;
		grid-template-columns: 120px 1fr;
		grid-gap: 10px;
		.sigCanvas {
			border: none !important;
			background-color: #f8f8f8 !important;
			width: 100%;
			box-shadow: none !important;
			border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
			height: 140px;
		}
		span {
			label {
				bottom: 0;
				font-weight: 800;
			}
		}
		.ui.button {
			display: none !important;
		}
	}
}
