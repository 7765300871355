@mixin push--auto {
  margin: { 
      left: auto;
      right: auto;
  }
}

// Placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

//Media Queries for Responsive 
// $breakpoints: (
//     "phone":        400px,
//     "phone-wide":   480px,
//     "phablet":      560px,
//     "tablet-small": 640px,
//     "tablet":       768px,
//     "tablet-wide":  1024px,
//     "mid-desktop":  1100px,
//     "desktop":      1248px,
//     "desktop-wide": 1440px
// );
// @mixin mq($width, $type: min) {
//     @if map_has_key($breakpoints, $width) {
//         $width: map_get($breakpoints, $width);
//         @if $type == max {
//             $width: $width - 1px;
//         }
//         @media only screen and (#{$type}-width: $width) {
//             @content;
//         }
//     }
// }

// Named Breakpoint Values
$breakpoints: (
  small: 320px,
  medium: 640px,
  large: 1024px,
  x-large: 1600px,
  xx-large: 2800px,
  menu-large: 1100px,  
  filter-breakpoint-m: 840px, 
  filter-breakpoint: 1370px, 
);

// SCSS Breakpoint shortcut mixin
@mixin breakpoints($from: none, $to: none, $media: all) {
  // Set some empty min + max values
  $min-width: null;
  $max-width: null;

  // Determine 'from' min-width value
  @if (type-of($from) == string) and (map-has-key($breakpoints, $from)) {
    // If value is a string listed in the named breakpoints map
    $min-width: map-get($breakpoints, $from);
  }
  @else if ((type-of($from) == number) and (unitless($from) == false)) {
    // If value is a number with a unit
    $min-width: $from;
  }
  @else if ($from == none) {
    // If the value is blank
    $min-width: false;
  }
  @else {
    // If none are true, display compile error
    @error '`breakpoints()` min-width value `#{$from}` was not found.';
  }

  // Determine 'to' max-width value
  @if (type-of($to) == string) and (map-has-key($breakpoints, $to)) {
    // If value is a string listed in the named breakpoints map
    $max-width: map-get($breakpoints, $to);
  }
  @else if ((type-of($to) == number) and (unitless($to) == false)) {
    // If value is a number with a unit
    $max-width: $to;
  }
  @else if ($to == none) {
    // If the value is blank
    $max-width: false;
  }
  @else {
    // If none are true, display compile error
    @error '`breakpoints()` max-width value `#{$to}` was not found.';
  }

  // If both min-width & max-width values:
  @if ($min-width != false) AND ($max-width != false) {
    // Increment min-width value by 1px
    $min-width: $min-width + 1px;
    @media #{$media} and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
      // breakpoint mixin content:
      @content;
    }
  }
  // If only the min-width value is set:
  @else if ($min-width != false) {
    // Increment min-width value by 1px
    $min-width: $min-width + 1px;
    @media #{$media} and (min-width: #{$min-width}) {
      // breakpoint mixin content:
      @content;
    }
  }
  // If only the max-width value is set:
  @else if ($max-width != false) {
    @media #{$media} and (max-width: #{$max-width}) {
      // breakpoint mixin content:
      @content;
    }
  }
  // If min-width & max-width are empty, but special media type set:
  @else if ($media != all) {
    @media #{$media} {
      // breakpoint mixin content:
      @content;
    }
  }
  // If no custom media query values are set:
  @else {
    // Thanks for playing
    @content;
  }
}

//Display pseudo
// @mixin pseudo($display: block, $pos: absolute, $content: ''){
//   content: $content; 
//   display: $display;
//   position: $pos;
// }

//Ellipsis
@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
