.slas-main-container {
  .button-container {
    float: right;
    color: #ffffff;
    margin: 10px 20px 0px 20px;
    font-size: 12px;
    overflow: unset !important;
    button {
      float: right;
      width: 120px;
      color: #ffffff;
      margin-right: 10px;
      font-size: 12px;
    }
  }
  .sla-table-list {
    table-layout: auto;
    white-space: nowrap;
    text-align: center;
    color: #3d87ab;
    margin: 20px;
    margin-top: 15px;
    span {
      font-size: 15px;
    }
    .header-label {
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 0px;
    }
    .sla-header {
      font-size: 14px;
      height: 20px;
      padding: 5px 0px;
      font-weight: 500;
      &.green {
        color: #66b283 !important;
      }
      &.amber {
        color: #dda749 !important;
      }
    }
    .sla-column {
      text-align: center;
      padding: 15px 10px;
      min-width: 150px !important;
      max-width: 750px !important;
      .disabled {
        * {
          pointer-events: none !important;
        }
      }
      &.enum {
        width: 170px !important;
      }
      &.process,
      &.fix {
        max-width: 150px !important;
      }
      &.process {
        padding-top: 10px;
        &.header-label {
          text-align: left;
          font-size: 14px;
          font-weight: 500;
        }
      }
      &.field-value {
        min-width: 200px !important;
        position: relative;
        padding: unset;
        padding-left: 10px;
        .field-value-main {
          width: 100%;
          .field-value-header {
            position: absolute;
            top: -10px;
            width: 100%;
            .menu-line {
              background-color: #dadada;
              border-radius: 2px;
              content: "";
              height: 1px;
              bottom: 0px;
              left: 0px;
              right: 30px;
              margin: 0px;
              position: absolute;
              width: 95%;
            }
            .field-value-menu {
              display: flex;
              .value-menu,
              .field-menu {
                cursor: pointer;
                margin: 0px;
                padding: 0px 15px;
                padding-bottom: 1px;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: max-content;
                color: #000;
                &.selected {
                  color: #3d87ab;
                  .underline {
                    border-radius: 2px;
                    content: "";
                    height: 1px;
                    bottom: 0px;
                    left: 0px;
                    right: 30px;
                    margin: 0px;
                    position: absolute;
                    width: 100%;
                    background: #3d87ab;
                  }
                }
                span {
                  font-size: 12px !important;
                }
              }
            }
          }
          .field-value-content {
            .value {
              .ui.selection.dropdown {
                font-size: 13px !important;
                min-width: 275px !important;
              }
              .field-date-time-picker-main {
                position: relative;
                .close {
                  position: absolute;
                  top: 9px;
                  right: 10px;
                  font-size: 10px;
                  width: 16px;
                  cursor: pointer;
                }
                .field-date-time-picker {
                  border: 1px solid #3d87ab;
                  border-radius: 0.28571429rem;
                  min-width: 275px;
                  display: flex;
                  justify-content: flex-start;
                  input {
                    border-radius: 0.28571429rem;
                    min-height: 33px;
                    border: unset !important;
                    padding-left: 12px;
                    &:focus {
                      outline: unset !important;
                    }
                    &::placeholder {
                      color: #3d87ab;
                    }
                  }
                }
              }
            }
            .field {
              display: flex;
              .ui.selection.dropdown {
                &.select-field {
                  min-width: 170px !important;
                  max-width: 170px !important;
                }
                &.from-field {
                  min-width: 160px !important;
                  max-width: 160px !important;
                  border: unset !important;
                }
              }
            }
          }
        }
      }
      .process-label {
        width: 100%;
        height: 38px;
        color: rgb(58, 133, 168) !important;
        background-color: unset !important;
        text-align: left;
        line-height: inherit;
        font-size: 15px;
        font-weight: inherit;
        align-items: center;
        display: flex;
      }
      .ui.label.status-label {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 90px;
        width: fit-content;
        height: 100%;
        min-height: 30px;
        max-height: 30px;
        text-align: left;
        line-height: inherit;
        font-size: 13px;
        font-weight: inherit;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
        padding: 10px 14px;
        &.bg-blue {
          background: #c7eaf1;
          color: #3e8794;
        }
        &.bg-orange {
          background: #fef2de;
          color: #d89f3d;
        }
        &.bg-gray {
          background: #e5e5e5;
          color: #a4a4a4;
        }
        &.bg-red {
          background: #f6ddd6;
          color: #e34b22;
        }
      }
      .ui.icon.input {
        width: 100%;
        min-height: 35px !important;
        max-height: 35px !important;
        input {
          text-align: left;
          color: #3d87ab;
          border: 1px solid rgb(58, 133, 168) !important;
          padding: 10px 0px 10px 13px !important;
          width: 100% !important;
        }
        input[type="number"] {
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
        }
        i.caret.down.icon {
          margin-top: 16px;
          color: #3d87ab;
        }
        i.caret.up.icon {
          margin-top: 9px;
          color: #3d87ab;
        }
        i {
          opacity: 1;
          font-size: 17px;
          height: 10px;
          cursor: pointer;
          pointer-events: visible;
          margin-right: -4px;
        }
      }
      .ui.selection.dropdown {
        min-width: unset !important;
        margin-left: unset !important;
        text-align: center;
        display: flex;
        padding-left: 11px;
        align-items: center;
        justify-content: left;
        width: 100%;
        max-height: 35px !important;
        min-height: 35px !important;
        .default.text {
          color: #3d87ab;
          margin-right: 20px;
        }
        div.text {
          white-space: nowrap;
          margin-right: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          width: inherit;
          text-align: left;
        }
        div {
          div {
            text-align: center;
          }
        }
        i.dropdown.icon {
          &::before {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 7px solid #3d87ab;
            content: "";
          }
          color: #3d87ab !important;
          padding: 0.91666667em 0.91666667em 0em;
          padding-top: 21px;
        }
        i.dropdown.icon.clear {
          &:before {
            content: "\f00d";
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            border-top: 0px solid #3d87ab;
            position: absolute;
            top: 10px;
            right: 22px;
            font-size: 10pt !important;
          }
        }
      }
      .ui.input {
        input[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
          margin: 0;
        }
      }
    }
  }
}
