#geolocation-field-modal {
  width: 700px;

  .content {
    max-height: calc(100vh - 75px - 120px - 65px - 18px);
    overflow: auto;

    input,
    textarea {
      background-color: #f3f3f3;
      border: none;
      width: 100%;
    }
  }

  .date-action-container {
    div {
      div {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
    :first-child {
      #custom-field-checkbox {
        float: left;
      }
      .label-bottom {
        padding-top: 20px;
        label {
          strong {
            color: red;
          }
        }
      }
    }
    :nth-child(2) {
      margin-top: 10px;
      display: inline-block;
    }
  }
}
