.action-modal-container {
  border-radius: 5px;
  box-shadow: 0px 2px 6px #000000;
  width: 145px;
  padding: 5px;
  position: absolute;
  z-index: 13;
  background-color: #f3f3f3;
  .selected-type {
    background-color: #c0c0c0;
  }
  .action-modal-title {
    background-color: #d4e0e6;
    border: #95bacc solid 1px;
    border-radius: 3px;
    margin: auto;
    padding-left: 5px;
    width: 100%;
    height: 25px;
    &::placeholder {
      font-style: italic !important;
      font-size: 13px;
    }
  }
  .action-modal-icon {
    display: grid;
    grid-template-columns: 65px 65px;
    grid-gap: 5px;
    padding: 5px 0px;
    div {
      border: #4a8eae solid 1px;
      border-radius: 5px;
      padding: 3px;
      text-align: center;
      cursor: pointer;
      label.forward {
        color: #5eab69;
        font-size: 25px;
        font-family: auto;
        font-weight: 700;
        cursor: pointer;
      }
      label.backward {
        color: #d0675c;
        font-size: 25px;
        font-family: auto;
        font-weight: 700;
        cursor: pointer;
      }
      &:nth-child(even) {
        i {
          font-size: 25px;
        }
        label {
          line-height: 0.4;
        }
      }
    }
    i {
      font-size: 20px;
      color: #3a85a8;
      margin-top: 5px;
      width: 60px;
    }
    i.forward {
      color: #5eab69;
      vertical-align: middle;
    }
    i.backward {
      color: #d0675c;
      vertical-align: middle;
    }
  }
  .checkbox-container {
    text-align: center;
    .ui.checkbox {
      border: unset !important;
      font-size: 13px;
      font-weight: 500;
      label {
        color: #808080;
        &::before {
          border: 1px solid #0087a9 !important;
        }
        &::after {
          color: #0087a9 !important;
        }
      }
    }
  }
  .action-modal-btn {
    width: 20px;
    margin: 3px 0px;
    cursor: pointer;
  }
  .action-modal-btn.cancel {
    margin-right: auto;
  }
  .action-modal-delete-button {
    background-color: #d3aea9;
    border: #d2958f 1px solid;
    width: 100%;
    &:hover {
      background-color: #d3aea9;
      opacity: 0.8;
    }
    &:active {
      background-color: #d3aea9;
      opacity: 0.8;
    }
  }
  .action-modal-grid {
    display: grid;
  }
  .action-modal-revert {
    display: revert;
  }
  .icon-group-end-status {
    margin: 5px;
  }
  input[type='text'].error {
    background-color: #ff8585 !important;
  }
  input {
    display: block;
    position: relative;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
