@import "../../../../css/mixins.scss";

.tasks-filter-main {
  display: grid;
  grid-template-columns: auto 200px;
}

#fltr-popup {
  padding: 5px 10px;
  .header {
    font-size: 12px !important;
    margin-top: 4px;
  }
  .sla-filter {
    margin: 5px 0px;
    .fltr-image {
      background: #f1f1f1;
      margin-right: 10px;
      padding: 7px 9px;
      border-radius: 5px;
      img {
        margin-right: unset !important;
      }
    }
  }
  .status-filter {
    .fltr-status-wrapper {
      margin-top: 5px;
      margin-bottom: 5px;
      .fltr-status {
        margin-right: 8px;
        padding: 0px 10px;
        font-size: 10px;
        border-radius: 4px;
        &.selected {
          color: #fff;
        }
      }
    }
    .all {
      top: -1px;
      position: relative;
      margin-right: 25px;
      margin-left: 5px;
    }
  }
  .all {
    font-size: 11px !important;
    font-weight: bolder;
  }
  .filter-action {
    position: absolute !important;
    right: 15px;
    margin-top: 8px;
    z-index: 10;
    .pending {
      margin-right: 5px;
    }
    svg {
      width: 17px;
      height: 17px;
    }
  }
}

@include breakpoints(240px, 480px) {
  .fltr-container {
    .urn-filter-field {
      margin-left: 0px !important;
      .dropdown {
        font-size: 10px;
        min-height: unset !important;
        min-width: 100px !important;
      }
    }
    .fltr-group {
      margin-right: 0px !important;
    }
  }
}

.fltr-container {
  &.grouped {
    .urn-filter-field {
      margin-left: 5px;
      .dropdown {
        font-size: 12px;
        min-height: unset !important;
        min-width: 110px !important;
      }
    }
    .display-filter {
      margin-right: 10px;
    }
  }
  width: 100%;
  .filter {
    border-right: 1px solid #8e8e8e;
    * {
      cursor: pointer;
    }
  }
  .custom-filter-container {
    padding: 0px 20px;
    border-radius: unset;
    height: max-content;
    width: 65%;
    margin: auto;
    .filter-field-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
    .filter-field-container {
      grid-row-gap: 10px;
      grid-column-gap: 20px;
      .field-input {
        height: 35px;
      }
      .btn-clear {
        position: relative;
        width: 12px;
        min-width: 12px;
        margin: 0px 0px 5px 10px;
        cursor: pointer;
      }
      .field-label {
        margin-bottom: 0.5em;
        font-weight: normal;
        font-size: 13px;
      }
      .field-range-container {
        height: 100%;
        text-align: center;
        color: #000;
        grid-column: span 2;
        width: 100%;
        div {
          span {
            display: none;
          }
        }
        span {
          text-align: left;
          label {
            display: none;
          }
        }
        .range-field {
          .field-col {
            background-color: #fff;
            border-radius: 0.28571429rem;
            height: 35px;
            input {
              border: 1px solid #d4d4d4;
              &::placeholder {
                color: #666669;
              }
            }
          }
          .flex-container {
            width: 100%;
            .field-icon {
              margin: 9px 5px;
              top: 0px;
              left: 6px;
              z-index: 5;
              position: absolute;
              &.number {
                top: 5px;
                width: 20px;
              }
              &.time {
                width: 18px !important;
              }
              &.date {
                width: 17px;
              }
            }
          }
          input {
            padding: 9px 40px !important;
            &::placeholder {
              color: #666669;
              font-size: 13px !important;
            }
          }
        }
      }
      .field-container {
        width: 100%;
        height: 40px;
        text-align: center;
        color: #000;
        span {
          text-align: left;
          label {
            display: none;
          }
        }
        .flex-container {
          width: 100%;
        }
      }
      .react-datepicker-wrapper {
        width: 100%;
        div {
          width: inherit;
          input {
            width: inherit;
            &::placeholder {
              color: #666669;
            }
          }
        }
      }
      .ui.input {
        width: 100%;
        input {
          height: 35px;
          border: none;
          max-width: 100%;
          width: 100%;
          background: #f1f1f1;
          &::placeholder {
            color: #666669;
          }
        }
      }
      div.react-datepicker-wrapper {
        div {
          input {
            border: none;
            border-radius: 0.28571429rem;
            padding: 9px 10px 9px 10px;
            font-size: 13px;
            background: #f1f1f1;
            &::placeholder {
              color: #666669;
            }
          }
        }
      }
      .ui.selection.dropdown {
        margin: 0px !important;
        font-size: 13px;
        div {
          z-index: 20;
        }
      }
    }
    div {
      div {
        .field-icon-container {
          @include push--auto;
          min-width: 90px;
        }
        .range-field {
          flex-basis: 70%;
        }
      }
    }
    .react-datepicker-popper {
      z-index: 20;
    }
  }
  .multiselect-dropdown {
    max-width: 250px;
    .labeled.dropdown {
      margin-top: unset !important;
      border-bottom: unset !important;
      background-color: #f1f1f1 !important;
      height: 35px;
      .option-list {
        .ui.checkbox {
          label {
            &::after,
            &::before {
              border-color: black;
              color: black;
            }
          }
        }
      }
    }
  }
  .ui.selection.dropdown {
    background-size: 0 !important;
    border-radius: 0;
    background-color: #f1f1f1;
    color: #000 !important;
    margin: unset !important;
    box-shadow: unset;
    border: none !important;
    border-radius: 3px;
    width: 100%;
    font-size: 13px;
    .angle.down {
      cursor: pointer;
      position: absolute;
      width: auto;
      height: auto;
      line-height: 1.21428571em;
      top: 0.6em;
      right: 1em;
      z-index: 3;
      margin: -0.78571429em;
      padding: 0.91666667em;
      opacity: 0.8;
      transition: opacity 0.1s ease;
      &.clear {
        font-size: 13px;
      }
    }
    .default.text {
      color: #666669;
    }
    .menu {
      z-index: 100 !important;
    }
  }
  .status-filter-field {
    align-self: baseline;
    .ui.selection.dropdown {
      min-width: 150px;
      min-height: 35px;
      max-width: 150px;
    }
  }
  .urn-filter-field {
    .ui.input {
      width: 100%;
      min-width: 250px;
      min-height: 35px;
      input {
        height: 35px;
        border: none;
        max-width: 100%;
        width: 100%;
        background: #f1f1f1;
        &::placeholder {
          color: #666669;
        }
      }
    }
  }
  .sla-filter {
    min-width: 75px;
  }
  .display-filter {
    margin-left: auto;
    margin-right: 15px;
    img {
      width: 20px;
      min-width: 20px;
      margin-right: 10px;
    }
    .fltr-list {
      height: 17px;
      &.disabled {
        opacity: 1;
      }
    }
  }
  .status-filter {
    min-width: 120px;
    padding: 0px 15px;
    .fltr-status.selected {
      border: 1px solid black;
    }
    .fltr-status-wrapper {
      .fltr-status {
        width: 17px;
        height: 13px;
        margin-right: 9px;
      }
    }
  }
  .fltr-group {
    border-radius: 3px;
    margin-right: 10px;
    padding: 4px;
    background: #ececec;
    .icon {
      width: 17px;
      min-width: 17px;
      height: 16px;
    }
  }
  .all {
    font-size: 10px;
    color: #000;
    font-weight: bolder;
  }
}
