.frontend-automation-container {
  position: relative;
  .automation-header {
    display: flex;
    justify-content: space-between;
    .field-label {
      font-weight: 700;
      color: #3b86a8;
      font-size: 14px;
      position: relative;
      top: 5px;
    }
    .automation-header-tab-main {
      position: relative;
      .line {
        background-color: #e0e0e0;
        border-radius: 2px;
        content: "";
        height: 2px;
        bottom: 0px;
        left: 0px;
        right: 30px;
        margin: 0px;
        position: absolute;
        width: 100%;
      }
    }
    .automation-header-tab {
      display: flex;
      .tab {
        cursor: pointer;
        line-height: 1.5;
        margin: 0px;
        padding: 2px 15px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        color: #a4a4a4;
        &.active {
          border-radius: 0.28571429rem 0.28571429rem 0 0 !important;
          font-weight: 500;
          color: #3a86a7;
          border-bottom: 2px solid #3a86a7;
        }
      }
    }
  }

  .frontend-automation-config-selection {
    color: #e0e0e0;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 5px;
    margin-top: 15px;
    width: fit-content;
    span {
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
      &.active {
        color: #3b86a8;
        border-bottom: #3b86a8 2px solid;
        padding-bottom: 7px;
      }
    }
  }

  .condition-join-method {
    grid-template-columns: 100px 100px;
    display: grid;
    margin-bottom: 15px;
    padding-top: 15px;
    .condition-container {
      label {
        padding: 0px 20px;
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        background-color: #f9f9f9;
        cursor: pointer;
      }
      label.selected {
        border: 1px solid #7ca7b9;
        background-color: #3b86a8;
        color: #ffffff;
      }
    }
  }

  .bool-selection {
    color: #e0e0e0;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 5px;
    margin-top: 20px;
    span {
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
      &.selected {
        color: #3b86a8;
        border-bottom: #3b86a8 2px solid;
        padding-bottom: 7px;
      }
    }
  }

  .clear-switch {
    &.ui.fitted.toggle.checkbox {
      margin-top: 20px;
      label {
        margin-left: 60px;
      }

      margin-left: 10px;
      label:before,
      label:after {
        margin-top: 2px;
        border-radius: 10px;
        margin-left: -60px;
      }
      label:before {
        height: 18px;
      }
      input:checked ~ label:after {
        width: 14px;
        height: 14px;
        margin-top: 4px;
        margin-left: -59px;
      }
      input ~ label:after {
        width: 14px;
        height: 14px;
        margin-top: 4px;
        margin-left: -57px;
      }
    }
  }

  .type-selection {
    color: #e0e0e0;
    padding-bottom: 5px;
    margin-top: 20px;
    text-align: right;
    margin-bottom: 20px;
    span {
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
      border-bottom: 2px solid #e0e0e0;
      padding-bottom: 5px;
      &.selected {
        color: #3b86a8;
        border-bottom: #3b86a8 2px solid;
        padding-bottom: 5px;
      }
    }
  }
  .value-config {
    min-height: 150px;
    padding: 5px;
    .condition-value {
      .react-datepicker-wrapper {
        width: 100%;
      }
      .field-info {
        display: none;
      }
      .field-input {
        width: 100%;
        border: 1px solid #f3f3f3 !important;
      }
      input {
        width: 100%;
        padding: 0.67857143em 1em;
        height: 100%;
        margin: 0;
        max-width: 100%;
        flex: 1 0 auto;
        outline: 0;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        text-align: left;
        line-height: 1.21428571em;
        font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
        background: #fff;
        color: rgba(0, 0, 0, 0.87);
        border-radius: 0.28571429rem;
        transition: box-shadow 0.1s ease, border-color 0.1s ease;
        box-shadow: none;
      }
    }

    .condition-field {
      display: flex;
      justify-content: flex-end;
    }
    .assemble-config {
      margin-top: 10px;

      .header-detail {
        .field-selection {
          float: right;
          width: 200px;
        }
      }

      .selected-field-container {
        width: 100%;
        margin-top: 40px;
        border: 1px solid #3b86a8;
        padding: 10px;
        border-radius: 5px;
        min-height: 80px;

        .selected-field {
          background: #8a9296;
          padding: 2px 25px 2px 5px;
          font-size: 12px;
          color: #ffffff;
          width: fit-content;
          border-radius: 3px;
          position: relative;
          display: inline-block;
          margin-right: 5px;
          margin-bottom: 5px;
          i {
            position: absolute;
            right: 0px;
            cursor: pointer;
          }
        }
      }

      .properties {
        grid-template-columns: 1fr;
        display: grid;
        row-gap: 10px;
        margin-top: 15px;

        > label {
          font-weight: 700;
        }

        input {
          width: 100px;
          margin-left: 5px;
          height: 30px !important;
          border: 1px solid #3d87ab;
          padding-left: 5px !important;
          padding-right: 5px !important;
          border-radius: 5px;
        }
        .checkbox {
          margin-left: 10px;
          margin-top: 5px;
          width: max-content;

          label {
            margin-left: 25px;

            &::before {
              margin-left: -25px;
              border-color: #3d87ab;
            }
            &::before {
              margin-left: -25px;
            }
          }
        }
        input:checked ~ label:after {
          margin-left: -25px;
          color: #3d87ab;
          border-color: #3d87ab;
        }
      }
    }
  }
}
