.automation-form-detail-new-action {
  height: 100%;
  padding: 5px 30px;
  overflow: auto;
}

.automation-form-detail-action {
  height: calc(100vh - 180px);
  padding-bottom: 30px;
  padding: 0px 25px;
  overflow: auto;
  .automation-field-section {
    margin-top: 15px;
    border: 1px solid #3b86a8;
    border-radius: 3px;
    padding: 2px;
    .automation-field-header {
      display: flex;
      justify-content: space-between;
      background: #e8e8e8;
      border-radius: 3px;
      padding: 5px 10px;
      .header-right {
        .icon {
          color: #3a85a8;
          font-size: 16px;
          cursor: pointer;
        }
        .count {
          background: #3a85a8;
          color: #fff;
          padding: 0.4em 0.4em 0.3em;
          border-radius: 0.2em;
          font-size: 0.8em;
          margin-right: 10px;
          position: relative;
          top: -2px;
        }
      }
      label {
        font-weight: 500;
      }
    }
    .automation-field-content {
      padding: 5px;
    }
    .automation-field-container {
      padding: 0px 5px;
      padding-top: 3px;
      width: 96%;
    }
  }
}
