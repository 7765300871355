@import "../../../../css/theme-variables";
@import "../../../../css/mixins";
.task-bulk-actions-container {
  height: 32px;
  margin-left: auto;
  .btn-action-collapse {
    height: 100% !important;
    padding: 0px 7px !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    margin-right: unset !important;
    width: 50px;
    z-index: 10;
    .btn-action-label {
      display: block;
    }
    &:hover,
    &:focus,
    &.active {
      background-color: $table-row-hover;
    }
    .dynamic-actions-collapse {
      position: relative;
      color: rgba(0, 0, 0, 0.76);
      border-radius: 4px !important;
      height: 100% !important;
      &::after {
        content: "•••";
        position: absolute;
        top: 20%;
        left: 25%;
        font-size: 10px;
        letter-spacing: 1px;
        margin-top: 2px;
      }
    }
    &.hasDynamicAction {
      width: 35px !important;
      margin-left: 5px;
      .dynamic-actions-collapse {
        &::after {
          content: "•••";
          position: absolute;
          top: 48%;
          left: 55%;
          transform: translate(-50%, -50%) rotate(90deg);
          font-size: 10px;
          letter-spacing: 1px;
          margin-top: 2px;
        }
      }
    }
  }
}

#bulk-actions-popup {
  padding: 0px;
  border-radius: 5px !important;
  top: -9px !important;
  left: auto !important;
  z-index: 30;
  .bulk-actions-popup-container {
    width: 150px;
    max-height: 400px;
    overflow: auto;
  }
  .bulk-actions-content {
    &:hover {
      background-color: $table-row-hover;
    }
    * {
      cursor: pointer;
    }
    padding: 8px 15px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid transparent !important;
      border-image: linear-gradient(to right, white 0%, #e6e6e6 50%, white 100%) !important;
      border-image-slice: 1 !important;
      border-image-width: 0 0 3px 0;
      border-image-repeat: stretch;
      border-top: 0;
    }
    &.warning {
      line-height: 16px;
    }
    .action-label {
      font-size: 14px;
    }
  }
}
