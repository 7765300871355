@import '../../../../css/mixins.scss';

@include breakpoints(240px, 320px) {
  .legend-container {
    min-width: 70px !important;
  }
}

.legend-container {
  min-width: 115px;
  background: #fff;
  z-index: 20;
  height: max-content;
  margin-left: 20px;
  margin-right: 10px;

  @include breakpoints(480px, 1070px) {
    min-width: 100px !important;
    div {
      margin: 2px 0px !important;
      div {
        height: 20px !important;
        width: 30px !important;
      }
      span {
        font-size: 13px;
      }
    }
  }
  @include breakpoints(240px, 480px) {
    min-width: 80px !important;
    margin-right: 0px;
    div {
      margin: 1px 0px !important;
      div {
        height: 18px !important;
        width: 25px !important;
      }
      span {
        font-size: 12px;
      }
    }
  }
  div {
    display: flex;
    align-items: center;
    margin: 3px 0px;
    div {
      height: 23px;
      width: 40px;
    }
    span {
      margin-left: 10px;
      font-weight: 500;
    }
  }
}
