.automation-form-container {
  .automation-form-detail {
    .add-component {
      padding: 20px;
      .add-component-header {
        display: grid;
        grid-template-columns: 1fr;
        .title {
          font-weight: 700;
        }
        .description {
          font-size: 10px;
        }
      }
    }
  }
}
