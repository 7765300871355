.response-message-container {
  position: fixed;
  z-index: 300;
  right: 20px;
  background: transparent;
  top: 80px;
  width: 10px;
}

.response-message-widget {
  max-width: 280px;
  min-width: 280px;
  float: right;
  .response-message {
    margin-right: 10px;
  }
  .close.icon {
    top: 13px !important;
    right: 10px !important;
  }
}
