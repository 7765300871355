#fx-field-modal {
  max-width: 920px !important;
  .content {
    padding-bottom: 0px;
    .label {
      font-weight: normal !important;
      strong {
        color: red;
      }
    }
    .label-bottom {
      strong {
        color: red;
      }
      font-style: italic !important;
      font-size: 14px !important;
      margin-top: 12px;
      text-align: end;
    }
  }
  .operator-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    border: 1px solid #c2d3db;
    border-radius: 10px !important;
    min-width: 430px !important;
    margin-top: 14px;
    padding: 10px;
    label {
      font-weight: 500 !important;
    }
    .operator-button {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      button {
        background-color: #ffffff;
        border-radius: 5px;
        border: 1px solid #6fa3c0;
        font-size: 14px;
        width: 41px;
        height: 40px;
        padding: 5px 8px;
        margin-right: 10px;
        cursor: pointer;
      }
      button.value {
        width: 83px;
      }
    }
    .dropdown.selection {
      margin-left: 0px !important;
      min-height: unset !important;
      margin-top: 5px !important;
      height: 40px;
    }
    .operator-value-container {
      display: grid;
      grid-template-columns: 1fr;
      .value-options {
        font-size: 15px;
        display: grid;
        grid-template-columns: 30px 60px 60px 60px;
        column-gap: 5px;
        vertical-align: middle;
        > div {
          text-align: center;
          border: 1px solid #3b86a9;
          height: 25px;
          border-radius: 5px;
          &.selected {
            color: #ffffff;
            background-color: #3b86a9;
          }
        }
      }
      .value-field {
        div {
          .error {
            background-color: #ff8585 !important;
          }
          > .icon {
            color: #3b86a9;
            min-height: unset !important;
          }
          > .dropdown {
            > .text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: inherit;
              top: -3px;
            }
          }
          &.normal-field {
            display: grid;
            grid-template-columns: 1fr 40px;
            column-gap: 10px;
            > .icon {
              margin-top: 12px;
              cursor: pointer;
            }
          }
          &.value-field {
            display: grid;
            grid-template-columns: 1fr 40px;
            column-gap: 10px;
            > .icon {
              margin-top: 11px;
              cursor: pointer;
            }
            input {
              background-color: #f3f3f3;
              height: 38px;
            }
            input[type="number"] {
              margin-top: 5px;
            }
          }
          &.array-field {
            display: grid;
            grid-template-columns: 1fr 1fr 40px;
            column-gap: 10px;
            > .icon {
              margin-top: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .field-info-properties-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
  .formula-contaner {
    margin-top: 15px;
    label {
      font-weight: 500;
    }
    .selected-formula {
      min-height: 150px;
      background-color: #f3f3f3;
      border-radius: 5px;
      padding: 10px;
      font-size: 14px;
      .field {
        display: inline-block;
        border: 1px solid #6fa3c0;
        border-radius: 5px;
        padding: 9px 15px;
        background-color: #ffffff;
        margin-right: 10px;
        text-align: center;
        height: 40px;
        &.aggregate {
          border: none;
          background-color: #dedddd;
          color: #3b86a9;
        }
      }
      .operator {
        display: inline-block;
        border: 1px solid #6fa3c0;
        border-radius: 5px;
        padding: 6px 13px;
        background-color: #ffffff;
        margin-right: 10px;
        text-align: center;
        height: 40px;
        span {
          font-size: 17px;
        }
        i {
          float: right;
          font-size: 10px;
          margin-left: -25px;
          margin-top: -5px;
        }
      }
      .value {
        display: inline-block;
        display: inline-block;
        border: 1px solid #6fa3c0;
        border-radius: 5px;
        padding: 9px 0px;
        background-color: #ffffff;
        margin-right: 10px;
        text-align: center;
        input {
          display: inline-block;
          border-radius: 5px;
          margin-right: 10px;
          min-width: 80px;
          max-width: 80px;
          text-align: center;
          background-color: #ffffff !important;
          color: #000;
          height: 20px;
        }
        input[type="number"] {
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
        }
        i {
          float: right;
          font-size: 10px;
          margin-left: -25px;
          margin-top: -5px;
        }
      }
    }
  }
  .drag-delete-container {
    display: flex;
    border: 1px dashed #a6a6a6;
    color: #a6a6a6;
    height: 60px;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-style: italic;
    font-weight: 300;
    img {
      margin-right: 8px;
    }
  }
}
