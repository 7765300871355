$black: #666;
$border_color: #efefef;

#cron-grid-picker_monthday_popup,
#cron-grid-picker_hour_popup,
#cron-grid-picker_minute_popup,
#cron-normal-picker_period_popup,
#cron-normal-picker_month_popup,
#cron-normal-picker_week_popup {
  filter: unset;
}

#cron-normal-picker_period_popup,
#cron-normal-picker_month_popup,
#cron-normal-picker_week_popup {
  .e-list-item.e-active {
    color: #3b85a8;
    border: unset;
  }
}

#cron-grid-picker_monthday_popup,
#cron-grid-picker_hour_popup,
#cron-grid-picker_minute_popup {
  .e-list-parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .e-list-item {
      text-align: center;
      padding-right: 0;
      text-indent: 0;
      &.e-active {
        color: #3b85a8;
        border: unset;
      }
    }
  }
}

.cron-tab-container {
  display: flex;
  flex-direction: column;

  .e-cron-select {
    border: unset !important;
    border-radius: 2px;
    background: #f3f3f3 !important;
    max-height: 38px;
    &.e-ddl {
      padding: 5px 12px;
    }
    &::before,
    &::after {
      background: transparent !important;
    }
    .e-multi-select-wrapper {
      padding: 5px 10px;
      cursor: pointer;
      input {
        cursor: pointer;
      }
      .e-chips-close.e-close-hooker {
        top: 90%;
        right: 30px;
        color: #3b85a8;
      }
    }
    .e-ddl-icon {
      margin-top: 6px;
      color: #3b85a8;
      &::before {
        content: "\e94d" !important;
      }
    }
  }

  .form-control-wrapper {
    .cron-select {
      height: 30px;
      width: 140px;
    }
    &.hour,
    &.day,
    &.week,
    &.month,
    &.year {
      .e-cron-select {
        &.e-input-focus {
          .e-chips-close.e-close-hooker {
            right: 10px !important;
          }
          &.e-valid-input {
            .e-ddl-icon {
              display: none;
            }
          }
        }
        .e-multi-select-wrapper {
          .e-delim-values {
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 85%;
          }
        }
      }
    }
    &.hour {
      .e-time-picker {
        .minute-picker {
          position: relative;
          .supporting-words {
            &:last-child {
              position: absolute;
              right: 70px;
              top: 55%;
            }
          }
          .form-control-wrapper {
            max-width: 70%;
          }
        }
      }
    }
    &.month {
      .e-day-month-picker {
        display: grid;
        grid-template-columns: calc(50% - 5px) calc(50% - 5px);
        grid-column-gap: 10px;
      }
    }
    &.year {
      .e-day-month-picker {
        display: grid;
        grid-template-columns: 122px 181px 153px;
        grid-column-gap: 5px;
      }
    }
    &.year,
    &.month,
    &.day,
    &.week {
      .e-time-picker {
        display: grid;
        grid-template-columns: calc(50% - 5px) calc(50% - 5px);
        grid-column-gap: 10px;
        position: relative;
        .e-cron-select {
          z-index: 2;
        }
        .hour-picker {
          &::after {
            content: ":";
            position: absolute;
            bottom: 12px;
            font-size: 16px;
            width: 100%;
            text-align: center;
          }
        }
        .minute-picker {
          .form-control-wrapper {
            position: relative;
            top: 4px;
          }
        }
      }
    }
    .field {
      margin-top: 10px;
    }
  }

  .supporting-words {
    font-size: 12px;
    font-style: inherit;
    line-height: 1.33333;
    color: #757575;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .e-btn-clear {
    background: #3a85a8;
    border-radius: 3px;
    font-weight: 500;
    color: #fff;
    padding: 8px 11px;
    margin-top: 15px;
    font-size: 13px;
    margin-left: auto;
    &:focus,
    &:hover {
      background: #3a85a8;
      color: #fff;
      opacity: 0.85;
    }
  }
}
