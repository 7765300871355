#label-component {
  text-align: left;
  .label-component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bolder;
  }
  .label-component-description {
    text-align: left;
  }
}
