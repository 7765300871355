@import "../../../../css/mixins";
.reports-main-container {
  .documents-filter-main-container {
    max-height: 200px;
    overflow: visible;
  }
  .custom-filter-container {
    padding: 1em;
    border-radius: unset;
    width: 100%;
    background-color: #eaeaea;
    height: max-content;

    @include breakpoints(1480px, 100vw) {
      .filter-field-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
    }
    @include breakpoints(filter-breakpoint-m, 1480px) {
      .filter-field-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .btn-clear {
          top: 20px;
        }
        .field-container {
          height: auto !important;
          margin-bottom: 5px !important;
        }
        .field-range-container {
          margin-bottom: 15px !important;
        }
      }
    }
    @include breakpoints(small, filter-breakpoint-m) {
      .filter-field-container {
        grid-template-columns: 1fr !important;
        .field-container {
          height: auto !important;
          margin-bottom: 5px !important;
        }
        .field-range-container {
          margin-bottom: 30px !important;
        }
      }
    }
    .filter-field-container {
      grid-row-gap: 10px;
      grid-column-gap: 20px;
      margin-bottom: 10px;
      .field-input {
        height: 35px;
      }
      .btn-clear {
        position: relative;
        width: 12px;
        min-width: 12px;
        margin: 0px 0px 5px 10px;
        top: 17px;
        cursor: pointer;
      }
      .field-label {
        margin-bottom: 0.5em;
        font-weight: normal;
        font-size: 13px;
      }
      .field-range-container {
        width: 100%;
        height: 40px;
        text-align: center;
        color: #000;
        grid-column: span 2;
        width: 100%;
        margin-bottom: 20px;
        .field-range-clear {
          width: 12px;
          min-width: 12px;
          margin: 5px 0px 5px 10px;
          cursor: pointer;
        }
        div {
          span {
            display: none;
          }
          .flex-wrapper {
            width: 100%;
          }
        }
        span {
          text-align: left;
          label {
            min-width: 100px;
            display: block;
            font-size: 13px;
          }
        }
        .range-field {
          .field-col {
            background-color: #fff;
            border-radius: 0.28571429rem;
            height: 35px;
          }
          .flex-container {
            width: 100%;
            .field-icon {
              margin: 9px 5px;
              top: 0px;
              left: 6px;
              z-index: 5;
              position: absolute;
              &.number {
                top: 5px;
                width: 20px;
              }
              &.time {
                width: 18px !important;
              }
              &.date {
                width: 17px;
              }
            }
          }
          input {
            padding: 9px 40px !important;
            &::placeholder {
              color: #747492 !important;
              font-size: 13px !important;
            }
          }
        }
      }
      .field-container {
        width: 100%;
        height: 40px;
        text-align: center;
        color: #000;
        margin-bottom: 15px;
        span {
          text-align: left;
          label {
            min-width: 110px;
            display: block;
          }
        }
        .flex-container {
          width: 100%;
        }
      }
      .react-datepicker-wrapper {
        width: 100%;
        div {
          width: inherit;
          input {
            width: inherit;
          }
        }
      }
      .ui.input {
        width: 100%;
        input {
          height: 35px;
          border: none;
          max-width: 100%;
          width: 100%;
        }
      }
      div.react-datepicker-wrapper {
        div {
          input {
            border: none;
            border-radius: 0.28571429rem;
            padding: 9px 10px 9px 10px;
            font-size: 13px;
          }
        }
      }
      .multiselect-dropdown {
        max-width: 375px;
        .labeled.dropdown {
          margin-top: unset !important;
          border-bottom: unset !important;
          height: 35px;
          .option-list {
            .ui.checkbox {
              label {
                &::after,
                &::before {
                  border-color: black;
                  color: black;
                }
              }
            }
          }
        }
      }
      .ui.selection.dropdown {
        border: 1px solid transparent !important;
        margin: 0px !important;
        font-size: 13px;
        background-color: #ffffff;
        width: 100%;
        div {
          z-index: 20;
        }
      }
    }
    div {
      div {
        .field-icon-container {
          // margin-right: auto;
          // margin-left: auto;
          @include push--auto;
          min-width: 90px;
        }
        .range-field {
          flex-basis: 70%;
          // min-width: 385px;
        }
      }
    }
    .react-datepicker-popper {
      z-index: 20;
    }
  }
}

.tasks-main-container {
  .custom-filter-container {
    .field-range-clear {
      width: 14px;
      height: 14px;
      margin: 0.8em 0.6em;
      cursor: pointer;
    }
  }
}
