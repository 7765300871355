@import "../../../../../../css/theme-variables";
.business-process-container {
  height: calc(100vh - 75px - 86px - 68px);
  position: relative;
  .add-business-process-btn {
    background-color: #3b86a8;
    color: #ffffff;
    float: right;
    padding: 5px 10px;
    font-size: 12px;
  }
  .business-process-table {
    width: 100%;
    tbody {
      td {
        &:first-child {
          width: $col-fix-width-xs;
          max-width: $col-fix-width-xs;
          min-width: $col-fix-width-xs;
        }
        padding: 3px !important;
        border-right: 0.5px solid #fff;
        font-size: 10pt;
        text-align: center;
        color: #000;
        i {
          font-size: 13px !important;
          color: #3a85a8;
        }
        &:last-child {
          background-color: #ffffff;
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          width: 40px;
          font-size: 12px;
        }
      }
      .bp-action {
        width: 180px;
      }
      tr {
        &:nth-child(odd) {
          background-color: #f3f3f3;
        }
      }
      .table-checkbox-toggle {
        z-index: 0;
        min-height: 15px;
        display: flex;
        margin: auto;
        width: fit-content;
        align-items: center;
        justify-content: center;
        padding: 4px;
        input {
          max-width: 30px;
        }
        label {
          cursor: pointer;
          min-height: inherit;
          padding-left: 37px;
        }
        input {
          ~ label {
            &:before {
              width: 30px;
              height: 13px;
            }
            &:after {
              width: 11px;
              height: 11px;
              margin-top: 1px;
              left: 1px;
            }
          }
          &:checked {
            ~ label {
              &:before {
                width: 30px;
                height: 13px;
              }
              &:after {
                width: 11px;
                height: 11px;
                margin-top: 1px;
                left: 1.3rem;
              }
            }
          }
        }
      }
    }
    thead {
      th {
        &:first-child {
          max-width: 35px !important;
        }
        &:last-child {
          background-color: #ffffff;
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          top: 0;
          z-index: 2;
        }
      }
      th.th-group {
        font-size: 13px !important;
        padding: 3px 10px !important;
        background-color: #3a85a8 !important;
        color: #ffffff !important;
        height: 30px !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 5;
      }
    }
  }
  .filter-wrapper {
    margin: unset;
    padding-bottom: unset;
    justify-content: space-between;
    margin-top: 30px;
    .toolbar {
      display: flex;
      align-items: center;
      &.left {
        .dropdown {
          margin-top: 10px;
        }
      }
    }
    .btn {
      cursor: pointer;
      position: relative;
      top: 2px;
      padding: 2px 5px;
      display: flex;
      align-items: center;
      .title {
        padding: 0px 3px;
        font-size: 13px;
      }
    }
  }
  .download-popup-container {
    height: 30px;
    .btn-download {
      margin-left: unset !important;
      background-color: transparent !important;
      width: 40px !important;
      .dynamic-actions-collapse {
        font-size: 25px;
        display: flex;
        justify-content: center;
        height: 100%;
        cursor: pointer;
      }
      &:hover,
      &:focus {
        background-color: #e1e5e8 !important;
      }
    }
  }
}
