.status-action-table {
  table-layout: auto;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  border-spacing: unset;
  .bg-blue {
    background-color: #3a85a8 !important;
  }
  .bg-gray {
    background-color: #e2e2e2 !important;
  }
  .bg-dark-gray {
    background-color: #c0c0c0 !important;
  }
  .bg-orange {
    background-color: #f39c52 !important;
  }
  .bg-red {
    background-color: #d16357 !important;
  }
  .bg-green {
    background-color: #5eaa69 !important;
  }
  .text-red {
    color: #e87265 !important;
    font-style: italic;
  }
  .text-blue {
    color: #3a85a8 !important;
  }
  .text-white {
    color: #ffffff !important;
  }
  .dark {
    color: #5e5e5e !important;
  }
  .light {
    color: #ffff !important;
  }
  > thead {
    > tr {
      height: 50px;
      > th {
        border: 1px solid #fff;
        position: sticky;
        top: 0;
        border-bottom: 5px solid #fff;
        z-index: 5;
        div.ui.label {
          svg {
            position: relative;
            top: -2px;
          }
        }
        .header-text {
          cursor: pointer;
          min-width: 200px;
          max-width: 400px;
          margin-right: auto;
          margin-left: 3px;
          font-size: 15px !important;
          text-align: left;
        }
        &:last-child {
          background-color: #c0c0c0;
          position: sticky;
          right: 0;
          top: 0;
          z-index: 13;
          border-left: 5px solid #fff;
          .header-text {
            text-align: center;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            svg {
              margin-right: 5px;
            }
          }
        }
        > div {
          background-color: transparent !important;
          width: 100% !important;
          margin: unset;
          color: #ffffff;
          > i.ellipsis.vertical.icon {
            float: right;
            color: #ffffff;
            position: relative;
            margin-right: 13px !important;
          }
          > i.align.justify.icon {
            float: left;
            color: #ffffff;
            position: relative;
            top: 2px !important;
            cursor: grab;
          }
          > .drag-icon {
            float: left;
            color: #ffffff;
            position: relative;
            cursor: grab;
            width: 12px !important;
            height: 12px !important;
            margin-left: 10px;
          }
        }
      }
    }
  }
  > tbody {
    > tr {
      height: 40px;
      > td {
        border: 1px solid #fff;
        &.bg-gray {
          background-color: #f3f3f3;
        }
        .action-button {
          .action-label {
            display: flex;
            align-items: center;
            margin-left: 10px;
            label.action {
              min-width: 200px;
              max-width: 400px;
              text-align: left;
              font-size: 15px !important;
              margin-left: 3px;
              font-weight: 500;
            }
            svg {
              position: relative;
              top: -2px;
            }
          }
          .icon-group {
            padding: 1px 0px;
            border-radius: 5px;
            float: right;
            margin-top: -5px;
            max-height: 38px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 40px;
            i.users.icon {
              margin: 0;
              color: #3d87ab;
              width: 100%;
            }
            i.user.icon {
              margin: 0;
              color: #3d87ab;
              width: 100%;
            }
            label.forward {
              margin: 0;
              color: #5eab69;
              font-family: auto;
              font-size: 14px;
              position: relative;
              top: 5px;
            }
            label.backward {
              margin: 0;
              color: #d0675c;
              font-family: auto;
              font-size: 14px;
              position: relative;
              top: 5px;
            }
            label.return {
              margin: 0;
              margin-top: 2px;
              font-size: 14px;
              position: relative;
              top: 4px;
            }
          }
          > i.ellipsis.vertical.icon {
            margin-top: 2px;
            float: right;
          }
        }
        &:last-child {
          div.ui.label {
            background-color: transparent !important;
            width: 100% !important;
            margin: unset;
            color: #ffffff;
            white-space: normal;
            display: flex;
            justify-content: center;
            label {
              min-width: 150px;
              max-width: 400px;
              font-size: 15px !important;
              padding: 0px 20px;
            }
          }
          position: sticky;
          right: 0;
          border-left: 5px solid #fff;
        }
        > div.action-button {
          display: flex;
          width: 100%;
          min-width: 200px;
          white-space: normal;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;
          margin-top: 0;
          > label {
            font-weight: 700;
          }
          > i.ellipsis.vertical.icon {
            margin-top: 3px;
            float: right;
          }
        }
      }
    }
  }
}

.status-action-table-container {
  overflow: auto;
  max-width: 100%;
  padding-bottom: 10px;
  margin: 0px 10px;
  margin-bottom: 10px;
  position: relative;
  height: calc(100vh - 75px - 120px - 65px - 60px);
}
