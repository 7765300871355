@import "../../css/mixins.scss";
@import url('https://cdn.syncfusion.com/ej2/material.css');

@include breakpoints(240px, 480px) {
  #user-avatar-modal {
    left: -20px !important;
    padding: 5px !important;
    min-width: 210px;
    .horizontal-line {
      margin: 13px 10px 5px !important;
    }
    .user-info-wrapper {
      margin: 10px 12px;
      img {
        height: 35px !important;
        width: 35px !important;
      }
      .user-info {
        .email,
        .name {
          font-size: 13px !important;
          line-height: 15px;
        }
      }
    }
    .user-navigation {
      font-size: 12px;
      margin: 7px 0px;
      margin-right: 12px;
      label {
        line-height: 20px;
        bottom: 0px !important;
      }
    }
  }
}
.ui.selection.dropdown {
  color: rgb(58, 133, 168) !important;
  border: 1px solid rgb(58, 133, 168);
  margin: 0px 5px 0px 5px !important;
}

.ui.selection.visible.dropdown {
  > .text {
    &:not(.default) {
      font-weight: 400;
      color: #3a85a8 !important;
    }
  }
}

.ui.dropdown {
  .menu {
    .selected.item {
      color: #333 !important;
    }
    .active.item {
      color: rgb(58, 133, 168) !important;
      background-color: #d8e7ee;
    }
    > .item {
      color: #333 !important;
    }
  }
}

.ui.dropdown.selected {
  color: rgb(58, 133, 168) !important;
}

.ui.checkbox + label {
  font-style: italic !important;
  color: rgb(58, 133, 168) !important;
  font-size: 14px !important;
  font-weight: unset !important;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.admin-main-container {
  margin-top: 56px !important;
  padding: 42px !important;
  background-color: #f4f4f4 !important;
}
.admin-main-container-prod {
  height: calc(100vh - 75px - 30px);
}
.admin-main-container-test {
  height: calc(100vh - 90px - 30px);
}

.bg-light-gray {
  background: #f4f4f4 !important;
}

.bg-transparent {
  background: transparent !important;
}

#user-info-main {
  .user-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 55px;
      width: 55px;
    }
    .user-info {
      margin-left: 10px;
      text-align: left;
      .name {
        font-size: 16px;
      }
    }
    .user-avatar {
      display: flex;
      flex-direction: column;
    }
  }
}

#user-avatar-modal {
  border-image: linear-gradient(
    to right,
    #3785a8 0%,
    #93cdbb 22%,
    #cdd7a2 28%,
    #f1d574 35%,
    #f99e4d 48%,
    #df4036 67%
  ) !important;
  border-image-slice: 1 !important;
  border-bottom: 4px solid transparent !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  left: -15px !important;
  padding: 25px;
  max-width: 550px;
  .horizontal-line {
    margin: 13px -15px 5px -15px;
    height: 1px;
    background-color: #e6e6e6;
  }
  .user-navigation {
    float: right;
    text-align: right;
    label {
      display: block;
      position: relative;
      bottom: -10px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.button-container {
  position: unset;
  .chat-icon {
    width: 40px !important;
    height: 35px;
    // background-color: #2185d0 !important;
    // box-shadow: 0 0 0 1px #2185d0 inset !important;
    position: absolute;
    border-radius: 50%;
    right: -15px;
    top: -650px;
  }
}

.chat-icon {
  color: #fff !important;
  background-color: #3a85a8 !important;
  box-shadow: 0 0 0 1px #3a85a8 inset !important;
  width: 40px;
  padding: 3px 11px 3px 11px !important;
  margin-right: 8px !important;
  margin-left: auto !important;
  max-height: 35px;
  svg {
    margin-top: 2px;
    margin-left: -1px;
  }
  &.inactive {
    background-color: #f7f7f7 !important;
    box-shadow: 0 0 0 1px #f7f7f7 inset !important;
  }
  &.form {
    min-height: unset !important;
    padding: 11px 10px !important;
    min-width: 50px;
    margin-left: 5px !important;
    max-height: 45px;
    &.inactive {
      box-shadow: 0 0 0 1px rgb(58, 133, 168) inset !important;
    }
  }
}

.add-button {
  padding: 7px 15px !important;
  background-color: #3a85a8 !important;
  margin-left: auto !important;
  height: 35px !important;
  width: 180px !important;
  color: #fff !important;
  font-size: 14px !important;
  &.hasAuditLogAccess {
    margin-left: 0px !important;
  }
}

.table-wrapper {
  max-width: 100%;
  padding: 10px -30px;
  margin: 30px 20px;
  overflow: auto;
  height: calc(100vh - 75px - 120px - 86px - 100px);
}

.table-container {
  table-layout: auto;
  white-space: nowrap;
}

.filter-wrapper {
  min-height: 42px !important;
  display: flex !important;
}

.grid-field-container {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 10px;
}

.react-datepicker-popper {
  z-index: 103;
}

.tree-type-ul {
  background: inherit;
  padding-right: 10px;
  padding-bottom: 10px;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul {
    background: inherit;
    padding-left: 10px;
    li {
      background: inherit;
      border-bottom: 0px;
      padding-left: 10px;
      border-width: 0 0 1px 1px;
      border-left: 1px solid #3a85a8;
      border-bottom: 1px solid #3a85a8;
      &.container {
        border: none;
        color: #3a85a8;
      }
      p {
        margin: 0 0 0 -4px;
        background: inherit;
        position: relative;
        top: 0.5em;
        &:before {
          content: "";
          position: absolute;
          width: 1em;
          height: 1em;
        }
      }
      ul {
        li {
          margin-left: -15px;
        }
      }
    }
  }
}

.geolocation-input-container {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  column-gap: 10px !important;
}
