#automation-form-modal {
  max-width: 900px;
  .close.icon {
    border-radius: 15px;
    color: #fff;
    font-weight: lighter;
    background: #3b85a8;
    right: 5px;
    top: 8px;
  }
  #automation-form-modal-header {
    padding-bottom: 0px;
    width: 350px;
    .automation-name {
      padding: 12px !important;
    }
  }
  > .content {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: -20px;
  }
  .automation-form-container {
    background-color: #ffffff;
    border-radius: 5px;
    margin-left: 0px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 300px 1fr;
    height: calc(100vh - 75px - 120px - 35px);
    min-width: 600px;
  }

  .automation-form-close {
    width: 22px;
    float: right;
    margin-top: -50px;
    margin-right: -10px;
    cursor: pointer;
  }

  .automation-action-container {
    float: right;
    padding-top: 20px;
    display: none;
  }

  .create-user-action {
    .automation-field {
      > label {
        color: #000;
      }
      &.required {
        span {
          label {
            color: #000;
          }
        }
        > label {
          &::after {
            margin: -0.2em 0 0 0.2em;
            content: "*";
            color: #db2828;
          }
        }
      }
    }
  }
}
