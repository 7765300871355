@import '../../../../css/mixins';
.calendar-container {
  .rbc-calendar {
    @include breakpoints(small, 1100px) {
      .custom-toolbar {
        flex-direction: column !important;
        min-height: unset !important;
      }
    }
    .custom-toolbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      font-size: 16px;
      position: sticky;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 10;
      min-height: 45px;
      .cstm-toolbar-label-wrapper {
        flex-grow: 1;
        padding: 0 10px;
        text-align: center;
        font-weight: 600;
        display: flex;
        justify-content: center;
        .cstm-next-btn {
          cursor: pointer;
        }
        .cstm-prev-btn {
          cursor: pointer;
        }
      }
      .cstm-toolbar-label {
        min-width: 200px;
      }
      .cstm-btn-group {
        display: inline-block;
        white-space: nowrap;
        .bg-filter-off {
          background: none !important;
          color: #000 !important;
          border-radius: 0px !important;
          padding: 8px 10px !important;
        }
        .bg-filter-on {
          background: #919191 !important;
          color: #fff !important;
          border-radius: 0px !important;
          padding: 8px 10px !important;
        }
      }
      .filter {
        height: 35px;
        cursor: pointer;
        &.slas {
          border-right: 1px solid #8e8e8e;
        }
        &.status-filter {
          border-radius: unset !important;
          padding-left: 3px;
          .selected {
            border: 1px solid #434343 !important;
          }
        }
        .owned {
          width: 13px;
          height: 18px;
          margin-right: 17px;
        }
        .co-owned {
          width: 18px;
          height: 18px;
          margin-right: 17px;
        }
        .followed {
          width: 18px;
          height: 18px;
          margin-right: 17px;
        }
        .all {
          cursor: pointer;
          font-size: 11px;
          color: #000;
          font-weight: bolder;
          margin-right: 10px;
          margin-left: 7px;
        }
        .fltr-act-inact-wrapper {
          padding: 1px;
          border-radius: 50%;
          margin-left: 11px;
        }
        .fltr-status {
          width: 18px;
          border-radius: 50%;
          &.fltr-sla {
            border-radius: unset !important;
          }
          &.pend {
            margin-left: 9px;
            margin-right: 6px;
          }
          &.comp {
            margin-left: 3px;
            margin-right: 2px;
          }
          &.completed {
            padding: 1px;
            border: 1px solid #63ce3e;
          }
          &.pending {
            padding: 1px;
            border: 1px solid #f3251b;
          }
        }
        .fltr-status-wrapper {
          margin: 0px 6px;
          padding: 1px;
        }
        .fltr-sla {
          height: 14px;
          width: 19px;
        }
      }
    }
  }
}
