.modal {
  border-radius: 10px !important;
  overflow: hidden !important;
}
.btn-action {
  font-size: 12px !important;
  min-height: 35px !important;
  min-width: 150px !important;
  color: #fff !important;
  font-weight: bolder !important;
}
.bg-primary {
  background: #3b85a8 !important;
  color: #fff;
  font-size: 14px !important;
  padding: 0.6em 0.6em;
  font-weight: bolder !important;
}
.bg-error {
  background: #e4453c !important;
  color: #fff;
  font-size: 14px !important;
  padding: 0.6em 0.6em;
  font-weight: bolder !important;
}
.bg-email {
  background: #f3f3f3 !important;
}
.text-center {
  text-align: center !important;
}
.btn-default {
  background: #a1a1a1 !important;
}
.btn-success {
  background: #56a73a !important;
}
.modal-content {
  margin: 15px;
  font-size: 14px;
}
.error-message {
  text-align: center;
  font-style: italic !important;
  color: #e5433c !important;
}
.success-message {
  text-align: center !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-25 {
  padding: 25px !important;
}
.ui.modal {
    >.close { top: 0.5rem;
    right: 0.1rem;
    opacity: unset;
    color: black;
    border-radius: 5px;
    background: #c1c1c1;
    width: 1.8rem;
    height: 1.8rem;
    padding: 0.4425rem 0 0 0;
    font-size: 1em;
  }
}


.borderless {
  border-radius: 6px !important;
}

#automatic-logout-modal-header {
  padding: 20px !important;
}

#automatic-logout-modal-footer {
  padding-right: 30px !important;
}
