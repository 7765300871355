@import '../../../../css/mixins.scss';

@include breakpoints(240px, 1200px) {
  .slaByBp-table-main-container {
    .slaByBp-table-container {
      max-width: 100% !important;
      min-width: unset !important;
    }
  }
}

.slaByBp-table-main-container {
  width: 100%;
  padding-right: 15px;
  .slaByBp-table-container {
    width: 100%;
    height: max-content;
    min-width: 950px;
    max-width: 65%;
    background: linear-gradient(
      180deg,
      rgba(240, 240, 240, 1) 52%,
      rgba(239, 239, 239, 1) 66%,
      rgba(238, 238, 238, 1) 81%
    );
    .slaByBp-table-main {
      padding: 20px 40px 5px;
      height: 100%;
    }
    .slaByBp-table-wrapper {
      overflow: auto hidden;
      padding-bottom: 30px;
      &::-webkit-scrollbar {
        height: 5px;
      }
      .slaByBp-table {
        width: 100%;
        height: 100%;
        table-layout: auto;
        thead {
          tr {
            th {
              &.col-large {
                min-width: 150px;
                background: linear-gradient(
                  180deg,
                  #f0f0f0 52%,
                  #efefef 66%,
                  #eeeeee 81%
                );
                position: sticky;
                top: 0;
                left: 0;
                z-index: 5;
              }
            }
            .summary-header {
              padding: 15px 16px;
              font-size: 15px;
              min-width: 100px;
              white-space: nowrap;
            }
          }
        }
        tbody {
          tr {
            &:last-child {
              &:hover {
                background-color: unset !important;
              }
            }
          }
          td {
            text-align: center;
            padding: 0.8em 0.5em !important;
            &:first-child {
              position: sticky;
              top: 0;
              left: 0;
              z-index: 5;
              &.legend {
                background: linear-gradient(
                  180deg,
                  #f0f0f0 52%,
                  #efefef 66%,
                  #eeeeee 81%
                );
                .container {
                  display: flex;
                  align-items: center;
                  div {
                    width: 15px;
                    height: 25px;
                    margin-right: 20px !important;
                  }
                  span {
                    font-size: 15px;
                  }
                }
              }
            }
          }
          .horizontal-rule {
            padding: unset !important;
          }
          .bold {
            * {
              font-weight: 700;
            }
          }
        }
      }
    }
    .sla-table-header {
      height: 50px;
      width: 100%;
      display: flex;
      flex-direction: row;
      &.mobile {
        .sla-action-container {
          .sla-title {
            padding: 5px;
            margin-left: 0px;
            .logo {
              width: 25px;
              height: 25px;
              margin-left: 5px;
            }
            .seq {
              margin-left: 10px;
              margin-right: -5px;
            }
            span {
              font-size: 12px;
            }
          }
          .sla-filter-1 {
            div {
              margin: 0px 5px;
            }
            .group,
            .favorite {
              width: 14px;
              height: 14px;
              margin-right: 0px;
            }
            .personal {
              width: 10px;
              height: 14px;
              margin-right: 03px;
            }
            .all {
              font-size: 10px;
              margin-right: 0px;
            }
          }
        }
      }
      div {
        .sla-title {
          display: flex;
          padding: 10px;
          align-items: center;
          color: #fff;
          margin-left: 15px;
          .logo {
            width: 35px;
            height: 35px;
          }
          span {
            font-size: 18px;
            margin-left: 15px;
          }
        }
        .sla-filter-1 {
          display: flex;
          align-items: center;
          margin-left: auto;
          padding: 5px 10px;
          div {
            margin: 0px 5px;
          }
          .group,
          .favorite {
            width: 17px;
            height: 17px;
            margin-right: 10px;
            cursor: pointer;
          }
          .personal {
            width: 13px;
            height: 17px;
            margin-right: 10px;
            cursor: pointer;
          }
          .all {
            font-size: 10.4pt;
            color: #fff;
            font-weight: bolder;
            margin-right: 15px;
          }
        }
        .sla-filter-2 {
          display: flex;
          padding: 15px 20px;
          label {
            margin: 0px 10px;
          }
          div {
            width: 18px;
            border-radius: 50%;
            margin: 0px 10px;
          }
        }
      }
      .sla-action-container {
        display: flex;
      }
    }
    .slaByBp-table-mobile {
      padding: 10px 25px;
      .content-wrapper {
        .content {
          margin: 15px 0px;
          &.footer {
            * {
              font-weight: 700;
            }
            .data {
              margin-left: 0px;
              display: flex;
              align-items: center;
              margin-right: 20px;
              font-size: 11px;
              line-height: 17px;
              .name {
                margin-right: auto;
              }
              .count {
                margin-left: auto;
              }
            }
          }
          .legend {
            display: flex;
            margin-bottom: 2px;
            font-size: 12px;
            .color {
              width: 15px;
              height: 15px;
              align-self: center;
              margin-right: 10px;
            }
          }
          .data {
            display: flex;
            align-items: center;
            margin-left: 26px;
            margin-right: 20px;
            font-size: 11px;
            line-height: 17px;
            .name {
              margin-right: auto;
            }
            .count {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
