@import "./theme-variables";
@import "./mixins";

#dropdown-menu {
  .menu {
    background: #fff !important;
    .item {
      &:hover {
        background: #fff !important;
      }
      &.menu-dropdown {
        padding: 5px 0px;
        .item {
          color: #000;
          padding: 0.8em;
          width: 100%;
          &:hover {
            opacity: 0.55;
          }
        }
      }
    }
  }
}

@include breakpoints(480px, 1100px) {
  #menu-gradient {
    .menu-item-right {
      #notification-button {
        .count-wrapper {
          font-size: 10px;
          top: 1em;
        }
        svg {
          width: 27px;
          height: 27px;
        }
      }
      .item {
        padding: 8px !important;
        &.admin {
          svg {
            width: 27px;
            height: 27px;
          }
        }
        .avatar-wrapper {
          svg {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
}

@include breakpoints(240px, 480px) {
  #dropdown-menu {
    .menu {
      .item {
        &.menu-dropdown {
          font-size: 11px;
          padding: 3px 0px;
          .item {
            padding: 0.1em;
            width: 100%;
          }
        }
      }
    }
  }
  #menu-gradient {
    .menu-item-left {
      &.brand {
        min-width: 40px !important;
        .item {
          padding: unset !important;
          margin: 0px 5px;
          .menu-logo {
            width: 40px !important;
          }
        }
      }
    }
    .document-menu {
      -webkit-tap-highlight-color: transparent;
      .menu-toggle-container {
        margin-left: 8px;
        .menu-toggle {
          padding: 7px 8px !important;
          font-size: 11px;
          .content {
            margin-right: 15px;
          }
          i {
            right: 1px;
          }
        }
      }
      .new-link {
        &.circular {
          padding: 5px 8px !important;
          .plus {
            &::after {
              font-size: 16px;
              top: 0px;
            }
          }
        }
      }
    }
    .menu-item-right {
      #notification-button {
        .count-wrapper {
          font-size: 8px;
          top: 1.5em;
        }
        svg {
          width: 22px;
          height: 23px;
        }
      }
      .item {
        padding: 0px 8px !important;
        &:first-child {
          margin-right: 0px !important;
        }
        &:last-child {
          padding-right: 10px !important;
        }
        &.admin {
          svg {
            width: 20px;
            height: 20px;
          }
        }
        .avatar-wrapper {
          padding: 5px 7px;
          &.img {
            padding: 5px 0px;
            img {
              width: 27px;
              height: 27px;
            }
          }
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

#menu-gradient {
  border-image: linear-gradient(to right, #66b283 0%, #e34b22 50%, #dda749 100%) !important;
  border-image-slice: 1 !important;
  border-bottom: 2px solid transparent !important;
  z-index: 5;
  // border-bottom-style: solid !important;
  // border-bottom-width: 2px !important;  // style for custom theme color in admin
  .menu-item-left {
    .item {
      padding: 7px 5px !important;
    }
    &.brand {
      @include breakpoints(240px, menu-large) {
        min-width: 90px;
      }
    }
    .menu-logo {
      width: 100px !important;
      height: 100%;
    }
  }

  .menu-item-right {
    span.item {
      font-weight: 600 !important;
      color: #000 !important;
    }
    .item {
      padding: 0px 8px;
      &:last-child {
        padding-right: 1.2em;
      }
      &:first-child {
        margin-right: 2px;
      }
    }
  }
  .document-menu {
    &.menu-dropdown {
      .item {
        padding-left: unset !important;
        padding-right: unset !important;
        margin-right: 10px;
      }
    }
    .menu-group {
      &.item {
        border-bottom-width: 0.3em;
        border-bottom-style: solid;
        border-bottom-color: transparent;
      }
    }
    .item {
      padding-left: 0.8em;
      padding-right: 0.8em;
      &:first-child {
        padding-left: 1em;
      }
      a.item {
        font-weight: 600 !important;
        padding-left: 0;
        padding-right: 0;
        top: 2px;
      }
      &.menu-toggle {
        display: none;
      }
    }
    .btn {
      padding: 0.3em 5em;
    }
  }
  .dropdown {
    .menu {
      &.visible {
        left: 0px !important;
        width: max-content !important;
      }
    }
  }
  .menu-toggle {
    padding: 7px 10px !important;
    font-weight: 600 !important;
    i {
      background: transparent;
      right: 5px;
    }
    .content {
      margin-right: 20px;
    }
  }
  .new-link {
    padding: 6px 12px;
    border-radius: 0.2em;
    position: relative;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600 !important;
    top: -1px;
    &.circular {
      border-radius: 50%;
      padding: 5px 8px;
      top: 0px;
      color: #fff;
      .plus {
        &::after {
          content: "\002B";
          font-size: 23px;
          position: relative;
          top: 1px;
        }
      }
      .icon {
        font-weight: 100 !important;
      }
    }
  }
  .avatar-wrapper {
    cursor: pointer;
    &.icon {
      border-radius: 50%;
      padding: 6px 7px;
      background: #e2e2e2;
    }
  }
}
