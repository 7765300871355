.automation-form-container {
  .automation-form-detail {
    .automation-form-detail-new-trigger {
      height: 100%;
      padding: 0px 40px;
      overflow: auto;
    }
    .automation-form-detail-trigger {
      height: calc(100vh - 250px);
      padding-bottom: 30px;
      padding: 0px 40px;
      overflow: auto;

      form {
        .field {
          display: grid;
          grid-template-columns: 140px 1fr;
          .input {
            margin-top: 15px;
            height: 30px;
            input {
              height: 30px;
              vertical-align: middle;
            }
          }
        }
      }

      .fields {
        margin-top: 20px;
        label {
          vertical-align: top;
          padding: 20px;
        }
        input {
          background-color: #f3f3f3 !important;
          border: none !important;
          width: auto;
          width: 300px;
        }
        .error {
          input {
            background-color: #ff8585 !important;
          }
        }
        textarea {
          background-color: #f3f3f3 !important;
          border: none !important;
          resize: none !important;
          width: auto;
          width: 325px;
          height: 80px;
          border-radius: 5px;
        }
      }
    }

    .sla-update-container {
      margin-top: 20px;

      .automation-field {
        .ui.fluid.search.selection.dropdown {
          margin-top: 10px !important;
        }
        label {
          font-weight: 700;
          color: #757575;
        }
      }

      .ui.radio.checkbox {
        margin-top: 20px;
        input:checked ~ label:after {
          color: #83b1c5;
          border-color: #83b1c5;
          background-color: #83b1c5;
        }

        label {
          font-weight: 700;
          color: #757575;
        }
      }

      input ~ label:before {
        color: #83b1c5;
        border-color: #83b1c5;
      }
      input:checked ~ label:before {
        color: #83b1c5;
        border-color: #83b1c5;
      }
      input:checked ~ label:after {
        color: #83b1c5;
        border-color: #83b1c5;
      }

      hr {
        margin-left: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #e4e5e5;
        padding: 0;
      }

      .status-container {
        display: grid;
        grid-template-columns: 1fr 30px;

        margin-top: 10px;
        .checkbox {
          label {
            text-transform: capitalize;
            color: #757575;
          }
        }
      }
    }
    .scheduler-container {
      padding-top: 10px;
      .field-layout-tab-container {
        padding-top: unset;
        margin: 0px 0px 15px;
      }
      .divider {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #c0bdbd;
        line-height: 0.1em;
        margin: 25px 0 30px;
        span {
          background: #fff;
          padding: 0 10px;
          color: #757575;
        }
      }
      .automation-info {
        display: grid;
        background: #eff8fc;
        border-radius: 4px;
        padding: 12px 15px;
        grid-template-columns: 30px 1fr;
        p {
          font-size: 13px;
        }
      }
      .automation-field {
        margin-top: 10px;
        width: 100%;
        .field {
          margin-bottom: 12px;
        }
        .input-rule-cron-exp {
          .field-header {
            font-size: 12px;
            font-style: inherit;
            line-height: 1.33333;
            color: #757575;
            font-weight: 500;
            display: inline-block;
            text-transform: uppercase;
          }
          .input-cron-exp {
            margin-top: 8px;
            input {
              border: unset !important;
              border-radius: 2px;
              background: #f3f3f3 !important;
              max-height: 38px;
              &:focus {
                outline: unset;
              }
              padding: 10px;
            }
          }
        }
        .input-rule-range {
          display: grid;
          grid-template-columns: calc(50% - 5px) calc(50% - 5px);
          grid-column-gap: 10px;
          .to-picker {
            display: flex;
            align-items: flex-end;
          }
          .from-picker {
            position: relative;
            &::after {
              font-family: "e-icons" !important;
              content: "\e934";
              position: absolute;
              bottom: 15px;
              font-size: 10px;
            }
          }
          .e-scheduled-range-picker {
            border: unset !important;
            border-radius: 2px;
            background: #f3f3f3 !important;
            max-height: 38px;
            min-height: 38px;
            .e-input-group-icon {
              color: #3a85a8;
              min-width: 20px;
              &.e-active {
                opacity: 0.7;
              }
              &.e-input-btn-ripple {
                animation-name: unset !important;
              }
            }
            .e-date-icon {
              margin: unset;
              &::before {
                font-size: 14px;
              }
            }
            .e-time-icon {
              margin: 0 5px 0 1px;
              &::before {
                font-size: 14px;
              }
            }
            .e-clear-icon {
              color: #3b85a8;
              padding-top: 5px;
              &::before {
                font-size: 11px;
              }
            }
            .e-input {
              padding: 5px 12px;
            }
            &::before,
            &::after {
              background: transparent !important;
            }
          }
          .field-header {
            font-size: 12px;
            font-style: inherit;
            line-height: 1.33333;
            color: #757575;
            font-weight: 500;
            display: inline-block;
            text-transform: uppercase;
            margin-bottom: 5px;
          }
        }
        .input-rule-zone {
          .e-scheduled-zone-picker {
            border: unset !important;
            border-radius: 2px;
            background: #f3f3f3 !important;
            &.e-ddl {
              padding: 5px 12px;
            }
            &::before,
            &::after {
              background: transparent !important;
            }
            .e-ddl-icon {
              margin-top: 6px;
              color: #3b85a8;
              &::before {
                content: "\e94d" !important;
              }
            }
            .e-clear-icon {
              top: 90%;
              right: 30px;
              color: #3b85a8;
            }
          }
          .field-header {
            font-size: 12px;
            font-style: inherit;
            line-height: 1.33333;
            color: #757575;
            font-weight: 500;
            display: inline-block;
            text-transform: uppercase;
            margin-bottom: 5px;
          }
        }
        .rule-run-info {
          display: flex;
          align-items: center;
          padding: 12px 10px;
          background: #eff8fc;
          border-radius: 4px;
          font-size: 12px;
          margin-bottom: 0;
          &.next-runs {
            padding: 5px 0px;
            .content {
              margin-left: 35px;
              ul {
                li {
                  line-height: 18px;
                }
              }
            }
          }
          .run-info {
            margin-left: 10px;
            width: 100%;
            line-height: 18px;
            .title {
              margin-right: 5px;
              font-weight: 500;
            }
            .button {
              margin-left: auto;
              color: #3a85a8;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#scheduled-timezonepicker_popup {
  filter: unset;
  &.selected {
    background: #f3f3f3;
    font-weight: 500;
  }
  .e-list-parent {
    .e-list-item {
      .timezone-offset {
        position: absolute;
        background: #5e9f2a;
        border-radius: 2px;
        padding: 3px 7px;
        font-size: 11px;
        color: #fff;
        top: 8px;
        width: 50px;
        height: 20px;
        right: 10px;
        span {
          position: relative;
          left: -14px;
          top: -10px;
        }
      }
      &.e-active {
        color: #3b85a8;
        border: unset;
      }
    }
  }
}
#scheduled-daterangepicker_from_timepopup,
#scheduled-daterangepicker_to_timepopup {
  filter: unset;
}

#scheduled-daterangepicker_to_datepopup,
#scheduled-daterangepicker_from_datepopup,
#scheduled-daterangepicker_popup {
  filter: unset;
  .e-cell {
    &.e-today {
      &.e-selected {
        .e-day {
          color: #fff !important;
        }
      }
      .e-day {
        border: 1px solid #3a85a8 !important;
        border-radius: 50%;
        color: #3a85a8 !important;
      }
    }
  }
  .e-selected {
    .e-day {
      background-color: #3a85a8 !important;
    }
  }
  .e-today,
  .e-apply {
    color: #3a85a8 !important;
    &:hover {
      background-color: #3a85a813 !important;
    }
  }
}
