@import "../../../../css/mixins.scss";

.tox:not([dir="rtl"]) {
  .tox-collection--list {
    .tox-collection__item {
      > :not(:first-child) {
        margin-left: 20px !important;
      }
    }
  }
}

.tox {
  filter: unset !important;
}

.tox-autocompleter {
  border: 1px solid #ccc !important;
  border-radius: 3px;
  background-color: #fff !important;
  overflow: unset !important;
  &::-webkit-scrollbar {
    width: 8px !important;
  }
  .tox-menu {
    border: unset !important;
    border-radius: unset !important;
  }
  .tox-collection__group {
    overflow: hidden auto;
    max-height: 404px;
    #default-avatar {
      padding: 5px;
      border-radius: 50%;
      min-height: 22px;
      min-width: 22px;
      text-align: center;
      font-size: 15px;
      color: #fff;
      font-weight: 500;
    }
    #avatar {
      min-width: 2em;
      max-width: 2em;
      max-height: 2em;
      min-height: 2em;
      display: inline-block;
      width: 2em;
      height: 2em;
      border-radius: 500rem;
    }
    .tox-collection__item {
      padding: 10px !important;
    }
  }
}

@include breakpoints(240px, 480px) {
  #attachments {
    .attachment-main-container {
      .attachment-list-container {
        overflow: auto hidden !important;
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
      .arrow-left,
      .arrow-right {
        width: 33px !important;
        height: 27px !important;
      }
    }
  }
}
#attachments {
  width: 100%;
  background: #fff;
  max-height: 65px;
  position: absolute;
  bottom: 40px;
  .attachment-main-container {
    background: #fff;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    * {
      cursor: pointer;
    }
    .arrow-right {
      background: #f2f2f4;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      border-radius: 50%;
      .right {
        margin-left: 2px;
        width: 18px;
        transform: rotate(90deg);
      }
      &.not-shown {
        background: transparent;
        .right {
          display: none;
        }
      }
    }
    .arrow-left {
      background: #f2f2f4;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      border-radius: 50%;
      .left {
        margin-right: 2px;
        width: 18px;
        transform: rotate(-90deg);
      }
      &.not-shown {
        background: transparent;
        .left {
          display: none;
        }
      }
    }
    .attachment-list-container {
      background-color: #fff;
      padding: 10px 0px;
      width: 420px;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      white-space: nowrap;
      max-width: 420px;
      .remove {
        width: 17px;
        float: right;
        margin-top: -8px;
        margin-right: -13px;
        background: #fff;
        z-index: 10;
      }
      .image-only {
        margin: 0px 6px;
        height: 40px;
        min-width: 70px;
        max-width: 70px;
        .uploaded-image {
          top: -10px;
          width: 100%;
          height: 100%;
        }
        .remove {
          margin-right: -7px !important;
          border-radius: 50% !important;
        }
      }
      .attachment {
        border: 1px solid #cdcdcd;
        height: 40px;
        min-width: 120px;
        max-width: 120px;
        border-radius: 5px;
        padding: 0px 6px;
        margin: 0px 6px;
        .attachment-info {
          margin-top: 7px;
          max-height: 40px;
          min-height: 28px;
          display: grid;
          grid-template-columns: 26px auto;
        }
        .file-icon {
          margin-right: 5px;
          max-width: 22px;
          img {
            width: 30px;
            height: 25px;
          }
          i {
            font-size: 21px;
            color: #9a9a9a;
          }
        }
        .file-name {
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
          color: #9a9a9a;
          white-space: nowrap;
          font-size: 13px;
          margin-top: 5px;
        }
      }
    }
  }
}

#chat-file-upload-modal-dimmer {
  display: flex !important;
  background-color: rgba(26, 26, 26, 0.88) !important;
  z-index: 2000 !important;
}

#file-upload-modal {
  &.mobile {
    width: 95% !important;
    .header {
      font-size: 14px;
    }
    .content {
      margin: 20px 20px 5px;
      .dropzone-container {
        .file-container {
          overflow: hidden auto;
          grid-template-columns: 1fr !important;
          max-height: 300px;
          margin: unset !important;
          padding-right: 5px;
          &::-webkit-scrollbar {
            width: 3px;
          }
          pre {
            white-space: normal;
          }
        }
        .file-icon-upload {
          width: 50px !important;
          height: 50px !important;
          margin-top: 0px !important;
          svg {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    .actions {
      margin: 0px 15px 18px;
      div {
        font-size: 14px;
      }
    }
  }
  .btn-upload {
    &.chat {
      margin-left: auto !important;
      cursor: not-allowed !important;
      filter: grayscale(0.7) !important;
    }
    &.uploaded {
      cursor: pointer !important;
      filter: none !important;
    }
  }
  .content {
    height: auto !important;
    .dropzone-main-container {
      margin: 15px 0px;
      margin-top: 15px;
      .dropzone-container {
        background: unset;
        .react-sweet-progress {
          min-width: 200px !important;
        }
        .file-container {
          display: grid;
          grid-template-columns: 1fr;
          margin: 0px 20px;
          margin-top: 10px;
          grid-column-gap: 20px;
          &.two {
            grid-template-columns: 1fr 1fr;
          }
        }
        .file-upload-label {
          margin-top: 5px !important;
        }
        .file-selected {
          margin-bottom: 5px !important;
          .file-name {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

.chatbox-container {
  .chatbox-dimmer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
  }
  .chatbox-icon {
    background-color: #000000;
    border-radius: 30px;
    width: 52px;
    height: 52px;
    cursor: pointer;
    bottom: 20px;
    right: 30px;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    margin: 10px 0px 10px 0px;
    padding-left: 2px;
    &.no-action {
      margin-left: auto;
    }
    &:hover {
      transform: scale(1.15);
    }
    img {
      width: 35px;
      height: 35px;
      margin-top: 3px;
    }
    .icon-wrap {
      position: relative;
    }
  }
  .chat-area {
    background: #fff;
    width: 500px;
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    z-index: 101;
    box-shadow: 0px 2px 6px #00000096;
    @include breakpoints(240px, 480px) {
      width: 100%;
      .chat-filter {
        height: 40px !important;
        .filter-by-type {
          img {
            width: 28px !important;
            height: 28px !important;
          }
        }
        .filter-by-user {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          .user-name-icon {
            margin-top: 0px !important;
            span {
              padding: 5px !important;
              font-size: 12px;
            }
            &.selected {
              padding: 4px 0px !important;
            }
          }
          .user-icon {
            margin-left: -3px !important;
            img {
              width: 29px !important;
              height: 29px !important;
            }
            &.selected {
              margin-top: 0px !important;
            }
          }
        }
      }
      .chat-content {
        &::-webkit-scrollbar {
          width: 5px;
        }
        .chat-message-widget {
          grid-column-gap: 5px;
          padding-left: 15px !important;
          .message-content-container {
            min-height: 45px !important;
            .user-name {
              font-size: 14px !important;
            }
            .message-date {
              font-size: 11px !important;
            }
            .message-content {
              max-width: 315px !important;
              font-size: 12px;
              .field-preview {
                font-size: 12px;
                .preview.before,
                .preview.after {
                  font-size: 12px !important;
                }
                .arrow-right {
                  font-size: 21px !important;
                }
                .file-container {
                  .file-icon {
                    max-width: 30px !important;
                    max-height: 25px !important;
                  }
                  .file-info {
                    .file-name {
                      font-size: 12px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .input-box {
        padding: 5px 0px !important;
        .tox.tox-tinymce {
          border-radius: unset !important;
        }
      }
    }
    .chat-filter {
      height: 45px;
      background-color: #eef0ef;
      padding: 8px;
      display: flex;
      align-items: center;
      img.close {
        width: 15px;
        margin-right: 10px;
        margin-left: 7px;
      }
      .filter-by-type {
        img {
          width: 30px;
          height: 30px;
          padding: 6px;
          border: 1px solid #dbdbdb;
          border-radius: 14px;
          background-color: #ffffff;
          display: inline-block;
          margin-right: 5px;
          cursor: pointer;
        }
        img.selected {
          background-color: #3c3c3e;
        }
        img.disabled {
          cursor: no-drop;
        }
      }
      .filter-by-user {
        padding-right: 5px;
        margin-left: auto;
        > div:nth-child(1) {
          z-index: 7;
          position: relative;
        }
        > div:nth-child(2) {
          z-index: 2;
          position: relative;
        }
        > div:nth-child(3) {
          z-index: 3;
          position: relative;
        }
        > div:nth-child(4) {
          z-index: 4;
          position: relative;
        }
        > div:nth-child(5) {
          z-index: 5;
          position: relative;
        }
        > div:nth-child(6) {
          z-index: 6;
          position: relative;
        }
        .user-icon {
          border-radius: 50%;
          border: none;
          float: right;
          margin-left: -5px;
          cursor: pointer;
          background-color: #ffffff;
          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 2px solid #ffffff;
            background-color: #ffffff;
            position: initial;
          }
          &:hover {
            top: -4px;
            z-index: 8;
            position: relative;
          }
          &.selected {
            border: 2px solid #357cbe;
            border-radius: 50%;
            margin-top: -2px;
          }
        }
        .more-user {
          border-radius: 50%;
          background-color: #dee1e5;
          color: #798799;
          width: 30px;
          height: 30px;
          padding: 4px;
          cursor: pointer;
          float: right;
          margin-left: -5px;
          border: 2px solid transparent;
          &:hover {
            border: 2px solid #357cbe;
          }
          &.selected {
            border: 2px solid #357cbe;
          }
          &:focus {
            outline: none;
          }
        }
        .user-name-icon {
          z-index: 8;
          cursor: pointer;
          float: right;
          text-align: center;
          color: #ffffff;
          background-color: #ffffff;
          border-radius: 50%;
          margin-left: -5px;
          span {
            background-color: #357cbe;
            padding: 6px;
            border-radius: 50%;
            border: 2px solid #ffffff;
            display: flex;
            width: 33px;
            height: 33px;
            align-items: center;
            justify-content: center;
          }
          &.selected {
            border: 2px solid #357cbe;
            border-radius: 50%;
            margin-top: -2px;
            span {
              padding: 5px;
            }
          }
          &:hover {
            margin-top: -3px;
            z-index: 8;
            position: relative;
          }
        }
      }
      .clear-filter {
        padding: 3px 8px;
        width: 50px;
        height: 26px;
        border: 1px solid #dddddd;
        border-radius: 15px;
        background-color: #ffffff;
        color: #8f8f8f;
        cursor: pointer;
        font-size: 10px;
        margin-top: 2px;
        label {
          cursor: inherit;
        }
        &:hover {
          background-color: #e2dfdf;
        }
        &.disabled {
          cursor: default;
          background-color: transparent;
          border: none;
          &:hover {
            background-color: inherit;
          }
        }
      }
    }
    .close-chat-area {
      width: 15px;
      height: 15px;
      position: absolute;
      margin: 10px;
      cursor: pointer;
      right: 10px;
    }
    .chat-content {
      height: calc(100vh - 75px - 50px - 170px);
      overflow: auto;
      padding-top: 5px;
      .chat-unread-nagivator {
        display: flex;
        position: absolute;
        margin: 0px auto;
        z-index: 20;
        background: #dee0e2;
        align-items: center;
        justify-content: space-between;
        width: max-content;
        height: 28px;
        cursor: pointer;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .label {
          color: #4e4e4e;
          font-size: 12px;
          padding: 4px 10px;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          text-align: center;
          flex-basis: 80%;
          font-weight: 500;
          width: max-content;
          display: flex;
          white-space: nowrap;
          &:hover {
            background-color: #caccce;
          }
        }
        .close {
          padding: 10px 0px;
          width: 35px;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          padding: 4px 10px;
          &:hover {
            background-color: #caccce;
          }
          &::before {
            content: "\00d7";
            font-size: 18px;
            color: #4e4e4e;
          }
        }
      }
      .chat-unread-mark {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 3px;
        align-items: center;
        position: absolute;
        left: 2px;
        top: -10px;
        width: 98%;
        font-size: 12px;
        color: #de4e2b;

        &::before {
          content: "";
          display: block;
          border-top: 1px solid currentColor;
        }
      }
      .chat-message-widget {
        display: grid;
        grid-template-columns: 40px 1fr;
        padding: 10px;
        position: relative;
        &:hover {
          background-color: #e1e1e1;
        }
        .comment-action-popup {
          position: absolute;
          right: 10px;
          margin-top: 10px;
          cursor: pointer;
        }
        .user-icon {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }
        .bot-icon {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          background-color: #000000;
          padding: 1px 2px 3px;
        }
        .message-attachment-icon {
          width: 20px;
          height: 20px;
          display: inline-block;
        }
        .message-attach-filename {
          font-weight: 700;
        }
        .message-content-container {
          min-height: 70px;
          .message-content {
            white-space: nowrap;
            overflow: hidden;
            word-break: break-word;
            max-width: 410px;
            p {
              overflow: hidden;
              word-wrap: normal;
              white-space: normal;
              margin-bottom: 0;
            }
            .icon-dropdown {
              margin-top: 10px;
              cursor: pointer;
              * {
                cursor: pointer;
              }
              .fields-preview-name {
                font-weight: 500 !important;
                margin-right: 10px;
                color: #747273;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .icon-expand {
                color: #525252;
                font-size: 13px !important;
              }
            }
            .field-preview {
              height: max-content;
              display: grid;
              grid-template-columns: 1fr;
              grid-row-gap: 10px;
              margin-top: 10px;
              border: 1px solid #cccccc;
              border-radius: 7px;
              padding: 10px;
            }
            .edited-field-container {
              .edited-field-info {
                display: flex;
                i {
                  cursor: pointer;
                  margin-top: 1px;
                  color: #525252;
                }
              }
              .edited-field-preview {
                max-width: 410px;
                grid-column-gap: 5px;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                &.inline,
                &.single {
                  margin-top: 0px;
                }
                * {
                  color: #747273;
                  font-style: italic;
                  font-size: 14px;
                }
                .arrow-right {
                  text-align: left;
                  font-size: 25px;
                  width: 30px;
                  margin: 0px 5px;
                  margin-top: -4px;
                }
                .preview {
                  white-space: nowrap;
                  overflow: hidden;
                  word-break: break-word;
                  word-wrap: normal;
                  white-space: normal;
                  width: fit-content;
                  .file-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .file-icon {
                      max-width: 35px;
                      max-height: 30px;
                      margin-right: 10px;
                    }
                    .file-info {
                      display: grid;
                      grid-template-columns: 1fr;
                      height: max-content;
                      p {
                        cursor: pointer;
                        color: rgb(65, 131, 196);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 0px;
                      }
                      .file-size {
                        font-size: 11px;
                        color: gray;
                        font-style: italic;
                      }
                    }
                  }
                }
              }
            }
          }
          .user-name {
            font-weight: 700;
            font-size: 16px;
          }
          .message-date {
            font-size: 12px;
            color: #777676;
            margin-left: 10px;
          }
        }
      }
      .user-name-avatar {
        height: max-content;
      }
      .user-name-icon {
        span {
          background-color: #357cbe;
          padding: 6px;
          border-radius: 50%;
          border: 2px solid #ffffff;
          color: #ffffff;
          display: flex;
          width: 33px;
          height: 33px;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .input-box {
      height: max-content;
      display: block;
      padding: 10px;
      background-color: #eef0ef;
      position: relative;
      .img-cancel {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 20;
        cursor: pointer;
      }
      .tox-toolbar-overlord {
        position: absolute;
        bottom: -1px;
        border-top: 1px solid #cfcfcf;
        border-bottom: none;
        width: 100%;
      }
      .tox-toolbar__group {
        button {
          &:nth-child(9) {
            position: absolute;
            right: 10px;
            bottom: 1px;
          }
          &:nth-child(10) {
            position: absolute;
            right: 45px;
            bottom: 1px;
          }
          &:nth-child(11) {
            position: absolute;
            right: 80px;
            bottom: 1px;
          }
        }
        @include breakpoints(240px, 480px) {
          button {
            &:nth-child(7) {
              position: absolute;
              right: 15px;
              bottom: 1px;
            }
            &:nth-child(6) {
              position: absolute;
              right: 48px;
              bottom: 1px;
            }
            &:nth-child(5) {
              position: absolute;
              right: 78px;
              bottom: 1px;
            }
          }
        }
      }
      .tox.tox-tinymce {
        border: none;
        border-radius: 10px;
      }
      .tox-toolbar {
        border: none;
      }
      .tox {
        .tox-tbtn {
          svg {
            fill: #b2b2b2 !important;
            cursor: pointer;
          }
        }
      }
      .tox-edit-area {
        height: 170px;
      }
      @include breakpoints(240px, 480px) {
        .tox-edit-area {
          height: 100px;
        }
      }
    }
  }
  .chat-area.close {
    right: -500px;
    -webkit-transition-property: width height background-color font-size left top color;
    -webkit-transition-duration: 0.9s;
    -webkit-transition-timing-function: ease;
    transition-property: width height background-color font-size left top color;
    transition-duration: 0.9s;
    transition-timing-function: ease;
  }
  .chat-area.open {
    right: 0px;
    -webkit-transition-property: width height background-color font-size left top color;
    -webkit-transition-duration: 0.9s;
    -webkit-transition-timing-function: ease;
    transition-property: width height background-color font-size left top color;
    transition-duration: 0.9s;
    transition-timing-function: ease;
  }
  .attachment-container {
    height: 40px;
    * {
      cursor: pointer;
    }
    &.withPreview {
      height: max-content !important;
    }
    .multiple {
      display: grid;
      grid-template-columns: 1fr;
      height: max-content;
      margin-right: 10px;
      .attachment-preview {
        margin-left: 0px !important;
        .attachment-main {
          &.image {
            padding: 10px;
            .attachment-remove {
              margin-top: -15px;
            }
          }
        }
      }
    }
    .attachment-preview {
      margin-top: 12px;
      .attachment-main {
        width: 260px;
        height: max-content;
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 7px;
        position: relative;
        &.deleted,
        &.document {
          padding: 6px 10px;
        }
        &:hover {
          .attachment-remove {
            opacity: 1;
          }
        }
        .attachment-remove {
          width: 15px;
          position: absolute;
          right: -7px;
          margin-top: -18px;
          z-index: 10;
          opacity: 0;
        }
        a {
          width: 100%;
        }
        &.image {
          height: max-content;
          padding: unset;
          .attachment-remove {
            margin-top: -7px;
          }
        }
        .attachment-header {
          height: 35px;
          display: flex;
          align-items: center;
          width: 100%;
          margin-left: 0px !important;
          font-size: 12px !important;
          height: 28px !important;
          &.deleted {
            .icon-deleted {
              background: #dddddd;
              padding: 6px;
              border-radius: 3px;
              .file-icon {
                width: 13px;
                height: 13px;
              }
            }
            .file-info {
              color: #7a797a;
              font-size: 12px;
            }
          }
          &.no-preview {
            .icon-no-preview {
              background: #dddddd;
              padding: 6px;
              border-radius: 3px;
              .file-icon {
                width: 13px;
                height: 13px;
              }
            }
            .file-info {
              color: #4183c4;
              font-size: 12px;
              text-decoration: underline;
            }
          }
          .size {
            font-size: 10px !important;
          }
          .file-icon {
            width: 25px;
            height: 28px;
            &.supported {
              width: 50px;
              height: 35px;
            }
          }
          .file-info {
            * {
              color: #2f2f2f;
            }
            display: grid;
            margin-left: 10px;
            .info {
              display: none;
            }
            .name {
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 13px;
            }
          }
        }
        .attachment-preview-container {
          height: 150px;
          border-radius: 7px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .attachment {
      margin-top: 10px;
      width: max-content;
      border-radius: 20px;
      max-width: 350px;
      display: flex;
      i {
        font-size: 18px;
        color: #8f8f8f;
      }
      .message-attach-icon {
        width: 17px;
        margin-right: 10px;
      }
      .message-attach-filename {
        font-weight: 500 !important;
        margin-right: 10px;
        color: #747273;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon-expand {
        color: #525252;
        font-size: 13px !important;
      }
    }
  }
}

.more-user-container {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  box-sizing: border-box;
  overflow: auto;
  padding: 1px 0px;
  max-height: 300px;
  width: 250px;
  z-index: 10;
  position: absolute;
  margin-top: 36px;
  margin-left: -43px;
  ul {
    padding-left: 0px;
    li {
      display: grid;
      grid-template-columns: 30px 50px 1fr;
      -webkit-box-align: center;
      align-items: center;
      box-sizing: border-box;
      color: rgb(23, 43, 77);
      cursor: pointer;
      white-space: nowrap;
      font-size: 14px;
      font-weight: normal;
      padding: 0px 12px;
      text-decoration: none;
      height: 35px;
      &:hover {
        background-color: #f3f3f3;
      }
      div {
        &:nth-child(1) {
          text-align: center;
        }
        &:nth-child(2) {
          text-align: center;
          > span {
            background-color: #357cbe;
            padding: 6px;
            border-radius: 50%;
            border: 2px solid #ffffff;
            color: #ffffff;
          }
          &.with-icon {
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 2px solid #ffffff;
              background-color: #ffffff;
              position: initial;
              margin-left: 9px;
            }
          }
        }
        &:nth-child(3) {
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 150px;
          overflow: hidden;
        }
      }
    }
  }
}

#comment-edit-modal {
  border-radius: unset !important;
  max-width: 85%;
  position: fixed;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px !important;
    .header-label {
      font-size: 14px;
    }
    .close {
      width: 13px;
      height: 13px;
    }
  }
  .content {
    p {
      font-size: 12px;
      font-style: italic;
    }
  }
  .actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: unset !important;
    border-radius: unset !important;
    border-top: 1px solid #cccccc !important;
    .input-box {
      padding: unset !important;
    }
  }
}
