.app-table-container {
  height: calc(100vh - 75px - 86px - 65px - 125px);
  margin: 0px 20px;
  overflow: unset;
  .e-pager {
    .e-numericcontainer {
      .e-numericitem.e-active {
        background-color: #3a85a8 !important;
        color: #ffffff !important;
      }
    }
  }
  .e-grid {
    .e-input-group {
      &::after,
      &::before {
        background: #3a85a8 !important;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
    .e-toolbar-items {
      .e-search-toolbar {
        position: absolute;
        right: 0;
      }
      .e-toolbar-right {
        .e-clear-btn {
          position: absolute;
          right: 16px;
          bottom: 4px;
          min-width: unset !important;
          button {
            background-color: unset;
            .e-btn-icon {
              min-width: unset !important;
              min-height: unset !important;
            }
            .e-close {
              font-size: 11px;
              &:hover {
                filter: alpha(opacity=75);
                opacity: 0.75;
              }
              &.e-focus {
                color: #000 !important;
              }
            }
          }
        }
      }
    }
    .e-gridcontent {
      max-height: calc(100vh - 75px - 86px - 309px);
      overflow: auto;
    }
    .e-headercell {
      .e-headercelldiv {
        padding: 0 1.8em 0 20px !important;
        .e-headertext {
          color: #fff !important;
        }
      }
      .e-icons {
        color: #fff !important;
      }
    }
  }
}
