@import "../../../../css/mixins";
#task-event-title {
  height: 22px;
  display: grid !important;
  grid-template-columns: 40% 60%;
  .task-info {
    color: #fff;
    padding: 2px 6px;
    background-color: #434343;
    width: 100%;
    height: 100%;
    font-size: 12px;
    .completed-icon {
      margin-right: 5px;
      display: inline-block;
      &.multiple {
        margin-right: 8px;
        top: -1px;
      }
    }
  }
  .task-info.task-urn {
    color: #fff;
    margin-right: auto;
    cursor: pointer;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .task-urn-label {
      cursor: pointer;
      position: relative;
      top: 1px;
      &.on-popup {
        top: 0px !important;
      }
    }
  }
  .status-container {
    padding: 2px 10px;
  }
  .task-name {
    .task-name-label {
      font-weight: 600;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .task-img {
    width: 20px;
    margin-left: auto;
  }
}

#week-event-wrapper {
  @include breakpoints(small, 1600px) {
    .event-content {
      display: flex;
      flex-direction: column;
      .event-status {
        width: 100%;
        min-width: 100% !important;
        max-width: 100% !important;
        position: initial;
        margin-bottom: 5px;
      }
    }
  }
  @include breakpoints(small, 790px) {
    .event-img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .event-img.left {
        margin-right: unset !important;
      }
      .event-img.right {
        margin-left: unset !important;
      }
    }
  }
  width: 100% !important;
  min-height: 185px !important;
  background-color: #606060;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  color: #fff;
  text-align: left !important;
  padding: 10px;
  font-size: 12px;
  cursor: pointer !important;
  display: flex !important;
  flex-direction: column;
  &.month-view {
    background-color: #606060e6 !important;
    border: unset !important;
    max-width: 230px !important;
    min-height: unset !important;
    .event-content {
      margin-top: 5px;
      margin-bottom: 30px;
      .content-info {
        margin: 2px 0px;
      }
    }
  }
  #label-component {
    margin-bottom: 10px;
    .value {
      color: #cecece;
      margin-left: 5px;
      &.no-label {
        margin-left: 0px;
      }
    }
  }
  .event-status {
    float: right;
    padding: 8px 10px;
    font-size: 12px;
    color: #fff;
    top: -10px;
    right: -10px;
    min-width: 110px;
    max-width: 110px;
    justify-content: center;
    .event-status-label {
      font-weight: 600;
      display: block;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .event-img-container {
    max-height: 30px;
    width: 100%;
    bottom: 0;
    margin-top: auto;
    display: flex;
    align-items: center;
    .event-seq {
      width: max-content;
      font-size: 12px;
      font-weight: 500;
    }
    .event-img.left {
      float: left;
      margin-right: auto;
      width: 25px;
      height: 25px;
      filter: invert(100%);
    }
    .event-img.right {
      float: right;
      margin-left: auto;
      width: 25px;
      height: 25px;
    }
  }
  .event-content {
    > * {
      cursor: pointer !important;
    }
    text-align: left;
    width: 100%;
    position: relative;
    .content-info.clickable {
      cursor: pointer !important;
    }
    .content-info {
      position: relative;
      white-space: normal;
      margin: 5px 0px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      cursor: default;
      line-height: 1.5em;
      .values {
        color: #cecece;
        margin-left: 5px;
      }
    }
    .event-wrapper {
      margin: -10px -10px;
      margin-bottom: 10px;
    }
  }
}
