/*
 * Application global variables.
*/

$app-primary: #ed1b2e;
$app-success: #66B283;
$app-info: #ed1b2e;
$app-warning: #CDAF34 !default;
$app-error: #ff0000;

$input-border-bottom: linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(189, 189, 189) 50%, rgb(255, 255, 255) 100%);
$input-border-bottom-valid: linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(132, 217, 74) 50%, rgb(255, 255, 255) 100%);
$input-border-bottom-error: linear-gradient(to right, rgb(255, 255, 255) 0%, #E34B22 50%, rgb(255, 255, 255) 100%);
$table-th-bg: #DDDDDD;
$table-th-border-color: #C6C6C5;
$table-tr-even: #F4F4F4;
$table-row-hover: #E8E8E8 !important;
$table-color: #333333;
$table-row-selected: lighten($app-warning, 44%) !important;
$table-row-selected-hover: lighten($app-warning, 39%) !important;
$icon-check-done: $app-success;
$icon-check-selected: $app-warning;
$icon-check-error: #E34B22;

$col-fix-width-xs: 150px;


/*
  Button style variables
*/

$button-bottom-line: 2px solid;
$backward-color: #F2411D;
$static-color: #939393;
$forward-color:  #65CC59;
$backward-button: $backward-color $button-bottom-line;
$static-button: $static-color $button-bottom-line;
$forward-button: $forward-color $button-bottom-line;



/*
  Background-color page variables
*/

$gray-background: #f2f2f2;
$gradient-gray: linear-gradient(0deg, rgba(242,242,242,1) 0%, rgba(235,235,235,1) 35%, rgba(230,230,230,1) 100%);

