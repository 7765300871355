.automation-field-container {
  padding-top: 20px;

  .field-range {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    .range-separator {
      margin-top: 8px;
    }
    .field-info {
      display: none;
    }
    .with-icon {
      display: none !important;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  &.two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    .automation-field {
      width: unset;
    }
  }

  &.with-button {
    display: grid;
    grid-template-columns: 1fr 20px;
    column-gap: 10px;

    .button-container {
      margin-top: 20px;

      .button {
        background-color: transparent;
      }
    }
  }

  .automation-field {
    width: 300px;
    margin-bottom: 10px;
    .info-icon {
      top: 3px !important;
      left: 3px;
    }
    .flex-container {
      justify-content: center;
      height: max-content;
    }
    .multiline-gap {
      display: block;
      height: 7px;
    }
    .multi-line-container {
      display: grid;
      grid-template-columns: 1fr 20px;
      grid-column-gap: 10px;
      i.plus {
        &.more {
          margin-top: 15px !important;
          margin-bottom: -15px !important;
        }
      }
    }
    .chkbx-today {
      margin-top: 10px;
      label {
        padding-left: 25px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #000000;
        font-weight: 500;
        &::after,
        &::before {
          border-color: #3a85a8 !important;
          color: #3a85a8 !important;
        }
      }
    }
    .selection-field-value {
      label {
        color: #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        padding-bottom: 2px;
        cursor: pointer;
      }
      label.selected {
        color: #376f8d;
        border-bottom: 1px solid #376f8d;
        padding-bottom: 2px;
      }
    }
    .condition-value {
      padding-top: 5px;
      .field-label {
        display: none;
      }
    }

    &.full {
      width: 100%;
      &.email {
        .field-input {
          input {
            max-width: 92%;
          }
        }
      }
    }

    &.compute-field {
      width: 70%;
    }

    > label {
      color: #8d8f91;
    }

    &.error {
      label {
        color: #db2828;
      }
      .ui.selection.dropdown {
        background-color: #fff6f6;
        border-color: #e0b4b4;
      }
    }

    span.required {
      color: #db2828;
    }

    .ui.selection.dropdown {
      margin-left: 0px !important;
    }

    .extra-detail {
      color: #8d8f91;
      white-space: nowrap;
      font-size: 10px;
    }
    input.summary {
      min-height: 36px;
    }
    input,
    textarea {
      width: 100%;
      border: #3b85a8 1px solid;
      border-radius: 5px;
      padding: 5px;
      &::placeholder {
        color: #376f8d;
      }
    }

    .field-input {
      width: 100%;
      &.ui.input {
        input {
          min-height: 36px;
        }
      }
      &.Email {
        input {
          max-width: 92%;
        }
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
      input {
        min-height: 36px;
      }
    }

    .field-option {
      .dropdown.icon {
        &::before {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 9px solid #3d87ab;
          content: "";
          top: 15px;
          right: 10px;
          position: absolute;
        }
      }
    }

    &.required.field {
      > span {
        > label {
          color: #8d8f91;
          &:after {
            margin: -0.2em 0 0 0.2em;
            content: "*";
            color: #db2828;
          }
        }
      }
    }

    .compute-fields-container {
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 25px 15px;
      padding-left: 20px;
      position: relative;
      svg {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
      .compute-field-details {
        .field-label {
          color: #757575;
          font-size: 15px;
        }
        .field-operator-container {
          padding-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .op-label {
            color: #757575;
            font-size: 12px;
          }
          .op-fields {
            display: grid;
            align-items: center;
            grid-template-columns: 90px 90px;
            grid-column-gap: 12px;
            .operator {
              width: 80px;
              min-height: unset !important;
              .menu {
                border-color: #e9e8e8 !important;
                border-top-width: 1px !important;
                .item {
                  text-align: center !important;
                  color: #3b86a8 !important;
                }
                .selected.item {
                  border-top: unset !important;
                  background: #f3f3f3 !important;
                }
              }
              .dropdown.icon {
                &::before {
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: 10px solid #3d87ab;
                  content: "";
                  top: 15px;
                  right: 10px;
                  position: absolute;
                }
                &.clear {
                  &::before {
                    content: "\f00d";
                    width: 0;
                    height: 0;
                    border-left: 0px solid transparent;
                    border-right: 0px solid transparent;
                    border-top: 0px solid #3b86a8;
                    position: absolute;
                    top: 12px;
                    right: 21px;
                  }
                }
              }
            }
            .field {
              height: 36px !important;
              background: #f3f3f3;
              border: unset !important;
              input {
                border: unset !important;
                background: #f3f3f3;
                width: 100%;
                padding: 10px !important;
              }
            }
          }
        }
      }
    }
  }
}

#copy-from-popup {
  padding: 0px;
  .copy-from-popup-popup-container {
    width: 140px;
    &.email {
      width: 170px;
      > div {
        padding: 10px 5px 0px 10px;
      }
    }
    > div {
      padding: 10px 5px 0px 5px;
      color: #929292;
    }
    > ul {
      padding: 0px;
      list-style-type: none;
      > li {
        cursor: pointer;
        padding: 5px 20px;
        &:hover {
          background-color: whitesmoke;
        }
        > span {
          color: #3a85a8;
        }
      }
    }
  }
}
