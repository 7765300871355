#lateral-menu-dim {
  right: 0;
  position: fixed;
  top: 59px !important;
  left: auto !important;
  bottom: 0;
  height: 100vh;
  padding: unset !important;
  z-index: 800 !important;
  opacity: 0.5;
}

.lateral-menu-container {
  background: #000000;
  height: calc(100vh - 59px);
  color: #ffffff;
  &.mobile {
    position: relative;
    z-index: 800;
    &.un-collapse {
      width: 85vw;
      .menu-header {
        margin: 10px 20px !important;
        padding-bottom: 10px;
      }
      .app-lateral-menu {
        height: calc(100vh - 59px - 50px - 100px);
        min-height: calc(100vh - 59px - 50px - 100px);
      }
      .app-lateral-menu {
        margin: 10px 20px !important;
      }
      .lateral-menu-action {
        justify-content: space-between;
        width: 75vw;
        div {
          right: 0px !important;
        }
        -webkit-tap-highlight-color: transparent;
      }
      .app-lateral-menu {
        i {
          margin-right: 8px;
        }
        li {
          &.group {
            margin-left: -10px;
          }
        }
      }
      .lateral-menu-bottom-container{
        justify-content: center;
      }
      .lateral-menu-bottom{
        width: 100%;
      }
    }
    &.collapse {
      .menu-header {
        margin: 10px 5px 0px 5px !important;
      }
      .menu-icon{
        margin: unset!important;
      }
      
    }
    .lateral-menu-arrow {
      opacity: 0;
    }
    .menu-header {
      border-bottom: 1px solid #444444;
    }
  }

  .app-lateral-menu {
    height: calc(100vh - 90px - 72px - 98px - 11px);
    min-height: calc(100vh - 90px - 72px - 98px - 11px);
    overflow: auto;
    padding-left: 0;
    padding: 0px 5px 5px 5px;
    label {
      cursor: pointer;
      font-size: 1.1em;
      font-weight: bolder;
    }
    i {
      cursor: pointer;
    }
    li {
      list-style-type: none;
      img {
        filter: invert(100%);
        max-width: 27px;
        margin: 0.4em auto;
        //margin-left: 23%;
      }
    }
    li.group {
      margin-left: -20px;
      cursor: pointer;
      
      &.iconOnly {
        margin-left: -2px;
      }
    }
    li.single {
      margin-left: 0px;
      cursor: pointer;
      margin-top: 5px;
    }
    li.ellipsis {
      margin-left: -15px;
      margin-top: -16px;
    }
    label.selected {
      span {
        padding: 2px;
      }
    }
    li.selected {
      span {
        padding: 2px;
      }
    }
    li.iconOnly {
      text-align: center;
      padding: 10px 0px;
      color: white;
    }
    li.iconOnly.withIcon {
      padding: 1px 1px;
    }
    li.selected.iconOnly {
      img {
        filter: none;
      }
    }
    ul {
      margin-top: 5px;
    }
  }
  .app-lateral-menu.collapse {
    li.group {
      border-radius: 0 !important;
    }
  }
  .menu-icon {
    cursor: pointer;
    align-items: center;
    flex-direction: row;
    width: 35px;
    display: flex;
    i {
      font-size: 18px;
      font-weight: lighter;
      display: inline-block;
      &:before {
        position: absolute;
      }
    }
    img {
      display: inline-block;
      width: 16px;
    }
    svg {
      display: inline-block;
    }
    i.right {
      &:before {
        margin-left: -3px;
      }
    }
  }
  .lateral-menu-action {
    text-align: center;
    cursor: pointer;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      width: 20px;
      margin: auto;
    }
    div {
      padding: 5px;
    
      .hidden {
        display: none;
      }
    }
  }
  .lateral-menu-action-prod {
    bottom: 0;
  }
  .lateral-menu-action-test {
    bottom: 15px;
  }
  .lateral-menu-action.collapse {
    grid-template-columns: 1fr;
    display: grid;
    text-align: center;
    cursor: pointer;
    font-size: 10px;
    padding-top: 5px;
    div {
      right: -10px !important;
      top: -80px;
    }
  }
  .lateral-menu-arrow {
    .left {
      float: right;
      height: 98px;
      bottom: 0px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 49px 25px 49px 0;
    }
    .right {
      float: left;
      height: 100px;
      bottom: 0px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 49px 0 49px 20px;
      position: fixed;
    }
  }
  .menu-header-collapse{
    margin: unset!important;
    height: unset!important;
  }
  .lateral-menu-filter{
    display: flex;
    justify-content: center;
  }
}

.lateral-menu-container{
  .lateral-menu-bottom{
    .menu-logo{
      display: flex;
      align-items: center;
      .logo-container{
        width: 60px;
      }
    }
    .lateral-menu-bottom-container{
      display: flex;
      justify-content: space-between;
      position:relative;
    }
  }
  &.collapse{
    .lateral-menu-action{
      .menu-icon{
        margin: 5px;
      }
    }
    .lateral-menu-bottom{
      position: absolute;
      width: 50px;
      bottom: 0;
      .menu-logo{
        .logo-container{
          width: unset;
        }
        .menu-bpm-logo{
          padding: 5px;
        }
        .label{
          display: none;
        }
      }
    }
  }
  &.un-collapse{
    .lateral-menu-action{
      border-bottom: solid #292828;
      border-bottom-width: thin;
      margin-bottom: 10px;
      .menu-icon{
        margin-left: 16em;
        position: absolute;
        left:0
      }
    }
    .lateral-menu-bottom{
      position: absolute;
      width: 200px;
      bottom: 0;
      .label {
        display: inline-grid;
        text-align: center;
        margin-left: 5px;
        .label-app{
          font-weight: bold;
          letter-spacing: 7px;
          font-size: 10px;
          position: relative;
          bottom: 2px;
          margin-left: 5px;
        }
        .label-mobbiz{
          font-size: 18px;
          font-weight: bolder
        }
      }
      .menu-logo{
        padding: 10px;
      }
     
    }
  }
  .menu-header {
    margin: 10px 10px 0px 5px;
    .menu-bpm-logo {
      max-width: unset !important;
      width: 40px !important;
      margin-left: 7px;
      margin-right: 12px;
    }
  }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
  .lateral-menu-action {
    div {
      img {
        margin: auto;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .app-lateral-menu {
    margin-top: 0px !important;
    overflow: scroll;
  }
  .app-lateral-menu::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .lateral-menu-container {
    .menu-header {
      margin: 10px 10px 0px 5px;
      height: 50px;
    }
  }
}


