.group-form-modal {
  max-width: 750px;
  padding: 5px;
}
#form-modal-header {
  > div {
    padding: 17px;
    font-size: 12pt !important;
  }
}
#group-form {
  > div {
    > div {
      > label {
        font-weight: normal;
        margin: 5px 0px 7px 7px;
      }
      > textarea {
        background-color: #f3f3f3;
        border: none;
      }
      > div {
        > input {
          background-color: #f3f3f3;
          border: none;
          height: 44px !important;
        }
        > i.dropdown.icon {
          color: #3d87ab !important;
          padding-top: 25px;
          &:before {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 12px solid #3d87ab;
            content: "";
          }
        }
        > i.dropdown.icon.clear {
          &:before {
            content: "\f00d";
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            border-top: 0px solid #3d87ab;
            position: absolute;
            top: 12px;
            right: 26px;
            font-size: 12pt !important;
          }
        }
      }
      > div.error {
        background-color: #ff8585 !important;
        > input {
          background-color: #ff8585 !important;
        }
        > div.text {
          background-color: transparent !important;
        }
        > div {
          > div {
            > span.text {
              color: #ff8585 !important;
            }
          }
        }
      }
      > div.ui.selection.dropdown {
        background-color: #f3f3f3;
        border: none !important;
        color: #000 !important;
        margin: unset !important;
        > div {
          background-color: #f3f3f3;
          border: none !important;
          color: #000 !important;
          margin: unset !important;
          max-height: 185px !important;
          > div {
            background-color: #f3f3f3;
            border: none !important;
            color: #000 !important;
            margin: unset !important;
          }
        }
      }
      > #selected-users {
        max-height: 180px;
        overflow: auto;
        padding: 10px 35px 10px 10px;

        .inactive {
          background-color: #f1d3d3;
        }
      }
    }
  }
  .ui.button.floating.labeled.dropdown.icon {
    width: 100%;
    background-color: #f3f3f3;
    color: #a3a3a3;
    padding-left: 10px !important;

    > .text {
      font-weight: 500;
    }

    > .dropdown.icon {
      right: 0;
      left: unset;
      background-color: transparent;

      &:before {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 12px solid #3d87ab;
        content: "";
        top: 18px;
        right: 10px;
        position: absolute;
      }
    }

    .option-title {
      color: #a3a3a3;
      font-size: 13px;
      font-weight: 500;
      padding: 10px 17px 0px;
      text-align: right;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: sticky;
      top: 0;
      right: 0;
      background-color: #fff;
      z-index: 10;
      &.enabled {
        color: #000;
      }
      span {
        font-size: 14px;
        font-weight: bold;
      }
      i {
        font-size: 16px !important;
      }
    }

    > .menu {
      margin-top: 1px !important;
      max-height: unset !important;
    }

    .option-list {
      padding: 10px 15px;
      border-top: none;
      box-shadow: none !important;
      max-height: 220px;
      .ui.fitted.checkbox {
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;

        label {
          padding-left: 25px !important;
          width: 250px;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #000000;
          font-weight: 500;

          &:before {
            border-color: #3a85a8;
          }
          &:after {
            color: #3a85a8;
          }
        }
      }
    }
  }
}
#group-dropdown-container {
  > div {
    > label {
      font-weight: normal;
      margin: 5px 0px 7px 7px;
    }
    i.dropdown.icon {
      color: #3d87ab !important;
      padding-top: 25px;
      &:before {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid #3d87ab;
        content: "";
      }
    }
    i.dropdown.icon.clear {
      &:before {
        content: "\f00d";
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        border-top: 0px solid #3d87ab;
        position: absolute;
        top: 12px;
        right: 26px;
        font-size: 12pt !important;
      }
    }
  }
  > div.ui.selection.dropdown {
    width: 100%;
    background-color: #f3f3f3;
    border: none !important;
    color: #000 !important;
    margin: unset !important;
    > div {
      background-color: #f3f3f3;
      border: none !important;
      color: #000 !important;
      margin: unset !important;
      > div {
        background-color: #f3f3f3;
        border: none !important;
        color: #000 !important;
        margin: unset !important;
      }
    }
  }
  display: flex;
  > .button-plus-group {
    float: right;
    width: 45px;
    margin-right: unset !important;
    margin-left: 5px;
    background-color: #3d87ab;
  }
  > div.error {
    background-color: #ff8585 !important;
    > div.text {
      background-color: transparent !important;
    }
    > div {
      > div {
        > span.text {
          color: #ff8585 !important;
        }
      }
    }
  }
}
#message-container {
  margin: -20px 0px 10px 0px !important;
}
