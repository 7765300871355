#download-widget {
  border-radius: unset !important;
  left: calc(100vw - 400px);
  position: fixed;
  z-index: 1000;
  padding: unset !important;
  min-width: 380px;
  border: unset !important;
  .header {
    background-color: #242424;
    border-radius: unset !important;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: unset !important;
    .header-icon-group {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      align-items: center;
      .icon-collapse {
        width: 17px;
        height: 15px;
        cursor: pointer;
      }
      .icon-close {
        width: 13px;
        height: 13px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  .content {
    padding: 0px 15px;
    display: flex;
    flex-direction: row;
    height: 80px;
    align-items: center;
    .progress-label {
      font-weight: 500;
      margin-left: 15px
    }
    .file-icon {
      width: 30px;
      margin-right: 10px;
    }
    .progress-container {
      margin-left: auto;
    }
  }
}