.document-container {
  grid-template-columns: 200px auto;
  display: grid;
  height: 100%;
  &.mobile {
    grid-template-columns: 50px auto !important;
  }
}
.document-container.collapse {
  grid-template-columns: 50px auto;
  display: grid;
}
.document-main-container {
  margin-top: 96px !important;
  margin: 96px 20px 0px;
  padding: 20px;
  max-height: 600px;
  overflow: auto;
}
.document-menu {
  font-size: 16px !important;
  color: rgb(58, 133, 168) !important;
  //margin-left: auto !important;
  cursor: pointer;
  > .item {
    > .item {
      > .ui.image {
        width: 32px;
        height: 32px;
        margin-right: 5px;
      }
    }
  }
}
.end-user-footer-container {
  margin-top: -60px;
}
.flex-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  position: relative;
}

.document-urn-link {
  a {
    color: #469de4 !important;
  }
}

#document-editor-popup {
  cursor: default;
  .content {
    display: grid;
    text-align: center;
    .action {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &.calendar {
    background-color: #606060f6 !important;
    color: #fff;
    border: unset !important;
  }
}
