@import "../../css/mixins.scss";

#user-bp-modal {
  border-radius: unset !important;
}

.user-bp-modal {
  &.small-landscape {
    max-width: 420px !important;
    .header {
      height: unset !important;
      div {
        font-size: 14px !important;
      }
    }
    .table-list-container {
      max-height: 100px !important;
    }
    .table-list {
      tbody {
        tr {
          .bp-list-detail {
            padding: 3px 80px 3px 15px !important;
            font-size: 12px !important;
          }
          .bulk-upload-icon {
            svg {
              width: 17px;
              height: 17px;
            }
          }
        }
      }
    }
  }
  @include breakpoints(649px, 767px) {
    width: 70.4% !important;
    .table-list-container {
      max-height: 300px !important;
    }
    .table-list {
      tbody {
        tr {
          .bp-list-detail {
            padding: 3px 100px 3px 20px !important;
            font-size: 13px !important;
          }
          .bulk-upload-icon {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  @include breakpoints(500px, 649px) {
    width: 75% !important;
    .table-list-container {
      max-height: 250px !important;
    }
    .header {
      height: unset !important;
      div {
        font-size: 14px !important;
      }
    }
    .table-list {
      tbody {
        tr {
          .bp-list-detail {
            padding: 3px 70px 3px 20px !important;
            font-size: 12px !important;
          }
          .bulk-upload-icon {
            svg {
              width: 17px;
              height: 17px;
            }
          }
        }
      }
    }
  }
  @include breakpoints(240px, 500px) {
    width: 90% !important;
    .table-list-container {
      max-height: 150px !important;
    }
    .header {
      height: unset !important;
      div {
        font-size: 13px !important;
      }
    }
    .table-list {
      tbody {
        tr {
          .bp-list-detail {
            padding: 3px 50px 3px 20px !important;
            font-size: 11px !important;
          }
          .bp-icon-main {
            .bp-sequence {
              font-size: 12px;
            }
            img {
              width: 20px !important;
            }
          }
          .bulk-upload-icon {
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .draft-button {
      font-size: 12px;
      margin-right: 15px !important;
      margin-bottom: 15px !important;
      padding: unset !important;
    }
  }
  border-radius: unset !important;
  max-width: 700px;
  .header {
    background-color: #242424 !important;
    border-radius: unset !important;
    line-height: unset !important;
    height: 50px;
    color: #fff !important;
    font-size: 16px !important;
    .header-close {
      width: 20px;
      position: absolute;
      right: 15px;
      top: 16px;
      cursor: pointer;
    }
  }
  .action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagination-container {
    text-align: center;
    display: flex;
    align-items: center;
  }
  .table-list-container {
    display: block;
    padding: 5px;
    overflow: hidden auto;
    max-height: 300px;
    &::-webkit-scrollbar {
      width: 5px !important;
    }
    .table-list {
      margin: auto;
      tbody {
        tr {
          &:hover {
            background: unset !important;
          }
          td {
            padding: unset;
          }
          .bp-icon-main {
            max-width: 20px;
            .bp-icon {
              width: 20px;
            }
          }
          .bulk-upload-icon-main {
            cursor: pointer;
            .bulk-upload-icon {
              width: 15px;
              height: 22px;
              padding-top: 2px;
            }
          }
          .bp-list-detail {
            cursor: pointer;
            padding: 5px 150px 5px 20px;
            font-size: 14px;
            font-weight: 500;
            a {
              color: #000 !important;
            }
            &:hover {
              text-decoration: underline;
            }
            &.not-allowed {
              pointer-events: none;
              opacity: 0.6;
              &:hover {
                text-decoration: unset;
              }
            }
          }
        }
        .separator {
          border: 0;
          height: 1px;
          background: linear-gradient(to right, #ffffff -30%, #e6e6e6 50%, #ffffff 130%);
          background-size: 100% 50%;
          background-repeat: no-repeat;
        }
      }
    }
  }
  div.content {
    padding: 20px 25px !important;
  }
  .draft-button {
    float: right;
    border-bottom: 1px solid black;
    padding: 10px 2px 3px 2px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    cursor: pointer;
    margin-left: auto;
  }
}
