@import "../../../../../../css/theme-variables";
.automation-main-container {
  padding: 15px !important;
  background: #ffffff;
  margin-top: 14px;
  border-radius: 8px !important;
  height: calc(100vh - 75px - 86px - 157px);

  .pagination-container {
    text-align: center;
  }

  .automation-logs-container {
    background-color: #ffffff;
    margin: unset;
    max-width: 100%;
    overflow: auto;
    height: calc(100vh - 75px - 300px - 86px - 100px);

    .automation-logs-table {
      width: 100%;
      tbody {
        td {
          padding: 5px !important;
          border-right: 0.5px solid #fff;
          font-size: 10pt;
          text-align: center;
          color: #000;
          overflow: hidden;
          max-width: 500px;
          &:first-child {
            width: 250px;
          }
          &:last-child {
            width: 45%;
          }
          .context {
            margin: unset;
            white-space: normal;
            &.error {
              color: #de413a;
            }
          }
        }
      }
      thead {
        th.th-group {
          font-size: 13px !important;
          padding: 3px 10px !important;
          background-color: #3a85a8 !important;
          color: #ffffff !important;
          height: 30px !important;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
        }
      }
      tr {
        &:nth-child(odd) {
          background-color: #f3f3f3;
        }
      }
    }
  }

  .ui.accordion {
    font-size: 13px;
    .content {
      p {
        margin-bottom: 10px;
      }
    }
  }

  .ui.accordion .title:not(.ui) {
    padding: 0.5em 0;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    color: #3a85a8;
    font-weight: bolder;
  }

  .automation-logs-status-container {
    border-radius: 5px;
    padding: 2px 10px 2px 10px;
    margin: 5px;
  }

  .automation-status-success,
  .automation-status-no-action {
    background-color: #c7f1d3;
    color: #509460;
  }

  .automation-status-loop {
    background-color: #dee1e6;
    color: #8a95a0;
  }

  .automation-status-failure {
    background-color: #eadfe4;
    color: #de413a;
  }

  .automation-status-error {
    background-color: #ffefb3;
    color: #8e8b74;
  }
}
