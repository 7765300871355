#custom-filter-configuration-modal {
  max-width: 500px;
}

#custom-filter-configuration-modal #custom-filter-config-form label {
  font-weight: 400;
  font-size: 15px !important;
}

#custom-filter-configuration-modal #custom-filter-config-form input {
  background-color: #f3f3f3;
  border: none;
}

#custom-filter-configuration-modal #custom-filter-config-form input.error {
  background-color: #ff8585;
}

#custom-filter-configuration-modal .fields-container {
  border: 1px solid #d2dde3;
  border-radius: 10px;
  padding: 10px;
}

#custom-filter-configuration-modal .fields-container .dropdown {
  max-width: 220px;
}

#custom-filter-configuration-modal i.dropdown.icon {
  color: #3d87ab !important;
  padding-right: 20px;
}

#custom-filter-configuration-modal i.dropdown.icon {
  color: #3d87ab !important;
  padding-right: 20px;
}

#custom-filter-configuration-modal i.dropdown.icon:before {
  width: 0;
  height: 2px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid #3d87ab;
  content: "";
  position: absolute;
}

#custom-filter-configuration-modal .fields-container .multiple.dropdown {
  max-width: 100%;
  min-height: 100px;
  margin: 10px 0px !important;
  border: none !important;
  background-color: #f3f3f3;
}

#custom-filter-configuration-modal .fields-container .multiple.dropdown .label {
  border: 1px solid #3a85a8;
  background-color: #ffffff;
  font-weight: 400;
}

#custom-filter-configuration-modal .fields-container .multiple.dropdown .label i {
  border: 1px solid #3a85a8;
  width: 13px;
  height: 13px;
  border-radius: 10px;
  margin-right: -5px;
}

#custom-filter-configuration-modal .fields-container .multiple.dropdown .label i.delete:before {
  position: absolute;
  margin-top: -0px;
  margin-left: -4px;
  font-size: 10px;
  color: #3a85a8;
}

#custom-filter-configuration-modal .fields-container #custom-filter-configuration-checkbox label {
  margin-left: 30px;
}

#custom-filter-configuration-modal .fields-container #custom-filter-configuration-checkbox label:before,
#custom-filter-configuration-modal
  .fields-container
  #custom-filter-configuration-checkbox
  input:checked
  ~ label:before {
  width: 25px;
  height: 25px;
  border-color: #83b1c5;
  margin-left: -30px;
  margin-top: -2px;
}

#custom-filter-configuration-modal .fields-container #custom-filter-configuration-checkbox input:checked ~ label:after {
  width: 25px;
  height: 25px;
  font-size: 20px;
  margin-top: 4px;
  color: #83b1c5;
  margin-left: -30px;
  margin-top: 1px;
}
