#file-camera-popup {
  right: 0;
  width: fit-content !important;
  background-color: transparent !important;
  box-shadow: unset !important;
  border-radius: 0.28571429rem !important;
  position: relative;
  .close-icon {
    width: 12px;
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 999;
    cursor: pointer;
    &.captured {
      top: 8px;
      right: 8px;
    }
  }
  .content {
    padding: unset !important;
    background-color: transparent !important;
    border-radius: 0.28571429rem !important;
    &.captured {
      padding: 1.5rem 1.5rem 0.2rem !important;
      background: #fff !important;
    }
    .image-preview {
      position: relative;
      img {
        border-radius: 0.28571429rem !important;
      }
      svg {
        cursor: pointer;
      }
      .image-action {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        padding: 10px 15px;
        justify-content: center;
        .retake {
          position: absolute;
          bottom: 65px;
          left: 10px;
        }
      }
    }
  }
}
