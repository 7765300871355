.ui.menu {
  //border: unset !important;
  box-shadow: unset !important;
  .active.item {
    color: rgb(58, 133, 168) !important;
    background: unset !important;
    font-weight: bolder !important;
  }
  .item {
    > i.icon {
      margin: unset !important;
    }
  }
  &.no-border {
    .item {
      &:before {
        background: none;
      }
    }
  }
}

.menu-item-right {
  > .item {
    font-size: 16px !important;
    color: rgb(127, 127, 127) !important;
    &.settings {
      padding: 2px !important;
    }
  }
}

.menu-item-left {
  > .item {
    font-size: 16px !important;
    color: rgb(58, 133, 168) !important;
  }
}

.active-directory {
  color: rgb(58, 133, 168) !important;
  font-weight: bolder !important;
  margin: 0px 5px 0px 5px;
}

.borderless {
  //border: unset !important;
  box-shadow: unset !important;
}

.admin-item-menu {
  border-radius: 7px !important;
  padding: 15px !important;
  margin: 0px 5px 0px 5px !important;
  min-width: 200px;
  color: rgb(58, 133, 168) !important;
  background-color: unset !important;
  box-shadow: 0 0 0 1px rgb(58, 133, 168) inset !important;
  font-weight: 100 !important;
  font-size: 16px !important;
}

.admin-active-menu {
  background-color: rgb(58, 133, 168) !important;
  color: #fff !important;
  font-weight: bolder !important;
}

.active-subroute {
  background-color: rgb(58, 133, 168) !important;
  color: #fff !important;
}

#route-container {
  overflow: auto;
  max-width: 100%;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &.flex {
    justify-content: unset !important;
    align-items: center;
  }
}

.main-menu {
  height: 75px !important;
  &.ui.menu.fixed {
    z-index: 0;
  }

  i.plus.circular.icon {
    color: #fff;
    background: #3d87ab;
    margin-top: 25px;
  }
  .menu-route-label {
    .label {
      text-decoration: underline;
    }
    .warning {
      color: #eb5757;
      font-style: italic;
      margin-left: 7px;
    }
    &.current-route,
    .label.current-route {
      text-decoration: none !important;
      cursor: default !important;
      pointer-events: none !important;
    }
  }
  .menu-route-label.current-route {
    pointer-events: none !important;
  }

  &.end-user {
    height: 59px !important;
  }
}
