.form-builder-field-container {
  .section-container {
    .settings-container {
      box-shadow: 1px 1px 14px 0px rgba(145, 145, 145, 0.87);
      width: calc(100vw - 40vw);
      right: 5px;
      float: right;
      z-index: 10;
      margin-bottom: 10px;
      position: absolute;
      div.footer {
        button.btn-default {
          position: absolute;
          right: 170px;
          bottom: 15px;
        }
        button.btn-success {
          position: absolute;
          right: 10px;
          bottom: 15px;
        }
      }
    }
  }
}

.settings-container {
  width: 850px !important;
  .settings-form {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin: 1em -0.5em 1em !important;
  }
  div.header {
    div {
      font-size: 13pt !important;
      height: 50px !important;
      padding: 13px !important;
    }
  }
  div.content {
    div.body {
      form {
        div {
          div {
            div.field {
              border: none !important;
            }
            div {
              label {
                font-weight: normal;
                font-size: 12pt;
              }
              div {
                input {
                  background-color: #f3f3f3 !important;
                  border: none;
                  max-height: 37px;
                }
                label {
                  &:after {
                    width: 25px !important;
                    height: 25px !important;
                    font-size: 18px !important;
                    margin: 10px 0px 0px 10px !important;
                    color: #3d87ab !important;
                    top: -5px;
                  }
                  &:before {
                    width: 25px;
                    height: 25px;
                    border: 1px solid #3d87ab !important;
                    border-radius: 3px !important;
                    margin-left: 10px !important;
                  }
                }
                i.dropdown.icon {
                  color: #3d87ab !important;
                  padding-top: 30px;
                }
                a.ui.label {
                  display: inline-block;
                  background-color: #fff;
                  border: 1px solid #457b90;
                  margin: 2px;
                  border-radius: 4px;
                  box-shadow: none;
                  padding: 3px 8px;
                  font-size: 12px;
                }
                a {
                  i.delete.icon {
                    border: 1px solid #3d87ab;
                    border-radius: 10px;
                    color: #3d87ab;
                    padding: 2px;
                    width: 18px;
                    height: 18px;
                    margin-left: 10px !important;
                    margin-right: -2px !important;
                  }
                }
              }
              textarea {
                background-color: #f3f3f3;
                border: none !important;
                min-height: 280px;
              }
              div.ui.selection.dropdown {
                min-height: 32px;
                div {
                  border: none !important;
                  color: #000 !important;
                  div {
                    border: none !important;
                    color: #000 !important;
                  }
                }
                input.search {
                  margin: -5px;
                }
              }
              i.dropdown.icon {
                &:before {
                  width: 0;
                  height: 0;
                  border-left: 7px solid transparent;
                  border-right: 7px solid transparent;
                  border-top: 12px solid #3d87ab;
                  content: "";
                  top: 14px;
                  position: relative;
                }
              }
              i.dropdown.icon.clear {
                &:before {
                  content: "\f00d";
                  width: 0;
                  height: 0;
                  border-left: 0px solid transparent;
                  border-right: 0px solid transparent;
                  border-top: 0px solid #3d87ab;
                  position: absolute;
                  top: 15px;
                  right: 25px;
                  font-size: 16px !important;
                }
              }
            }
            div.checkbox {
              margin: 8px 0px;
              width: max-content !important;
              label {
                padding-left: 45px !important;
                padding-top: 5px !important;
                font-size: 14px;
              }
            }
            div.settings-checkbox {
              display: grid;
              margin: 15px 0px;
              div.field {
                display: grid;
                grid-template-columns: 0.5fr 1fr;
                margin: 5px 0px;
                align-items: center;
                margin-left: 50px;
                label {
                  font-size: 14px;
                  margin: unset;
                }
                .ui.selection.dropdown {
                  margin: unset !important;
                  font-size: 13px;
                  background-color: #f3f3f3;
                  border: none !important;
                  color: #000 !important;
                  .item {
                    font-size: 13px !important;
                    padding: 10px 16px !important;
                  }
                  input {
                    height: unset !important;
                  }
                  i.dropdown.icon {
                    padding: 0.91666667em 8px !important;
                    top: 7px;
                    &::before {
                      border-left: 7px solid transparent;
                      border-right: 7px solid transparent;
                      border-top: 10px solid #3d87ab;
                    }
                  }
                  i.dropdown.icon.clear {
                    &::before {
                      top: 13px !important;
                      right: 19px !important;
                      border-left: 0px solid transparent;
                      border-right: 0px solid transparent;
                      border-top: 0px solid #3d87ab;
                      font-size: 13px !important;
                    }
                  }
                }
              }
            }
            .section-select {
              .pinned-section {
                display: flex;
                padding: 15px 15px 0px;
                .error-text {
                  color: #eb5757;
                  margin-left: 10px;
                  font-size: 13px;
                }
              }
              .ui.selection.dropdown {
                margin: unset !important;
                font-size: 13px;
                background-color: #f3f3f3;
                border: none !important;
                color: #000 !important;
                width: 100%;
                .item {
                  font-size: 13px !important;
                  padding: 10px 16px !important;
                }
                input {
                  margin: unset !important;
                }
                i.dropdown.icon {
                  padding: 0.91666667em 8px !important;
                  top: 7px;
                  &::before {
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 10px solid #3d87ab;
                  }
                }
                i.dropdown.icon.clear {
                  &::before {
                    top: 13px !important;
                    right: 19px !important;
                    border-left: 0px solid transparent;
                    border-right: 0px solid transparent;
                    border-top: 0px solid #3d87ab;
                    font-size: 13px !important;
                  }
                }
                .menu {
                  max-height: 200px;
                }
              }
              .checkbox {
                margin-top: 15px;
              }
            }
          }
          div.ui.segment {
            width: 50% !important;
            margin: unset;
          }
        }
      }
      margin-top: -35px;
    }
  }
  .field.error {
    > div {
      background-color: #ff8585 !important;
    }
  }
}
