@import "../../../../css/theme-variables";

.datatable-list-container {
  .table-detail-content {
    background-image: linear-gradient(to bottom, #f1f1f1, white);

    .table-gradient-border-bottom {
      tr {
        &:hover {
          background: transparent !important;
        }
      }
      th {
        &:last-child {
          background: transparent !important;

          &:hover {
            background: transparent !important;
          }
        }

        &:hover {
          background: $table-th-bg;
        }
      }
    }

    .datatable-detail-list {
      tr {
        &:hover {
          td {
            background: $table-row-hover !important;
          }
        }
      }

      &.last-child {
        th {
          background: transparent !important;
        }
      }

      td {
        cursor: pointer;
        text-align: center;

        &:last-child {
          background-color: unset;
        }
      }
    }
  }
}
