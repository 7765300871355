.dropzone-container{
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: unset;
  padding: 0px 5px;
  .file-selected {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    .file-icon {
      width: 25px;
      height: 20px;
      margin-right: 15px;
    }
    .file-size {
      color: gray;
      font-size: 13px;
      font-style: italic;
      margin: unset;
      margin-left: 5px;
    }
    .icon.close {
      cursor: pointer;
      margin-left: 20px;
    }
    .file-name {
      font-size: 16px;
    }
  }
}

.dropzone{
  width: unset;
  border-radius: 12px;
  padding: 20px;
  border: 2px dashed #fff;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(to left, #f6c870, #acd4bc 49%, #5681a3) border-box;
}

.dropzone-file-upload-btn{
  margin-top: 15px;
  padding: 5px 25px;
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(to bottom, #81a5b1, #6c99ae 49%, #5e89a9) !important;
}