#task-view-aggregate-modal {
  .content {
    padding: 20px 50px;
  }

  div.ui.checkbox {
    width: 180px;
    margin: 15px 0px;
    color: #3d87ab;

    > label {
      padding-left: 30px;
      font-weight: 700;
      font-size: 13px;
      padding-top: 3px;
    }
  }
  input:checked ~ label:after {
    font-size: 16px !important;
    margin: 3px 0px 0px 1px !important;
    color: #3d87ab !important;
    float: right;
  }
  input:checked ~ label:before {
    border: 1px solid #3d87ab !important;
    border-radius: 2px !important;
    margin-top: 1px !important;
    width: 20px;
    height: 20px;
    background: #fff !important;
    font-size: 16px !important;
  }
}
