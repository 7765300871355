#duplicate-popup {
  border-radius: unset !important;
  max-height: 465px;
  .close-icon {
    width: 25px;
    margin-left: auto;
    cursor: pointer;
  }
  .sad-icon {
    width: 50px;
    margin-left: 10px;
  }
  .header {
    .label {
      font-weight: bolder;
      color: #000;
      cursor: pointer;
      margin-left: 20px;
    }
    span {
      font-size: 15px;
      margin-top: -10px;
      font-weight: 500 !important;
    }
  }
  .content {
    .duplicate-list-container {
      max-width: 100%;
      overflow: auto;
      justify-content: center;
      display: flex;
      height: calc(445px - 71px - 200px);
      .duplicate-list-table {
        table-layout: auto;
        white-space: nowrap;
        width: 100%;
        thead {
          th.th-fields {
            font-size: 11pt;
            padding: 3px 10px;
            color: #000;
            height: 30px !important;
            text-align: center;
            min-width: 150px;
          }
          tr {
            th {
              position: sticky;
              top: 0px;
              background-color: #fff;
              z-index: 1;
            }
          }
        }
        tbody {
          tr {
            padding: 20px 5px !important;
            border-right: .5px solid #fff;
            font-size: 10pt;
            text-align: center;
            color: #000;
            td {
              padding: 3px 10px;
            }
            td.owner {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  .actions {
    padding: 15px 30px;
    padding-right: 2.25rem;
    display: flex;
    .btn-cancel {
      border-bottom: 2px solid #9A9A9A;
      padding-bottom: 5px;
      width: 55px;
      margin-left: auto;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
    }
    .btn-confirm {
      border-bottom: 2px solid #7EBB61;
      padding-bottom: 5px;
      width: 55px;
      margin-left: 20px;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .label-confirm {
    display: flex;
    justify-content: flex-end;
    padding: 0px 2.25rem;
    margin: 5px 0px;
  }
}