#event-popup {
  .event-popup-container {
    width: auto;
    min-width: 300px;
    max-height: 435px;
    overflow: auto;
    .ui.header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: #fff;
      min-height: 20px;
    }
    .task-name {
      max-width: unset !important;
    }
    .event-wrapper {
      max-width: 350px !important;
      min-width: 300px !important;
      margin: 7px 0px;
      &.week {
        margin: -10px -10px !important;
        margin-bottom: 8px !important;
      }
      .task-urn-label {
        white-space: nowrap !important;
      }
    }
  }
}