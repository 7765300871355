@import "../../../../css/theme-variables";
@import "../../../../css/mixins";

#file-upload-column {
  &.one {
    width: 85px !important;
    min-width: 85px !important;
    .file-field-btn {
      max-width: 80px !important;
      margin: unset !important;
    }
  }
  .file-field-list {
    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 7px;
    width: fit-content;
    white-space: normal;

    .file-field-btn {
      max-height: 25px;
      min-width: 50px;
      max-width: 65px;
      width: 100%;
      border-radius: 3px;
      color: #fff;
      padding: 7px 10px;
      margin: 0px 3px;
      white-space: nowrap;
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      &:hover {
        filter: brightness(95%);
      }
      &.noData {
        background: #c7eaf1;
        color: #498f9b;
        &.readonly {
          cursor: not-allowed !important;
        }
      }
      &.hasData {
        background: #3e86a7;
        color: #fff;
      }
    }
  }
}

.task-table-container {
  overflow: auto;
  height: calc(100vh - 50px - 75px - 50px);
  .task-list-loading {
    margin-top: 100px;
    position: relative;
  }
  &::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
  }
  .task-table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    &.custom-view {
      width: 100% !important;
      table-layout: auto;
      white-space: nowrap;
      thead {
        tr {
          .th-system-fields {
            &.urn {
              max-width: 150px !important;
              width: 130px;
            }
            &.owner-header {
              width: 85px !important;
              min-width: unset !important;
            }
          }
          .th-system-fields,
          .status-header {
            min-width: 130px !important;
          }
        }
      }
      tbody {
        tr {
          td {
            max-width: unset !important;
            img {
              margin: auto;
            }
            .tr-dropdown-type {
              .tr-alignment {
                display: inline-block !important;
                background-color: #eaeaea !important;
                border: 1px solid #000 !important;
                margin-left: 0px !important;
                margin-right: 3px !important;
                border-radius: 5px !important;
              }
            }
          }
        }
        .agg-separator {
          td {
            padding: 0px;
            height: 0.1em;
            z-index: 0;
            &:not(:last-child) {
              background-color: #c6c6c5 !important;
            }
          }
        }
        .task-agg-row {
          td {
            padding: unset;
            &:not(:last-child) {
              background-color: #dddddd !important;
            }
          }
          .task-agg-col {
            padding: 0.3em 1em;
            .task-agg-data-main {
              .task-agg-enum {
                display: flex;
                justify-content: left;
                .agg-func-label {
                  font-size: 11px;
                  font-weight: 500 !important;
                }
                i {
                  margin-left: 5px;
                }
              }
              .task-agg-data {
                font-size: 14px;
                font-weight: 600 !important;
              }
            }
          }
        }
      }
    }
    &.tablet-landscape {
      white-space: nowrap;
      font-size: 12px !important;
      .action-header,
      .action-data {
        .task-bulk-actions-container {
          height: 28px;
          .btn-action-collapse {
            padding: 0px 6px !important;
            &.hasDynamicAction {
              margin-left: 0px;
              .dynamic-actions-collapse {
                &::after {
                  font-size: 9px;
                  left: 45%;
                }
              }
            }
          }
        }
      }
      * {
        font-weight: normal !important;
      }
      thead {
        th {
          min-width: unset !important;
          &.ownership {
            max-width: 80px;
          }
        }
      }
      tbody {
        td {
          max-width: 105px;
          white-space: normal;
          .status-container {
            padding: 5px 10px !important;
            font-size: 11px !important;
          }
          &.ownership {
            .img-ownership-type {
              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
          label {
            white-space: normal;
          }
          &:last-child {
            width: 30px;
            min-width: 30px !important;
            max-width: 30px;
          }
        }
      }
    }
    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 7;
      tr {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 5 !important;
        th {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 3 !important;
          &:last-child {
            position: -webkit-sticky !important;
            position: sticky !important;
            top: 0 !important;
            right: 0 !important;
            border-left: none !important;
          }
          &:nth-last-child(2) {
            border-right: unset !important;
          }
          .btn-action-collapse {
            .dynamic-actions-collapse {
              font-size: 28px;
              display: flex;
              justify-content: center;
              height: 100%;
              cursor: pointer;
            }
          }
          .ui.checkbox {
            min-height: 100%;
            top: -14px;
            label {
              &::after {
                font-size: 12px !important;
                top: 3px !important;
              }
              &::before {
                border: 2px solid #949494 !important;
                top: 2px !important;
                border-radius: 4px;
              }
            }
          }
        }
        .ownership {
          max-width: 90px;
          min-width: 90px;
        }
        .status {
          max-width: 45px;
          min-width: 45px;
          &.view {
            min-width: 130px;
          }
        }
        .status-header {
          min-width: 100px !important;
          border-right: 0px !important;
        }
        .action-header {
          width: 65px !important;
          min-width: 65px !important;
          background-color: #fff !important;
          padding: unset !important;
          border: unset !important;
        }
        .current-status {
          min-width: 130px;
          border-right: 0px !important;
        }
      }
    }
    tbody {
      tr:nth-of-type(even) {
        td {
          &:last-child {
            background-color: $table-tr-even;
          }
        }
      }
      tr {
        &.selected {
          .action-data {
            &.disabled {
              cursor: not-allowed !important;
            }
          }
        }
        &:hover {
          td {
            background: $table-row-hover !important;
            &:last-child {
              background: $table-row-hover !important;
            }
          }
        }
        td {
          right: 0;
          text-align: center !important;
          &:last-child {
            position: sticky;
            right: 0;
            background-color: #fff !important;
            width: 50px;
            min-width: 50px !important;
            z-index: 5 !important;
          }
          &.disabled > * {
            pointer-events: none !important;
          }
          .action-data {
            width: 65px !important;
            min-width: 65px !important;
            right: 0;
          }
          .status-container {
            padding: 8px 12px;
            font-weight: 500;
            font-size: 12px;
            max-width: 200px;
            margin-left: auto;
            margin-right: auto;
          }
          .task-bulk-actions-container {
            .dynamic-actions-collapse {
              font-size: 28px;
              display: flex;
              justify-content: center;
              height: 100%;
              cursor: pointer;
            }
          }
          .check-icon {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .check-icon.disabled {
            pointer-events: none !important;
            cursor: default !important;
            opacity: 0.5;
          }
          .check-icon.pending.warn.selected {
            background-color: #66b283 !important;
            color: #fff !important;
          }
          .check-icon.pending.ok.selected {
            background-color: #66b283 !important;
            color: #fff !important;
          }
          .check-icon.pending.ok {
            border: 1px solid #66b283;
            color: #66b283;
            &::after {
              content: "\2713";
            }
          }
          .check-icon.warn {
            border: 1px solid $icon-check-done;
            color: $icon-check-done;
          }
          .check-icon.pending.warn {
            border: 1px solid #66b283 !important;
            color: #66b283;
            &::after {
              content: "\2713";
            }
          }
          .check-icon.off,
          .check-icon.late {
            background-color: $icon-check-error;
          }
          .check-icon.completed.off.selected {
            background-color: #66b283 !important;
            color: #fff !important;
          }
          .check-icon.completed.off {
            background-color: #66b283 !important;
            color: #fff !important;
            font-size: 11px;
            &::after {
              content: "\0021";
            }
          }
          .check-icon.pending.off,
          .check-icon.pending.late {
            background-color: #e34b22 !important;
            color: #fff !important;
            font-size: 11px;
            &::after {
              content: "\0021";
            }
          }
          .check-icon.off.selected,
          .check-icon.late.selected {
            &::after {
              content: "\2713";
            }
          }
        }
        .user-info-avatar {
          justify-content: center;
          .user-photo.default {
            border: 1px solid black;
          }
          .user-photo.default.user-svg {
            border-radius: 500rem;
            margin-right: 0.25em;
          }
          .user-name {
            font-weight: 500;
          }
        }
        .ownership {
          .img-ownership-type {
            margin: auto;
          }
        }
        .urn {
          font-weight: 400;
        }
        .undo {
          .undo-icon {
            width: 20px;
          }
        }
      }
    }
  }
}
