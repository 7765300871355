.automation-form-detail-new-condition {
  height: 100%;
  padding: 5px 30px;
  overflow: auto;
}

.automation-form-detail-condition {
  height: calc(100vh - 250px);
  padding-bottom: 30px;
  padding: 0px 40px;
  overflow: auto;
}
