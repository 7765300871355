.user-profile-info {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 20px;
	align-items: center;
	.user-profile-avatar {
		justify-content: center;
		width: 100%;
		height: 152px;
	}
	.progress-container {
		position: absolute;
		left: 19px;
		.react-sweet-progress-circle-outer {
			width: 140px !important;
			height: 140px !important;
		}
	}
	.profile-photo {
		width: 90% !important;
		height: 160px !important;
		margin: unset !important;
	}
	.profile-photo.default {
		border: 1px solid black;
	}
	.avatar-upload-icon {
		font-size: 35px;
		-webkit-box-pack: center;
		justify-content: center;
		transition: all 0.25s ease 0s;
		border-radius: 50%;
		margin: 2px;
		background-color: rgba(0, 0, 0, .6);
		width: 160px !important;
		height: 160px !important;
		opacity: 0;
		position: absolute;
		left: 8px;
		top: -6px;
		&:hover {
			opacity: 1;
			animation-duration: .5s;
			transition: opacity 0.25s ease 0s;
		}
		&:hover~#profile {
			filter: blur(1.5px);
			-webkit-filter: blur(1.5px);
		}
		.camera {
			margin: 2.1em auto auto 1.75em;
			color: #fff;
			cursor: pointer;
		}
	}
	.profile-name-container {
		height: 30px;
		width: 100%;
		justify-content: center;
		margin-top: 15px;
		.edit-profile {
			width: 20px;
			height: 20px;
			margin-right: auto;
			margin-left: 5px;
		}
	}
	.profile-name {
		margin-left: auto;
		margin-right: 5px;
		font-weight: 800;
	}
}
