.datatable_grid {
  th {
    font-size: 13px !important;
    padding: 3px 10px !important;
    background-color: #3a85a8 !important;
    color: #ffffff !important;
    height: 30px !important;
  }
}

.datatable_icons {
  color: #3a85a8 !important;
}
