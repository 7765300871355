.slas-task-manager-container {
  border-right: 1px solid #3b86a8;
  padding: 15px 25px;
  text-align: center;
  font-weight: 500;
  font-size: 10pt;
  color: #3d87ab;
  overflow: hidden auto;
  .input-date {
    background: #f3f3f3;
    display: flex;
    padding: 5px;
    margin: 8px 0px;
    input {
      &:checked ~ label {
        &:after {
          font-size: 16px !important;
          margin: 3px 0px 0px 1px !important;
          color: #3d87ab !important;
          float: right;
          font-size: 16px !important;
          margin: 10px 0px 0px 1px !important;
          color: #3d87ab !important;
          float: right;
        }
        &:before {
          border: 1px solid #3d87ab !important;
          border-radius: 2px !important;
          margin-top: 1px !important;
          width: 20px;
          height: 20px;
          background: #fff !important;
          font-size: 16px !important;
          border: 1px solid #3d87ab !important;
          border-radius: 2px !important;
          margin-top: 8px !important;
          width: 20px;
          height: 20px;
          background: #fff !important;
          float: right;
        }
      }
      background-color: transparent !important;
      box-shadow: unset !important;
      color: #000 !important;
      border: unset !important;
    }
    label {
      &:before {
        background: #fff !important;
        border: 1px solid #3d87ab !important;
        border-radius: 2px !important;
        width: 20px;
        height: 20px;
        margin-top: 1px !important;
      }
    }
    div.ui.input {
      flex-basis: 90%;
    }
  }
  .switch-field-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    .ui.toggle.checkbox {
      input:checked ~ label:before {
        background-color: #3d87ab !important;
      }
    }
  }
  .task-assigned {
    background: #f3f3f3;
    display: flex;
    padding: 10px 5px;
    margin: 5px 0px 20px 0px;
    input {
      &:checked ~ label {
        &:after {
          font-size: 16px !important;
          margin: 3px 0px 0px 1px !important;
          color: #3d87ab !important;
          float: right;
        }
        &:before {
          border: 1px solid #3d87ab !important;
          border-radius: 2px !important;
          margin-top: 1px !important;
          width: 20px;
          height: 20px;
          background: #fff !important;
          font-size: 16px !important;
        }
      }
    }
    label {
      &:before {
        background: #fff !important;
        border: 1px solid #3d87ab !important;
        border-radius: 2px !important;
        width: 20px;
        height: 20px;
        margin-top: 1px !important;
      }
    }
    span {
      text-align: left !important;
      padding: 2px 0px 0px 10px !important;
      font-weight: 500;
      font-size: 10pt;
      color: #3d87ab;
      flex-basis: 90%;
    }
  }
  .process-launch {
    background: #f3f3f3;
    display: flex;
    padding: 10px 5px;
    margin: 5px 0px 20px 0px;
    input {
      &:checked ~ label {
        &:after {
          font-size: 16px !important;
          margin: 3px 0px 0px 1px !important;
          color: #3d87ab !important;
          float: right;
        }
        &:before {
          border: 1px solid #3d87ab !important;
          border-radius: 2px !important;
          margin-top: 1px !important;
          width: 20px;
          height: 20px;
          background: #fff !important;
          font-size: 16px !important;
        }
      }
    }
    label {
      &:before {
        background: #fff !important;
        border: 1px solid #3d87ab !important;
        border-radius: 2px !important;
        width: 20px;
        height: 20px;
        margin-top: 1px !important;
      }
    }
    span {
      text-align: left !important;
      padding: 2px 0px 0px 10px !important;
      font-weight: 500;
      font-size: 10pt;
      color: #3d87ab;
      flex-basis: 90%;
    }
  }
  .days-list-wrapper {
    input {
      &:checked ~ label {
        &:after {
          font-size: 16px !important;
          margin: 3px 0px 0px 1px !important;
          color: #3d87ab !important;
          float: right;
        }
        &:before {
          border: 1px solid #3d87ab !important;
          border-radius: 2px !important;
          margin-top: 1px !important;
          width: 20px;
          height: 20px;
          background: #fff !important;
          font-size: 16px !important;
        }
      }
    }
    label {
      &:before {
        background: #fff !important;
        border: 1px solid #3d87ab !important;
        border-radius: 2px !important;
        width: 20px;
        height: 20px;
        margin-top: 1px !important;
      }
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    text-align: left;
    min-height: 165px;
    div.ui.checkbox {
      width: 100%;
      margin: 5px 0px;
      color: #3d87ab;
    }
    div {
      label {
        text-align: left !important;
        padding: 3px 0px 0px 30px !important;
        font-weight: 400;
        font-size: 11pt;
        color: #3d87ab !important;
        &:hover {
          color: #3d87ab;
        }
      }
    }
    .ui.checkbox {
      input {
        &:focus ~ label {
          color: #3d87ab;
        }
      }
    }
  }
  .working-hours-container {
    margin-bottom: 20px;
    input {
      &:checked ~ label {
        &:after {
          font-size: 16px !important;
          margin: 3px 0px 0px 1px !important;
          color: #3d87ab !important;
          float: right;
        }
        &:before {
          border: 1px solid #3d87ab !important;
          border-radius: 2px !important;
          margin-top: 1px !important;
          width: 20px;
          height: 20px;
          background: #fff !important;
          font-size: 16px !important;
        }
      }
    }
    label {
      &:before {
        background: #fff !important;
        border: 1px solid #3d87ab !important;
        border-radius: 2px !important;
        width: 20px;
        height: 20px;
        margin-top: 1px !important;
      }
    }
    div {
      label {
        text-align: left !important;
        padding: 3px 0px 0px 30px !important;
        font-weight: 400;
        font-size: 11pt;
        color: #3d87ab !important;
      }
    }
    .time-widget-wrapper {
      display: flex;
      text-align: left;
    }
    div.date-picker-container {
      label {
        padding: unset !important;
        text-align: left;
      }
      margin-top: 10px;
      margin-right: 25px;
      display: grid;
      min-width: 150px !important;
      .react-datepicker-wrapper {
        input {
          background-color: #f3f3f3 !important;
          border: none !important;
          height: 30px;
          padding-left: 10px;
          margin-right: 10px;
        }
        .react-datepicker__input-container {
          button {
            background: none !important;
            border-bottom: 1px solid #3b86a8 !important;
            border-radius: unset !important;
            color: #3b86a8;
            text-align: left !important;
            padding-left: 2px !important;
            min-width: 100% !important;
            i {
              background: none !important;
            }
          }
        }
      }
    }
    .date-picker-container {
      .react-datepicker-wrapper {
        margin-top: 5px;
      }
    }
    div.ui.checkbox {
      width: 100%;
      margin: 5px 0px 20px 0px;
      color: #3d87ab;
    }
  }
  div.label-days {
    text-align: left;
    font-size: 11pt;
    margin: 20px 0px 15px 0px;
    font-weight: 600;
  }
  div.label-hours {
    text-align: left;
    font-size: 11pt;
    margin-bottom: 10px;
    font-weight: 600;
  }
}
