@import "../../../../css/mixins";
.week-view-container {
  .rbc-time-content {
    > * + * {
      > * {
        min-height: 50px !important;
      }
    }
  }
  @include breakpoints(medium, x-large) {
    .custom-header {
      display: block;
      position: relative;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  @include breakpoints(1100px, x-large) {
    .custom-header {
      display: block;
      position: relative;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  @include breakpoints(1600px, 1760px) {
    .rbc-time-content {
      > * + * {
        > * {
          min-height: 48px !important;
        }
      }
    }
  }
  @include breakpoints(1400px, 1600px) {
    .rbc-time-content {
      > * + * {
        > * {
          min-height: 52px !important;
        }
      }
    }
  }
  @include breakpoints(small, 1400px) {
    .rbc-time-content {
      > * + * {
        > * {
          min-height: 57px !important;
        }
      }
    }
  }
  .rbc-calendar {
    padding-right: 20px;
    .rbc-time-view {
      padding: 10px 8px;
      border: unset !important;
      background-color: #ededed !important;
      min-height: max-content;
      .rbc-today {
        .rbc-timeslot-group {
          border: none !important;
        }
      }
    }
    .rbc-time-header-gutter {
      padding: unset !important;
      display: none !important;
    }
    .rbc-time-header-content {
      .rbc-allday-cell {
        display: none !important;
      }
      .rbc-header {
        height: 60px !important;
        font-size: 14px !important;
        font-weight: 800 !important;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ffffff;
        padding: unset !important;
        border: 1px solid #e0dfdf;
        margin: 0px 3px;
        a {
          width: 100%;
          height: 100%;
        }
        .custom-header {
          color: #595a5a !important;
          height: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          font-weight: 700;
        }
      }
      border: none !important;
    }
    .rbc-time-header.rbc-overflowing {
      margin-right: 0px !important;
      border: none !important;
    }
    .rbc-time-content {
      .rbc-event {
        border: none !important;
        background-color: #606060;
        border-radius: unset !important;
      }
      > * + * {
        > * {
          min-height: 44px;
        }
      }
      border: none !important;
      overflow-y: unset;
      .rbc-time-column {
        .custom-show-more-label {
          cursor: pointer;
          z-index: 4;
          font-weight: bold;
          font-size: 85%;
          height: auto;
          line-height: normal;
          white-space: nowrap;
          margin-left: 5px;
          color: #434343;
        }
      }
      .rbc-day-slot {
        background-color: #ffffff;
        margin: 0px 3px;
        .rbc-timeslot-group {
          border: none;
          border-left: 1px solid #ededed !important;
          border-right: 1px solid #ededed !important;
        }
      }
      .rbc-events-container {
        margin-right: unset !important;
        border-left: unset !important;
      }
      .rbc-current-time-indicator {
        display: none !important;
      }
    }
  }
  .user-info-avatar {
    width: fit-content;
    .user-photo.default {
      border: 1px solid white;
      svg {
        width: 16px;
        height: 15px;
      }
    }
    .user-photo.default.user-svg {
      border-radius: 500rem;
      margin-right: 0.25em;
      width: 18px;
      height: 18px;
    }
  }
}
