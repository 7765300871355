#role-action-modal {
  border-radius: 5px;
  box-shadow: 0px 2px 6px #000000;
  width: 180px;
  padding: 5px;
  position: absolute;
  z-index: 15;
  background-color: #ffffff;
  .header-name {
    border-radius: 3px;
    border: none;
    background-color: transparent;
    padding-left: 3px;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #3c85a5;
    text-transform: uppercase;
  }
  .static-button-container {
    display: grid;
    grid-template-columns: 25px 25px 25px 25px 25px;
    grid-gap: 10px;
    padding: 5px 0px;
    img.active {
      background-color: #3c85a5;
    }
    img.inactive {
      filter: grayscale(200%);
    }
    img {
      background-color: #f3f3f3;
      padding: 2px 3px;
      cursor: pointer;
      border-radius: 5px;
      padding: 4px;
      height: 25px;
      box-shadow: 0px 2px #e7e7e7;
    }
    img.eye {
      padding-top: 6px;
      padding-bottom: 6px;
    }
    &.constraint {
      img {
        width: 100%;
      }
      img:first-child {
        padding-left: 5px;
        padding-right: 5px;
      }
      img.eye {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }

  .custom-constraint {
    padding-top: 5px;
    margin-top: 5px;
    max-height: 100px;
    overflow: auto;
    .created-constraint {
      margin-top: 5px;
      border-radius: 3px;
      padding: 2px 5px;
      color: #ffffff;
      .label {
        white-space: nowrap;
        width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      img {
        float: right;
        margin-top: -13px;
        margin-right: 5px;
        width: 8px;
      }
    }
  }
  .option-selection {
    min-width: auto !important;
    min-height: auto !important;
    width: 100% !important;
    margin: 0px !important;
    padding: 5px !important;
    height: 25px !important;
    background-color: transparent;
    border: none !important;
    border-bottom: 2px solid #3d87ab !important;
    border-radius: 0;
    margin-top: 10px !important;
    &::placeholder {
      color: #3d87ab;
    }
    &.disabled {
      filter: grayscale(0.8);
    }
    i.dropdown.icon {
      &:before {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #3d87ab;
        content: "";
      }
    }
    .dropdown.icon {
      height: 25px !important;
      margin-top: -3px;
      right: 7px;
    }
    .item {
      padding: 3px !important;
    }
  }
  .dynamic-action-container {
    padding: 5px;
    background-color: #f4f4f4;
    margin-top: 10px;
    border-radius: 5px;
  }
  .selected-dynamic-action-container {
    max-height: 100px;
    overflow: auto;
    margin-top: 5px;
  }
  .selected-dynamic-action {
    border-radius: 3px;
    margin: 6px 0px;
    padding: 4px 5px !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    i {
      color: #7ba880;
      font-size: 12px !important;
      cursor: pointer;
    }
    label {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      margin-right: auto;
      position: relative;
      top: 2px;
    }
  }
  .selected-dynamic-action.forward {
    background-color: #dcfbe1;
    border: 1px solid #7ba880;
    i {
      color: #7ba880;
    }
  }
  .selected-dynamic-action.backward {
    background-color: #e9b3ae;
    border: 1px solid #d8827e;
    i {
      color: #d8827e;
    }
  }
  .selected-dynamic-action.save {
    background-color: #e2e2e2;
    border: 1px solid #c0c0c0;
    i {
      color: #c0c0c0;
    }
  }
  i.icon.close {
    &:before {
      position: absolute;
      margin-top: -4px;
      margin-left: -3px;
    }
  }
}
