.status-modal-container {
  border-radius: 5px;
  box-shadow: 0px 2px 6px #000000;
  width: 160px;
  padding: 5px;
  position: absolute;
  z-index: 14;
  background-color: #F3F3F3;
  input {
    display: block;
    position: relative;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  input[type=text] {
    background-color: #D4E0E6;
    border: #95BACC solid 1px;
    max-width: 150px;
    padding-left: 5px;
    border-radius: 3px;
    width: 100%;
    height: 25px;
    &::placeholder {
      font-style: italic !important;
      font-size: 13px;
    }
  }
  input[type=text].error {
    background-color: #ff8585 !important;
  }
  .checkbox {
    margin-top: 5px;
    label {
      &:before {
        border: #3D87AB 1px solid !important;
      }
    }
  }
  button {
    margin-top: 10px !important;
    background-color: #CF6256 !important;
    color: #FFFFFF !important;
    width: 100% !important;
  }
}