#doc-status {
  height: 100%;
  width: 100%;
  font-style: italic;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  .status-label {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
  }
}
