#autopopulate-field-modal {
  max-width: 500px !important;
  width: 500px !important;
  .field {
    > label {
      font-weight: 400;
    }
  }
  .content {
    .label-bottom {
      float: right;
      padding-top: 5px;
      strong {
        color: red;
      }
    }
    .label {
      strong {
        color: red;
      }
    }
  }
  div {
    > div {
      > div {
        > div.error {
          input {
            background-color: #ff8585 !important;
          }
        }
      }
    }
  }
  .custom-field-checkbox {
    margin-top: 20px;
    > div {
      > label {
        &:before {
          width: 35px !important;
          height: 35px !important;
          border: 1px solid #3d87ab !important;
          border-radius: 5px !important;
        }
        font-size: 18px !important;
        width: 225px !important;
        text-align: left !important;
        padding: 10px 0px 0px 50px !important;
      }
      > input {
        &:checked ~ label {
          &:before {
            width: 35px !important;
            height: 35px !important;
            border: 1px solid #3d87ab !important;
            border-radius: 5px !important;
          }
          &:after {
            width: 35px !important;
            height: 35px !important;
            font-size: 27px !important;
            margin: 10px 0px 0px 0px !important;
            color: #3d87ab !important;
          }
        }
      }
    }
  }
}
