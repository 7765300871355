@import "../../../../css/mixins";
#dt-group-popup {
  margin-top: 1px;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: unset;
  padding: unset;
  .menu {
    cursor: default;
    .item {
      position: relative;
      cursor: pointer;
      display: block;
      border: none;
      height: auto;
      text-align: left;
      border-top: none;
      line-height: 1em;
      color: rgba(0, 0, 0, 0.87);
      padding: 0.78571429rem 1.14285714rem !important;
      font-size: 1rem;
      text-transform: none;
      font-weight: 400;
      box-shadow: none;
    }
  }
}

.data-table-form-container {
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 30px;
  padding-bottom: 40px;
  padding-right: 0px;
  min-width: 800px;
  height: calc(100vh - 75px - 86px - 68px);
  .error-message-container {
    width: 35vw;
    margin: -10px 0px 20px 0px;
  }
  .attribute-content {
    padding-right: 30px;
  }
  .label-bottom {
    float: left !important;
    font-style: italic !important;
    font-size: 15px !important;
    margin-top: 15px;
    > strong {
      color: red !important;
    }
  }
}

.dropdown-popper-option {
  .item {
    text-align: center !important;
  }
}

#attributes-container {
  table-layout: auto;
  white-space: nowrap;
  border: 1px solid #3a85a8;
  border-radius: 10px !important;
  padding: 10px 15px !important;
  width: 100% !important;
  > thead {
    > tr {
      > th {
        font-weight: normal;
      }
    }
  }
  > tbody {
    &:before {
      content: "-";
      display: block;
      line-height: 10px;
      color: transparent;
    }
    > tr {
      > td.td-primary.td-checkbox {
        min-width: 30px;
        text-align: center;
        vertical-align: middle;
      }
      > td.td-primary {
        background-color: #f3f3f3 !important;
      }
      > td {
        > input {
          background-color: #f3f3f3 !important;
          border: unset !important;
          box-shadow: unset !important;
          color: #3d87ab !important;
        }
        > div.ui.fitted.checkbox {
          > label {
            font-size: 18px !important;
            text-align: center !important;
            &:before {
              border: 1px solid transparent !important;
            }
          }
          > input {
            &:checked ~ label {
              &:before {
                border: 1px solid transparent !important;
                width: 23px !important;
                height: 23px !important;
                font-size: 20px !important;
              }
              &:after {
                width: 23px !important;
                height: 23px !important;
                font-size: 20px !important;
                margin-top: 4px;
                color: #3d87ab !important;
              }
            }
          }
        }
        > div {
          > div.ui.selection.dropdown {
            margin-left: 0px !important;
            background-color: #f3f3f3;
            border: none !important;
            > div {
              background-color: #f3f3f3;
              border: none !important;
              > div {
                background-color: #f3f3f3;
                border: none !important;
                text-align: center;
                font-size: 17px;
                background-color: #ffffff;
              }
            }
            > div.text {
              width: inherit;
              overflow: hidden !important;
              white-space: nowrap !important;
              text-overflow: ellipsis !important;
              margin-top: 5px;
              margin-bottom: -3px;
            }
          }
          > div {
            > div.visible.menu.transition {
              box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3) !important;
              overflow: hidden;
              max-height: none !important;
              height: auto !important;
              > div.item {
                &:nth-child(even) {
                  background-color: #f3f3f3;
                }
                &:nth-child(odd) {
                  background: #fff;
                }
              }
            }
            > div {
              > div {
                > span {
                  color: #000;
                  font-weight: normal;
                }
              }
            }
          }
        }
        > #data-type-dropdown {
          > div.ui.disabled.selection.dropdown {
            color: #3d87ab !important;
          }
          > div {
            > i.dropdown.icon {
              color: #3d87ab !important;
              padding-top: 30px;
              &:before {
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 15px solid #3d87ab;
                content: "";
              }
            }
            > i.dropdown.icon.clear {
              &:before {
                content: "\f00d";
                width: 0;
                height: 0;
                border-left: 0px solid transparent;
                border-right: 0px solid transparent;
                border-top: 0px solid #3d87ab;
                position: absolute;
                top: 15px;
                right: 27px;
                font-size: large;
              }
            }
          }
          margin: 0 !important;
        }
        > #field-type-dropdown {
          > div {
            > div.visible.menu.transition {
              > div.item {
                &:last-child {
                  display: none;
                }
              }
            }
            > i.dropdown.icon {
              color: #3d87ab !important;
              padding-top: 27px;
              &:before {
                width: 0;
                height: 0;
                content: "";
              }
            }
            > div {
              &:first-child {
                background: transparent;
                background: transparent;
              }
            }
            > i.dropdown.icon.clear {
              &:before {
                content: "\f00d";
                width: 0;
                height: 0;
                border-left: 0px solid transparent;
                border-right: 0px solid transparent;
                border-top: 0px solid #3d87ab;
                position: absolute;
                top: 13px;
                right: 27px;
                font-size: large;
              }
            }
          }
          span {
            margin-left: 20px;
            color: #3a85a8;
          }
          margin: 0 !important;
          .error {
            div {
              color: #ffffff !important;
            }
            background-color: #ff8585 !important;
            color: #ffffff !important;
          }
        }
        > .action-icon {
          color: #3a85a8;
          text-align: center;
        }
        input.column-heading.error {
          background-color: #ff8585 !important;
          color: #ffffff !important;
          @include placeholder {
            color: #fff;
            opacity: 1;
          }
        }
        > button.ui.button.add-column-button {
          background-color: #fff !important;
          height: 55px;
          width: 100%;
          text-align: center;
          border: 1px dotted #3a85a8;
          border-radius: 5px;
          font-style: italic;
          color: #c3c3c3;
          font-size: 12pt;
          font-weight: normal;
        }
      }
      > td.td-icon-action {
        text-align: center;
        max-width: 40px;
      }
    }
  }
}

#database-form {
  height: calc(100vh - 75px - 86px - 165px);
  overflow: hidden auto;

  .dt-grouped-trigger-menu {
    min-height: 41px;
    background-color: #f3f3f3 !important;
    position: relative;
    cursor: text;
    padding: 5px 10px 5px 5px;
    margin-top: 10px;
    display: flex;

    .dropdown.icon {
      position: absolute;
      top: 40%;
      right: 10px;
    }
    .ui.label {
      background: transparent;
      padding: 7px 9px !important;
      border: 1px solid #3d87ab;
      margin: 0px 3px !important;
      font-size: 14px !important;
      border-radius: 5px !important;
      width: auto !important;
      font-weight: normal;
      height: 30px;
      cursor: grab;
    }
    i.delete.icon {
      &::before {
        color: #3d87ab;
        width: 21px;
        padding: 2px 4px;
        margin-left: 10px;
        height: 18px;
        font-size: 10pt;
      }
    }
    i.dropdown.icon.small {
      color: #3d87ab !important;
      padding-top: 10px;
      &:before {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #3d87ab;
        content: "";
      }
    }
    i.dropdown.icon.clear {
      cursor: pointer;
      &:before {
        content: "\f00d";
        width: 0;
        height: 0;
        color: #3d87ab;
        position: relative;
        top: -5px;
        right: -2px;
        font-size: 14px;
      }
    }
  }

  > div {
    > div {
      > label {
        font-weight: normal;
        margin: unset;
      }
      > div {
        > input {
          background-color: #f3f3f3 !important;
          border: unset !important;
          box-shadow: unset !important;
          margin: 10px 0px 0px 0px !important;
          max-width: 750px;
        }
        > a.ui.label {
          background: transparent;
          padding: 7px 9px !important;
          border: 1px solid #3d87ab;
          margin: 3px !important;
          font-size: 14px !important;
          border-radius: 5px !important;
          width: auto !important;
          font-weight: normal;
          height: 30px;
        }
        > a {
          > i.delete.icon {
            &::before {
              color: #3d87ab;
              width: 21px;
              padding: 2px 4px;
              margin-left: 10px;
              height: 18px;
              font-size: 10pt;
            }
          }
        }
        > i.dropdown.icon {
          color: #3d87ab !important;
          padding-top: 25px;
          &:before {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #3d87ab;
            content: "";
          }
        }
        > i.dropdown.icon.clear {
          &:before {
            content: "\f00d";
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            border-top: 0px solid #3d87ab;
            position: absolute;
            top: 13px;
            right: 23px;
          }
        }
      }
      > div.ui.selection.dropdown {
        margin: 10px 0px 0px 0px !important;
        background-color: #f3f3f3;
        border: none !important;
        color: #000 !important;
        min-width: 300px;
        max-width: 500px;
        width: auto;
        min-height: 40px !important;
        > div {
          background-color: #f3f3f3;
          border: none !important;
          color: #000 !important;
          min-width: 300px;
          max-width: 500px;
          width: auto;
          > div {
            background-color: #f3f3f3;
            border: none !important;
            color: #000 !important;
            min-width: 300px;
            max-width: 500px;
            width: auto;
            border-top: solid #fcfcfc 1px !important;
            overflow: hidden !important;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
          }
        }
        > div.text {
          width: inherit;
          overflow: hidden !important;
          white-space: nowrap !important;
          text-overflow: ellipsis !important;
          background: transparent;
        }
      }
    }
    > div.field {
      margin-left: 20px !important;
    }
    > div.four.wide.field {
      margin-top: 10px;
    }
    > div.ui.segment.borderless {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0;
      > button {
        min-width: 180px !important;
      }
    }
  }
  > div.ui.small.header {
    font-weight: 600;
    margin: 14px 0px;
  }
  > div.ui.medium.header {
    color: #3a85a8;
    margin-bottom: 10px;
  }
}
.datatable-config-main {
  display: grid !important;
  margin: 1.5em 0 0 0 !important;
  grid-template-columns: 50% auto;
  width: 78%;
}

.datatable-permission-container {
  display: grid;
  grid-template-columns: auto auto;
  margin: unset !important;
}

.datatable-config-container {
  margin-right: 10px;
  label {
    font-weight: normal !important;
  }
  .datatable-config-field {
    padding-right: 0px !important;
    padding-left: 25px;
    .datatable-config-enum {
      display: inline-block;
      white-space: nowrap;

      .ui.icon.trash {
        color: #3b86a8;
        cursor: pointer;
        vertical-align: bottom;
        margin-bottom: 10px;
        font-size: 20px;
        margin-left: 10px;
      }

      .new-config {
        color: #fff;
        background: #3b86a8;
        vertical-align: bottom;
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 11px;

        &:before {
          margin-left: -1px;
        }

        &.no-delete {
          margin-left: 10px;
        }
      }
    }
    div.ui.selection.dropdown {
      margin: 10px 0px 0px 0px !important;
      background-color: #f3f3f3;
      border: none !important;
      color: #000 !important;
      min-width: 300px;
      max-width: 80vw;
      width: auto;
      min-height: 40px !important;
      > div {
        background-color: #f3f3f3;
        border: none !important;
        color: #000 !important;
        min-width: 300px;
        max-width: 500px;
        width: auto;
        > div {
          background-color: #f3f3f3;
          border: none !important;
          color: #000 !important;
          min-width: 300px;
          max-width: 80vw;
          width: auto;
          border-top: solid #fcfcfc 1px !important;
          overflow: hidden !important;
          white-space: nowrap !important;
          text-overflow: ellipsis !important;
        }
      }
      > div.text {
        width: inherit;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        background: transparent;
      }
      i.dropdown.icon {
        color: #3d87ab !important;
        padding-top: 25px;
        &:before {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid #3d87ab;
          content: "";
        }
      }
      i.dropdown.icon.clear {
        &:before {
          content: "\f00d";
          width: 0;
          height: 0;
          border-left: 0px solid transparent;
          border-right: 0px solid transparent;
          border-top: 0px solid #3d87ab;
          position: absolute;
          top: 13px;
          right: 23px;
        }
      }
      a.ui.label {
        background: transparent;
        padding: 7px 10px !important;
        border: 1px solid #3d87ab;
        margin: 5px 3px 5px 3px !important;
        font-size: 15px !important;
        border-radius: 5px !important;
        width: auto !important;
        font-weight: normal;
        height: 30px;
      }
      a {
        > i.delete.icon {
          &::before {
            color: #3d87ab;
            width: 21px;
            padding: 2px 4px;
            margin-left: 10px;
            height: 18px;
            font-size: 9pt;
          }
        }
      }
    }
  }
}
.dt-action-button {
  float: right;
  margin-top: 17px;
  &:last-child {
    margin-right: 10px;
  }
  .ui.button {
    margin-right: 5px;
  }
}
