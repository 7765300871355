.string-field-modal {
  max-width: 500px !important;

  textarea,
  input {
    background-color: #f3f3f3 !important;
    border: unset !important;
    box-shadow: unset !important;
  }

  .field {
    label {
      font-size: 12pt !important;
      font-weight: unset !important;
      &.error {
        color: #9f3a38;
      }
    }

    &.error {
      input {
        background-color: #ff8585 !important;
      }
    }
  }

  .show-advance-settings {
    display: grid;
    grid-template-columns: 500px 460px;

    .string-normal-config {
      margin-right: 40px;
    }
  }

  .string-special-config {
    padding: 20px;
    border: 2px solid #a4b8c1;
    border-radius: 10px;

    .special-config-title {
      font-weight: 800;
      font-size: 15px;
    }

    .ui.checkbox {
      display: block;
      margin-left: 20px;
      margin-top: 5px;

      label {
        &:before {
          border-color: #3d87ab;
        }
        &:after {
          border-color: #3d87ab;
          color: #3d87ab;
        }
      }
    }

    .ui.radio.checkbox {
      display: block;
      margin-left: 0px;
      margin-bottom: 10px;
      margin-top: 20px;

      label {
        font-weight: 800;
        &:before {
          border-color: #3d87ab;
        }
        &:after {
          border-color: #3d87ab;
          background-color: #3d87ab;
          transform: scale(0.46666667);
        }
        span {
          font-weight: 400;
        }
      }
    }

    .ui.input {
      input {
        margin-top: 10px;
        margin-left: 45px;
        background-color: #f3f3f3;
        border: none;
      }
    }
  }
  .label-bottom {
    margin: 10px 5px;
    label {
      strong {
        color: red;
      }
    }
  }
  .string-action-container {
    div {
      div {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
    :first-child {
      #custom-field-checkbox {
        float: left;
      }
    }
    :nth-child(2) {
      display: inline-block;
    }
  }
}

.string-field-modal.with-advance-setting {
  max-width: 1000px !important;

  .string-action-container {
    display: grid;
    grid-template-columns: 500px 460px;
  }
}
