.company-form-modal {
  padding: 10px;
  width: 850px !important;
  margin: 0 auto !important;

  #form-modal-header {
    padding: 10px 20px;
  }

  .actions {
    padding: 0 !important;
    .ui.segment {
      padding: 10px 25px !important;
    }
  }

  .modal-header {
    padding: 12px 15px !important;
    height: 50px !important;
  }

  .upper-input-with-logo-container {
    display: grid;
    grid-template-columns: 180px auto;
  }

  .logo-background {
    opacity: 1;
    width: 160px;
    height: 145px;
  }

  .logo-alternative {
    background-image: url("../upload-icon.png");
    background-repeat: no-repeat;
    background-size: 90px;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.2;
    width: 160px;
    height: 145px;
  }

  .logo-container {
    width: 160px;
    height: 145px;
    background-color: #f3f3f3;
    border-radius: 3px;
    .progress-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .upload-logo {
      opacity: 0;
      z-index: 2;
      position: absolute;
      margin-top: -133px;
      margin-left: 11px;
      font-size: 12px;
      cursor: pointer;
      padding: 60px 37px;
    }
    &:hover {
      .upload-logo {
        opacity: 1 !important;
      }
      .logo-background {
        opacity: 0.3 !important;
      }
    }
  }

  .upper-input-container {
    width: 100%;
  }

  input {
    background-color: #f3f3f3 !important;
    border: none !important;
  }

  div.error input {
    background-color: #ff8585 !important;
  }

  .field {
    label {
      font-weight: 400 !important;
    }
  }

  .ui.form {
    .fields {
      margin: 0 -0.5em 0.9em;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  #company-dropdown-container {
    display: flex;
    .ui.selection.dropdown {
      width: 100%;
    }
    .ui.selection.dropdown,
    .ui.selection.dropdown div,
    .ui.selection.dropdown div div {
      background-color: #f3f3f3;
      border: none !important;
      color: #000 !important;
      margin: unset !important;
      line-height: 19px;
      width: 100%;
    }
    div {
      i.dropdown.icon {
        color: #39aec1 !important;
        padding-top: 25px;
        &::before {
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 12px solid #39aec1;
          content: "";
        }
        &.clear {
          &::before {
            content: "\f00d";
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            border-top: 0px solid #39aec1;
            position: absolute;
            top: 12px;
            right: 26px;
            font-size: 12pt !important;
          }
        }
      }
    }
  }

  #company-form-switch {
    right: 0;
    position: absolute;
    margin-top: -20px;
    margin-right: 35px;
    display: flex;
    align-items: center;
    label {
      margin-left: 10px;
    }
    div.field {
      margin-right: 15px;
    }
    div {
      div {
        input:checked ~ label:before {
          width: 46.5px;
          height: 20px;
          margin-top: 3px !important;
          margin-left: 2px !important;
        }
        input:checked ~ label:after {
          width: 15px;
          height: 15px;
          margin-top: 5px !important;
          margin-left: 1px !important;
        }
        label {
          &::before {
            width: 46.5px;
            height: 20px;
            margin-top: 3px !important;
          }
          &::after {
            width: 15px;
            height: 15px;
            margin-top: 5px !important;
            margin-left: 3px !important;
          }
        }
      }
    }
  }

  .container-col {
    .row {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .color-picker-container {
    background: #f3f3f3;
    border: 1px solid #dedddd;
    width: 100%;
    margin: 1em 1rem 0;

    .text-input-wrapper {
      width: 100%;
      height: 75%;
      .field {
        margin: auto;
        width: 90% !important;
        label {
          font-style: italic;
          margin-bottom: 10px;
        }
      }
    }

    .color-list,
    .color-picker-wrapper {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 0;
      padding-left: 0;
      .logo-container {
        width: 70%;
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        .file-logo {
          position: relative;
        }
        .upload-logo {
          top: 0px;
          margin: unset;
          padding: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
        }
        .upload-logo {
          width: 100%;
          height: 100%;
        }
      }
    }

    .color-list {
      height: 350px;
      .color-list-header {
        margin: 0.3em 0 0;
        padding: 0.3em 0.5em;
        .title {
          margin-bottom: 5px;
        }
        .thumbnail {
          width: 2.5em;
          height: 2em;
          border: 0.2em solid #fff;
          border-radius: 0.2em;
          margin-right: 0.5em;
          margin-left: 1.5em;
          display: inline-block;
          cursor: pointer;
        }
        .tab-list-container {
          position: relative;
          .tab-line {
            background-color: #dadada;
            border-radius: 2px;
            content: "";
            height: 2px;
            bottom: 0px;
            left: 0px;
            right: 5px;
            margin: 0px;
            position: absolute;
            width: inherit;
          }
          .tab-list {
            display: flex;
            margin-top: 5px;
            .tab {
              font-size: 14px;
              padding: 3px 7px;
              cursor: pointer;
              line-height: 1.8;
              margin: 0px;
              position: relative;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: max-content;
              color: #7d7d7d;
              font-weight: 500;
              .active {
                color: #000;
              }
              .underline {
                border-radius: 2px;
                content: "";
                height: 2px;
                bottom: 0px;
                left: 0px;
                right: 30px;
                margin: 0px;
                position: absolute;
                width: 100%;
                background: #000;
              }
            }
            .title {
              cursor: pointer;
              font-size: 14px;
              margin-right: 8px;
              margin-bottom: 0px;
              font-weight: 500;
              color: #9b9b9b;
              &.active {
                text-decoration: underline;
                color: #000;
              }
            }
          }
        }
      }
      .color-list-setting {
        height: 238px;
        overflow: auto;
      }

      ul {
        margin: 0.5em 0;
        padding: 0;
        list-style: none;

        li {
          padding: 0.3em 0.5em;
          display: flex;
          align-items: center;
          font-size: 1.15rem;

          &.active,
          &:hover {
            background: #ffffff;
            cursor: pointer;
          }

          .thumbnail {
            width: 2.5em;
            height: 2em;
            border: 0.2em solid #fff;
            border-radius: 0.2em;
            margin-left: 1em;
            margin-right: 0.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            &.text {
              border: 0.17em solid #e8e8e8 !important;
              &::after {
                content: "T";
                font-size: 18px;
                font-weight: 600;
              }
            }
            &.file {
              border: 0.17em solid #e8e8e8 !important;
              i.icon {
                height: unset;
                width: unset;
                margin: unset;
              }
            }
            .newButtonText {
              padding: 6px 4px;
              font-size: 12px;
              font-weight: bolder;
              display: flex;
            }
          }

          &.with-child {
            display: block;
            > span {
              font-weight: 500;
              font-size: 1.21rem;
            }
            &:hover {
              background: transparent;
              cursor: default;
            }
          }
        }
      }
    }

    .color-picker-wrapper {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
