#file-upload-modal-dimmer {
  display: flex !important;
  background-color: rgba(26, 26, 26, 0.75) !important;
  &.reports {
    z-index: 20 !important;
  }
}

#file-upload-modal {
  border-radius: unset !important;
  width: 800px;
  z-index: 200 !important;
  .header {
    background-color: #242424;
    border-radius: unset !important;
    color: #fff;
    font-size: 18px;
  }
  .header-menu {
    display: flex;
    background: #f5f5f5;
    border: 1px solid #ebebeb;
    .menu-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0px 5px;
      padding: 0px 10px;
      padding-bottom: 7px;
      cursor: pointer;
      font-size: 12px;
      margin-left: auto;
      margin-right: 5px;
      &.camera {
        margin-top: 2px;
      }
      &.home {
        svg {
          margin-bottom: 1px;
        }
      }
      &.selected {
        border-bottom: 4px solid #5e89a9;
      }
    }
  }
  .label-file-limit {
    margin-right: 30px;
    padding: 0px 10px;
    text-align: right;
    font-weight: 500;
    position: absolute;
    right: -25px;
    top: -25px;
    font-size: 13px;
  }
  .content {
    margin: 35px 40px 20px 40px;
    width: unset;
    height: 220px;
    border-radius: 12px;
    padding: unset;
    border: 2px dashed #fff;
    background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(to left, #f6c870, #acd4bc 49%, #5681a3) border-box;
    position: relative;
    .dropzone-main-container {
      height: 100%;
      width: 100%;
      &.signature {
        margin-top: 22px;
        .signature-image {
          img {
            width: 100%;
            height: 180px;
          }
        }
      }
      .dropzone-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        outline: unset;
        padding: 0px 5px;
        .file-icon-upload {
          width: 75px;
          height: 75px;
          margin-top: -20px;
        }
        .limit-error-label {
          font-style: italic;
          font-weight: 400;
          color: #e34b22;
          font-size: 13px;
        }
        .file-upload-label {
          font-style: italic;
          font-weight: 400;
          font-size: 13px;
        }
        .file-upload-btn {
          margin-top: 15px;
          padding: 5px 25px;
          color: #fff;
          cursor: pointer;
          background-image: linear-gradient(to bottom, #81a5b1, #6c99ae 49%, #5e89a9) !important;
        }
        .file-selected {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-bottom: 20px;
          .file-info {
            display: grid;
            grid-template-columns: 1fr;
            height: max-content;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 0px;
              cursor: pointer;
            }
          }
          .file-icon {
            width: 25px;
            height: 20px;
            margin-right: 15px;
          }
          .file-size {
            color: gray;
            font-size: 13px;
            font-style: italic;
            margin: unset;
            margin-left: 5px;
          }
          .icon.close {
            cursor: pointer;
            margin-left: 20px;
          }
          .file-name {
            font-size: 16px;
          }
        }
      }
    }
    .dropzone-main-container.uploading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .actions {
    display: flex;
    margin: 0px 27px 25px 27px;
    font-size: 15px;
    align-items: center;
    .btn-download-template {
      height: 20px;
      margin-right: auto;
      border-bottom: 2px solid #272727;
      color: #272727;
      cursor: pointer;
    }
    .btn-cancel {
      height: 20px;
      border-bottom: 2px solid #b8283f;
      color: #b8283f;
      margin-right: 20px;
      cursor: pointer;
    }
    .btn-validate {
      height: 20px;
      border-bottom: 2px solid #272727;
      color: #272727;
      margin-right: 20px;
      cursor: pointer;
    }
    .btn-upload {
      height: 20px;
      border-bottom: 2px solid #71c635;
      color: #71c635;
      cursor: pointer;
    }
    .btn-validate,
    .btn-upload {
      &.disabled {
        filter: opacity(0.5);
        cursor: not-allowed !important;
      }
    }
    .form-status-field {
      margin-top: -15px;
      margin-right: auto;
      &.error {
      }
    }
    .ui.selection.dropdown {
      background-size: 0 !important;
      color: #272727 !important;
      margin: unset !important;
      box-shadow: unset;
      border: none !important;
      border-bottom: 2px solid #272727 !important;
      border-radius: 0;
      width: 100%;
      font-size: 13px;
      .angle.down {
        cursor: pointer;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 1.21428571em;
        top: 0.6em;
        right: 1em;
        z-index: 3;
        margin: -0.78571429em;
        padding: 0.91666667em;
        opacity: 0.8;
        transition: opacity 0.1s ease;
        &.clear {
          font-size: 13px;
        }
      }
      .default.text {
        color: #272727;
      }
      .menu {
        z-index: 100 !important;
      }
      &.error {
        border-bottom: 2px solid #b8283f !important;
        background: none;
        color: #b8283f !important;
        .default.text {
          color: #b8283f !important;
        }
      }
    }
  }
  div {
    div.content {
      background: unset !important;
      border: unset !important;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
