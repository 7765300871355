.automation-form-container {
  .automation-form-detail {
    border-left: 2px solid #3a85a8;
    width: 100%;
    // padding: 0px 40px;
    overflow: hidden;
    margin-top: -40px;

    .header {
      display: grid;
      grid-template-columns: 40px 1fr;

      .svg-main {
        width: 30px;
        height: 30px;
        border: 1px solid #3a85a8;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.no-border {
          border: unset;
        }
      }

      .content {
        display: grid;
        grid-template-columns: 1fr;

        .title {
          font-weight: 700;
        }

        .description {
          font-size: 13px;
        }
      }
    }

    .add-condition {
      color: #3b85a8;
      padding-top: 20px;
      cursor: pointer;
    }

    .child-automation-container {
      border: 1px solid #000;
      border-radius: 5px;
      margin-top: 20px;

      .header {
        display: none !important;
      }

      .child-title {
        padding: 10px;
        border-bottom: 1px solid #000;
      }

      .automation-detail-content {
        padding: 10px;
        margin-top: 10px;
      }

      .automation-field-container {
        padding-left: 20px;
      }
    }

    .automation-detail-content {
      margin-top: 40px;
    }

    .option-widget-selection {
      display: grid;
      grid-template-columns: 220px 220px;

      .option-widget {
        display: grid;
        grid-template-columns: 30px 1fr;
        margin-top: 20px;
        cursor: pointer;

        .content {
          display: grid;
          grid-template-columns: 1fr;
          padding-right: 25px;
          height: 60px;
          position: relative;
          &.selected {
            color: #3a85a8;
          }

          .title {
            font-weight: 700;
            font-size: 11px;
            height: 15px;
          }
          .description {
            font-size: 10px;
            position: absolute;
            margin-top: 17px;
            width: 160px;
          }
        }
      }
    }

    .upload-button {
      img {
        width: 20px;
      }
    }
  }
}
