.form-builder-field-container {
  background-color: #ffffff;
  padding: 5px 10px 0px 10px;
  height: calc(100vh - 75px - 120px - 65px - 27px);
  .section-container {
    background: transparent;
    padding: 10px;
    overflow: auto;
    height: calc(100vh - 75px - 120px - 65px - 102px);
    .section {
      background: #ffffff;
      padding: 2px;
      border: 1px solid #3b86a8;
      border-radius: 5px;
      margin-left: 20px;
      position: relative;
      margin-bottom: 10px;
      .section-title {
        background: #e8e8e8;
        border-radius: 2px;
        padding: 5px;
        color: #3b86a8;
        font-weight: 700;
        cursor: grab;
        .settings-icon {
          position: relative;
          float: right;
          font-size: 17px !important;
        }
        .collapse-icon {
          position: relative;
          float: right;
          font-size: 19px !important;
        }
      }
      .section-body {
        background: #ffffff;
        border-radius: 2px;
        padding: 5px 10px;
      }
    }
    .section.plain {
      background: #ffffff;
      border: none;
      margin-left: 20px;
      position: relative;
      margin-bottom: 10px;
      height: max-content;
      padding-top: 0px;
    }
    .drag-section {
      position: absolute;
      margin-left: -16px;
      background: transparent;
      justify-content: center;
      height: 100%;
      align-items: center;
      display: flex;
      width: 10px;
      color: #3b86a8;
      font-size: 10px;
      cursor: grab;
    }
    .field-group {
      margin: 5px 0;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-row-gap: 10px;
      grid-column-gap: 10px;
    }
    .field-group.one {
      grid-template-columns: 1fr;
    }
    .field-group.two {
      grid-template-columns: 1fr 1fr;
    }
    .field-group.three {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .field-group.four {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .field-group.five {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .field-group.six {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    .field {
      background: #ffffff;
      border: 1px dashed #3b86a8;
      border-radius: 2px;
      padding: 5px;
      color: #3b86a8;
      max-width: 100%;
      display: grid;
      grid-template-columns: auto 1fr auto;
      &.constraint {
        background: #ecc4c4;
        img {
          background-color: transparent;
        }
      }
      > img {
        width: 20px;
        float: left;
        margin-right: 5px;
      }
      > .sequence-icon {
        width: 20px;
        float: left;
        margin-right: 5px;
        text-align: center;
      }
      > i {
        float: right;
        cursor: pointer;
      }
      > .field-label {
        cursor: pointer;
        color: #4183c4;
        position: relative;
        top: 1px;
        &.constraint {
          color: black;
        }
      }
      .field-action {
        position: relative;
        top: 1px;
        display: flex;
        height: fit-content;
        flex-direction: row-reverse;
        grid-gap: 3px;
      }
    }
    .drag-and-drop {
      background: #fcfbfc;
      padding: 5px;
      height: 80px;
      color: #3b86a8;
      font-style: italic;
      text-align: center;
      padding-top: 30px;
    }
  }
  .button-container {
    background: transparent;
    min-height: 65px;
    padding: 10px;
    position: relative;
    top: 8px;
    button {
      float: right;
      width: 120px;
      color: #ffffff;
      margin-right: 10px;
      font-size: 12px;
    }
  }
}
