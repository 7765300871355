@import "../css/mixins.scss";
$notificationOrangeColor: #d6823d;

#notification-modal-container {
  border-radius: 5px;
  box-sizing: border-box;
  width: 500px;
  padding: 5px;
  position: absolute;
  z-index: 25;
  background-color: #ffffff;
  padding: 20px 20px 40px;
  padding-right: 5px;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  height: 90%;
  * {
    -webkit-tap-highlight-color: transparent;
  }
  &.full-screen {
    left: 0 !important;
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    height: calc(100vh - 59px);
    z-index: 900 !important;
    .btn-mark {
      width: 11px !important;
      height: 11px !important;
      .mark {
        width: 7px !important;
        height: 7px !important;
        left: 2px !important;
        top: 2px !important;
      }
    }
    @include breakpoints(480px, 768px) {
      .title {
        padding: 0px 0px 15px;
      }
      .notification-list-container {
        scrollbar-width: none !important;
        &::-webkit-scrollbar {
          width: 4px;
        }
        .notification-group {
          padding: 20px 0px 20px;
          font-size: 14px;
          .mark-all-unread-button {
            padding: 2px 10px;
            font-weight: 500;
            font-size: 13px;
          }
        }
        .notification-item {
          padding: 8px 13px;
          .content-title {
            font-size: 13px;
            line-height: 0px;
          }
          .ui.radio.checkbox {
            min-width: 14px;
            label {
              &::after,
              &::before {
                width: 12px;
                height: 12px;
                top: 4px;
              }
            }
          }
          .content-detail {
            font-size: 12px;
          }
          .view-more {
            margin-top: 4px !important;
            .view-button {
              font-size: 13px;
            }
            span {
              font-size: 11px;
            }
          }
        }
      }
    }
    @include breakpoints(240px, 480px) {
      padding: 15px 20px;
      .loading {
        font-size: 18px !important;
      }
      .title {
        padding: 0px 0px 12px;
        * {
          font-size: 14px !important;
        }
      }
      .notification-filter {
        .notif-line {
          right: 7px;
        }
        .notif-tablist {
          .notif-tab {
            font-size: 13px;
            padding: 3px 7px;
          }
        }
      }
      .notification-list-container {
        scrollbar-width: none !important;
        padding-right: 0px;
        &::-webkit-scrollbar {
          width: 0px;
        }
        &.none {
          h4 {
            font-size: 14px !important;
          }
        }
        .notification-group {
          padding: 20px 0px 20px;
          font-size: 13px;
          .mark-all-unread-button {
            padding: 2px 7px;
            font-weight: 500;
            font-size: 12px;
          }
        }
        .notification-item {
          padding: 5px 10px;
          .content-title {
            font-size: 12px;
            line-height: 0px;
          }
          .ui.radio.checkbox {
            min-width: 10px;
            label {
              &::after,
              &::before {
                width: 10px;
                height: 10px;
                top: 5px;
              }
            }
          }
          .content-detail {
            font-size: 11px;
          }
          .view-more {
            margin-top: 3px !important;
            .view-button {
              font-size: 12px;
            }
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .title {
    color: #504e4e;
    padding: 0px 0px 20px;
  }
  .notification-filter {
    position: relative;
    .notif-tablist {
      display: flex;
      font-weight: 500;
    }
    .notif-line {
      background-color: #dadada;
      border-radius: 2px;
      content: "";
      height: 2px;
      bottom: 0px;
      left: 0px;
      right: 30px;
      margin: 0px;
      position: absolute;
      width: inherit;
    }
    .notif-tab {
      cursor: pointer;
      line-height: 1.8;
      margin: 0px;
      padding: 4px 8px;
      padding-bottom: 7px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: max-content;
      color: #7d7d7d;
      .underline {
        border-radius: 2px;
        content: "";
        height: 2px;
        bottom: 0px;
        left: 0px;
        right: 30px;
        margin: 0px;
        position: absolute;
        width: 100%;
      }
    }
  }
  .loading {
    text-align: center;
    font-size: 20px;
    position: absolute;
    width: 90%;
    padding: 10px;
    margin-left: 10px;
    &.top {
      top: 110px;
    }
    &.bottom {
      bottom: 0px;
      background: #fff;
    }
    .spinner {
      margin-left: -15px;
    }
  }
  .notification-list-container {
    height: calc(100vh - 180px - 40px);
    overflow: auto;
    padding-right: 15px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &.none {
      color: #504e4e;
      text-align: center;
      margin: auto 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .notification-group {
      color: $notificationOrangeColor;
      font-weight: 700;
      padding: 20px 0px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mark-all-unread-button {
        float: right;
        margin-right: 10px;
        background-color: #d6d6d6;
        color: #696767;
        padding: 3px 9px;
        border-radius: 3px;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .notification-no-more-to-load {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 0px 30px;
      p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 0px;
        width: 225px;
        text-align: center;
      }
      .notification-flag {
        width: 25px;
      }
    }
    .notification-item {
      padding: 10px;
      display: grid;
      grid-template-columns: 35px 1fr;

      .user-info-avatar {
        width: fit-content;
        .user-photo.default {
          border: 1px solid #000;
          svg {
            width: 21px;
            height: 20px;
          }
        }
        .user-photo.default.user-svg {
          border-radius: 500rem;
          margin-right: 0.25em;
          width: 23px;
          height: 23px;
        }
      }

      &:hover {
        background-color: #f3f3f3;
        .content-container {
          .content-action {
            .img-close {
              opacity: 1;
            }
          }
        }
      }

      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }

      .bot-icon {
        background-color: black;
        padding: 5px;
      }

      .user-name-icon {
        width: 25px;
        height: 25px;
        background-color: #418399;
        border-radius: 50%;
        text-align: center;
        padding: 3px;
        color: #ffffff;
        span {
          margin-top: 10px;
          font-size: 12px;
        }
      }

      .content-container {
        &:hover {
          .content-action {
            .img-close {
              opacity: 1;
            }
          }
        }
        .content-action {
          float: right;
          display: flex;
          align-items: center;
          .img-close {
            width: 13px;
            height: 13px;
            position: relative;
            padding: 2px;
            border-radius: 2px !important;
            opacity: 0;
            margin-right: 5px;
            &:hover {
              background: #dadada;
              cursor: pointer;
            }
          }
          .btn-mark {
            width: 15px;
            height: 15px;
            background: #e7e7e7;
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            .mark {
              width: 9px;
              height: 9px;
              position: absolute;
              border-radius: 50%;
              left: 3px;
              top: 3px;
            }
          }
        }
        .content-title {
          font-weight: 500;
        }
        .content-detail {
          color: #575858;
          a {
            color: #575858;
            &:hover {
              text-decoration: underline;
            }
          }
          .more {
            font-size: 13px;
            cursor: pointer;
          }
          .secondline-ellipsis {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .view-more {
          margin-top: 8px;
          color: #8c8888;
          &.noView {
            display: flex;
            align-items: center;
          }
          .view-button {
            border: 1px solid $notificationOrangeColor;
            border-radius: 5px;
            padding: 2px 8px;
            color: $notificationOrangeColor;
            cursor: pointer;

            &:hover {
              background-color: $notificationOrangeColor;
              color: #ffffff;
            }
          }
        }
        .ui.radio.checkbox {
          cursor: pointer;
          color: #e7e7e7;
          margin-left: 5px;

          label {
            &:before {
              border-color: transparent;
              background: #e7e7e7;
            }
            &:after {
              background-color: #68bc42;
            }
          }
        }
      }
    }
  }
}
