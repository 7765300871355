.role-table {
  border-collapse: collapse;
  table-layout: auto;
  white-space: nowrap;
  text-align: center;
  .bg-light-blue {
    background-color: #7aa0b3;
  }
  .bg-blue {
    background-color: #3a85a8 !important;
  }
  .bg-gray {
    background-color: #e2e2e2 !important;
  }
  .bg-dark-gray {
    background-color: #c0c0c0 !important;
  }
  .bg-orange {
    background-color: #f39c52 !important;
  }
  .bg-red {
    background-color: #d16357 !important;
  }
  .bg-green {
    background-color: #5eaa69 !important;
  }
  .text-blue {
    color: #3a85a8 !important;
  }
  .text-white {
    color: #ffffff !important;
  }
  .dark {
    color: #5e5e5e !important;
  }
  .light {
    color: #ffff !important;
  }
  th,
  td {
    border-left: 2px solid #dbeff5;
    border-bottom: 2px solid #2996b2;
    &:first-child {
      border-left: none;
      width: 200px;
      min-width: 200px;
      max-width: 200px;
      word-break: break-all;
      white-space: pre-wrap !important;
    }
    &:last-child {
      border-left: none;
      border-bottom: none;
      padding-right: 10px;
    }
  }
  > tbody {
    > tr {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      > td {
        background-color: #f3f3f3;
        position: relative;
        width: max-content;
        padding: 0px 2px;
        min-width: 200px;
        &:last-child {
          position: sticky;
          right: 0;
          top: 0;
          background-color: #fff;
          min-width: 30px;
          max-width: 30px;
          width: 30px;
        }
        &:first-child {
          padding: 5px;
        }
        > div {
          > div.ui.selection.dropdown {
            background-color: transparent !important;
            border: 1px solid transparent !important;
            width: 100% !important;
            > div {
              background-color: #f3f3f3;
              border: none !important;
              color: #3d87ab !important;
              margin: unset !important;
              > div {
                background-color: #f3f3f3;
                border: none !important;
                color: #3d87ab !important;
                margin: unset !important;
              }
            }
            > i.dropdown.icon {
              &:before {
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 12px solid #3d87ab;
                content: "";
              }
              color: #3d87ab !important;
              padding-top: 25px;
            }
            > i.dropdown.icon.clear {
              &:before {
                content: "\f00d";
                width: 0;
                height: 0;
                border-left: 0px solid transparent;
                border-right: 0px solid transparent;
                border-top: 0px solid #3d87ab;
                position: absolute;
                top: 12px;
                right: 26px;
                font-size: 12pt !important;
              }
            }
          }
        }
        .role-constraint-indicator-check {
          position: absolute;
          left: 10px;
          top: 10px;
          label {
            &:before {
              border: #3a85a8 solid 1px;
            }
            &:hover {
              &:before {
                border: #3a85a8 solid 1px;
              }
            }
          }
          input {
            &:checked {
              ~ label {
                &:before {
                  border: #3a85a8 solid 1px;
                }
                &:after {
                  color: #3a85a8;
                }
              }
            }
          }
        }
        .group-role-list {
          display: flex;
          flex-direction: column;
          &.expand {
            .role-name {
              padding-top: 20px;
            }
          }
          .expand-group {
            margin-left: 8px;
            font-size: 16px;
            cursor: pointer;
            position: relative;
            top: 1px;
          }
          .list-container {
            margin: 23px 0px;
            display: flex;
            flex-direction: column;
            color: rgb(61, 135, 171);
            font-weight: bold;
            .list {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin: 2px 15px;
            }
          }
        }
      }
      &:last-child {
        > td {
          background-color: transparent;
          > i.plus.icon {
            color: #fff;
            background: #3d87ab;
            font-size: 15pt;
            margin: 5px;
          }
        }
      }
      &:nth-child(even) {
        td {
          background: #f9f9f9;
          &:last-child {
            background: #fff;
          }
        }
      }
      &:nth-child(odd) {
        td {
          background: #fff;
        }
      }
    }
  }
  > thead {
    > tr {
      height: 40px;
      color: #fff;
      > th {
        &:first-child {
          > div {
            > div.header-image {
              display: none;
            }
          }
          > div.header-container {
            > div.header-label {
              text-align: center;
            }
          }
        }
        position: sticky;
        top: 0;
        z-index: 2;
        &:last-child {
          position: sticky;
          right: 0;
          text-align: center;
          background-color: #fff;
          border-color: #fff;
          width: 30px;
          padding-left: 5px;
        }
        > div.header-container {
          display: flex;
          > div.header-label {
            text-align: center;
            font-size: 12pt;
            margin: 12px;
          }
          > div.header-image {
            margin-top: 10px;
            margin-left: 10px;
          }
          > div {
            flex-basis: 100%;
          }
        }
      }
    }
  }
}

.role-table-container {
  max-width: 100%;
  margin: 10px 20px;
  .button-container {
    float: right;
    margin-top: 10px;
    button {
      float: right;
      width: 120px;
      color: #ffffff;
      margin-right: 10px;
      font-size: 12px;
    }
  }
}

.role-table-parent-container {
  overflow: auto;
  max-width: 100%;
  margin: 10px 0px;
  height: calc(100vh - 75px - 120px - 65px - 60px);
}

#action-image-group {
  background-color: #ebf5f8;
  height: 30px !important;
  min-width: max-content;
  > i.ellipsis.vertical {
    position: relative;
    float: right;
    top: 5px;
    font-size: 15px;
    padding-top: 2px;
  }
  > .access-constraint {
    height: 18px;
    margin: 8px 5px;
    float: left;
    padding-left: 5px;
    border-left: 2px solid #3d87ab;
  }
  > span {
    width: 18px;
    height: 18px;
    background-color: #000;
    border-radius: 100%;
    margin: 8px 3px;
    float: left;
  }
}

#action-button-group {
  width: 100%;
  background-color: #fff;
  margin-top: 5px;
  text-align: left;
  .action-item {
    border-radius: 5px;
    padding: 4px 0 1px 5px;
    margin: 8px 6px;
    color: #000;
    &.forward {
      background: #ddf9e1;
      color: #000;
      border: 1px solid #a5c3a8;
    }
    &.backward {
      background: #e9b3ad;
      border: 1px solid #d07f7a;
    }
    &.save {
      background: #e2e2e2;
      border: 1px solid #c0c0c0;
    }
  }
}

#action-main-container {
  display: grid;
}
