#date-field-modal {
  width: 700px;

  .content {
    max-height: calc(100vh - 75px - 120px - 65px - 18px);
    overflow: auto;

    input,
    textarea {
      background-color: #f3f3f3;
      border: none;
    }
  }

  .date-checkbox-container {
    .checkbox-container {
      margin-bottom: 15px;
      &:first-child {
        margin-top: 20px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .date-properties-container {
    border-radius: 3px;
    border: 1px solid #3d87ab;
    padding: 20px;

    .title {
      color: #3d87ab;
      margin-bottom: 15px;

      i {
        font-size: 15px;
        margin-left: 10px;
      }
    }

    .selection-grid-container {
      display: grid;
      grid-template-columns: 80px 200px 80px 80px 120px;
      column-gap: 10px;

      > span:nth-child(1) {
        margin-top: 20px;
        font-size: 12px;
      }

      > .field-container {
        > div {
          > span {
            padding-right: 10px;
            padding-left: 10px;
            border-bottom: 1px solid black;
            font-size: 12px;
            &.active {
              color: #3d87ab;
              border-bottom: 1px solid #3d87ab;
            }
          }
        }
        .ui.selection.dropdown {
          min-width: 200px;
          margin-top: 10px !important;
          margin-left: 0px !important;
        }
      }

      .count-type {
        min-width: 80px;
        height: 30px;
        margin-top: 35px !important;
        margin-left: 0px !important;
      }

      .no-of-days {
        min-width: 80px;
        height: 45px;
        margin-top: 35px !important;
        padding: 10px;
      }

      .date-part {
        min-width: 80px;
        height: 30px;
        margin-top: 35px !important;
        margin-left: 0px !important;
      }
    }
  }

  .date-action-container {
    div {
      div {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
    .label-bottom {
      font-size: 14px !important;
      text-align: end;
      margin-bottom: 10px;
      margin-right: 8px;
      label {
        strong {
          color: red;
        }
      }
    }
  }
}
