.month-view-container {
  .rbc-calendar {
    padding-right: 20px;
    .rbc-month-view {
      border: unset !important;
      height: max-content;
      padding: 8px;
      background: #ededed !important;
      .rbc-month-header {
        display: none !important;
      }
      .rbc-month-row {
        border: none !important;
        background: none !important;
        min-height: 210px;
        max-height: 210px;
        .rbc-event {
          border: none !important;
          background: none !important;
          border-radius: 0px !important;
          padding: 0px !important;
          .custom-show-more-label {
            cursor: pointer;
            z-index: 4;
            font-weight: bold;
            font-size: 84%;
            height: auto;
            line-height: normal;
            white-space: nowrap;
            margin-left: 5px;
            color: #434343;
          }
        }
        .rbc-day-bg {
          background-color: #ffffff !important;
          border: unset !important;
          margin: 3px;
        }
        .rbc-off-range-bg {
          background-color: #c7c7c7 !important;
        }
        .rbc-date-cell {
          top: 155px !important;
          position: relative !important;
          font-weight: 500;
          color: #7a7a7a !important;
          display: none;
          margin-top: 8px;
          .rbc-date {
            width: max-content;
            margin-right: 7px;
            height: max-content;
            display: flex;
            margin-left: auto;
            padding: 7px 9px;
          }
        }
        .rbc-row-segment {
          padding: 3px 4px 1px 5px !important;
          border-radius: 0px !important;
          min-height: 20px;
        }
        .rbc-row {
          min-height: 20px;
        }
      }
    }
  }
  .user-info-avatar {
    width: fit-content;
    .user-photo.default {
      border: 1px solid white;
      svg {
        width: 16px;
        height: 15px;
      }
    }
    .user-photo.default.user-svg {
      border-radius: 500rem;
      margin-right: 0.25em;
      width: 18px;
      height: 18px;
    }
  }
}
