#automation-send-email {
  .tox-autocompleter {
    border: 1px solid #ccc !important;
    border-radius: 3px;
    background-color: #fff !important;
    overflow: unset !important;
    .tox-collection__group {
      overflow: hidden auto;
      max-height: 304px;
      max-width: 200px;
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      .tox-collection__item {
        padding: 7px 10px !important;
        .tox-collection__item-label {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}

.send-sms-action {
  .header {
    margin-bottom: 20px;
  }
  .automation-field-container {
    margin-top: 5px;
  }
}

.send-sms-action,
.send-email-action {
  .automation-field-container {
    padding-top: unset !important;
  }
  .ui.button.floating.labeled.dropdown.icon {
    width: 100%;
    border: #3a85a8 1px solid;
    background-color: #ffffff;
    border-radius: 5px;
    color: #3a85a8;
    padding-left: 10px !important;

    > .text {
      font-weight: 500;
    }

    > .dropdown.icon {
      right: 0;
      left: unset;
      background-color: transparent;

      &:before {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 12px solid #3d87ab;
        content: "";
        top: 18px;
        right: 10px;
        position: absolute;
      }
    }

    .option-title {
      color: #a3a3a3;
      font-size: 13px;
      font-weight: 500;
      padding: 10px 10px 0px;
    }

    > .menu {
      margin-top: 1px !important;
      max-height: unset !important;
    }

    .option-list {
      padding: 10px 15px;
      border-top: none;
      box-shadow: none !important;
      .ui.fitted.checkbox {
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;

        label {
          padding-left: 25px !important;
          width: 250px;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #000000;
          font-weight: 500;

          &:before {
            border-color: #3a85a8;
          }
          &:after {
            color: #3a85a8;
          }
        }
      }
    }
    input,
    .dropdown {
      padding-left: 10px !important;
    }
  }
  .select-field-container {
    width: 100%;
    .close-button {
      float: right;
      margin-top: 10px;
      cursor: pointer;
      font-weight: 500;
    }
    .dropdown-text-cover {
      position: absolute;
      color: white;
      background-color: transparent;
      display: block;
      width: 100%;
      padding: 13px;
      border-radius: 3px;
      margin-top: -40px;
      z-index: 10;
      cursor: pointer;
      > label {
        font-weight: 700;
        width: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .ui.button.floating.labeled.dropdown.icon {
      background-color: #3a85a8;
      max-height: 36px;
    }
    .button-container {
      z-index: 21;
    }
    .option-list {
      .option-title {
        color: #6b6868 !important;
        font-weight: 700 !important;
        padding: 5px 0px !important;
        margin-top: 5px;
      }
    }
    .btn-action-collapse {
      &.open {
        background-color: #3a85a8 !important;
        i {
          color: white;
        }
      }
    }
    input {
      min-height: 38px;
    }
    input.search {
      min-height: unset !important;
    }
    .dropdown {
      width: 100%;
    }
  }
  .automation-field {
    width: 100%;
    &.email-body {
      min-height: 340px;
      max-width: 95%;
      .tox-tinymce {
        border-color: #3a85a8;
        border-radius: 5px;
      }
    }
    &.input {
      max-width: 95%;
      input {
        min-height: 38px;
        margin-top: 5px;
        padding: 7px;
      }
    }
    textarea {
      min-height: 150px;
      padding: 7px;
    }
    .with-left-icon {
      position: relative;
      display: flex;
      width: 100%;
      .dropdown {
        margin-right: unset !important;
        padding-left: 30px !important;
      }
      input {
        line-height: 1.21428571em;
        padding: 0.67857143em 2.1em 0.67857143em 1em;
        padding-left: 30px !important;
        &:focus {
          outline: unset;
        }
        &::placeholder {
          color: rgba(191, 191, 191, 0.87);
        }
      }
      i.dropdown.icon {
        right: 0.3em !important;
        &:before {
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 11px solid #3d87ab;
          content: "";
          top: 13px;
          right: 10px;
          position: absolute;
        }
      }
      i.dropdown.icon.clear {
        &:before {
          content: "\f00d";
          width: 0;
          height: 0;
          border-left: 0px solid transparent;
          border-right: 0px solid transparent;
          border-top: 0px solid #3d87ab;
          position: absolute;
          top: 12px;
          right: 23px;
        }
      }
      i.at.icon {
        position: absolute;
        font-size: 16px;
        top: 8px;
        left: 7px;
        color: #737373;
      }
      &.attach-email {
        margin: 5px 0px;
        .upload-button {
          border: #3b85a8 1px solid !important;
          border-radius: 5px;
        }
        .field-info {
          display: none;
        }
        pre {
          right: 0px;
          top: 6px;
          color: #ee2929 !important;
          background-color: unset !important;
          font-size: 11px;
          position: absolute;
        }
        ul {
          li {
            span {
              grid-template-columns: 1fr;
              height: max-content;
              grid-column-gap: 5px;
              img {
                max-width: 23px;
                max-height: 35px;
              }
              p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 0px;
                margin-top: 3px;
                pointer-events: none;
              }
              .file-size {
                display: none;
              }
              .file-name {
                max-width: 250px;
              }
            }
          }
        }
      }
    }
  }
  .with-action-btn {
    display: grid;
    grid-template-columns: 77% 40px 21px 25px;
    grid-gap: 5px;
    align-items: center;
    &.no-padding-top {
      padding-top: 0px !important;
      .button-container {
        margin-top: -10px;
        &.delete {
          margin-top: -11px;
        }
      }
    }
    .button-container {
      margin-top: 10px;
      .ui.button {
        background: #e1e1e1;
      }
      &.delete {
        font-size: 20px !important;
        text-align: center;
        cursor: pointer;
        color: #3b86a8;
      }
      &.add {
        cursor: pointer;
        .new-email {
          color: #fff;
          background: #3b86a8;
          padding: 4px 0 !important;
          width: 1.65em !important;
          height: 1.6em !important;
          &::before {
            font-size: 11px;
          }
        }
      }
    }
  }
}
