.user-roles-info {
	height: 290px;
	.user-roles-header {
		font-size: 18px;
		font-weight: 900;
		padding: 0px 10px;
	}
	.user-roles-header-line {
		margin-top: 10px;
		border-image: linear-gradient(to right, rgb(255, 255, 255) -15%, rgb(189, 189, 189) 50%, rgb(255, 255, 255) 113%) !important;
		border-image-slice: 1 !important;
		border-bottom: 2px solid transparent !important;
	}
	.user-roles-info-list {
		margin-top: 25px;
		display: flex;
		flex-direction: row;
		max-width: 1495px;
		overflow-x: auto;
		height: 250px;
		.user-roles-bp-container {
			min-width: 400px;
			max-width: 400px;
			background-color: #e9e9e9;
			margin: 0px 20px;
			.bp-header-color {
				background-color: #dc9555;
				height: 8px;
			}
			.bp-header {
				justify-content: flex-start;
				padding: 0px 20px;
				height: 50px;
				.bp-collapse-icon {
					color: #000;
					margin-left: auto;
				}
			}
			.bp-header-line {
				border-image: linear-gradient(to right, #e9e9e9 10%, #e2bc95 50%, #e9e9e9 90%) !important;
				border-image-slice: 1 !important;
				border-bottom: 2px solid transparent !important;
			}
			.bp-header-icon {
				width: 35px;
				height: 35px;
				margin-right: 20px;
			}
			.bp-header-label {
				font-weight: 700;
				font-size: 16px;
			}
			.bp-content {
				padding: 15px 20px;
				.bp-collapse-icon {
					color: #000;
					float: right;
					bottom: 20px;
					position: relative;
				}
				.bp-content-role {
					text-align: center;
					margin: unset;
					font-weight: 500;
				}
			}
			.bp-content-role {
				.bp-content-action {
					margin-left: 20px;
					font-weight: normal;
				}
			}
		}
	}
}
