.forgot-password-form-input-container {
  text-align: left !important;
}

.reset-password-container {
  width: 100%;
  height: 100%;
  .background {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    position: fixed;
  }
}
