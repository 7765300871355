.user-form-modal {
  height: 600px !important;
  max-width: 600px !important;
  .content {
    height: 430px !important;
    overflow: auto;
  }
  .create-user-bottom {
    min-height: 100px;
    overflow: auto;
  }
  .cancel-btn {
    min-width: 30px !important;
    padding: 10px 30px;
  }
  .create-add-new-btn {
    min-width: 30px !important;
    padding: 10px 30px;
  }
  .create-btn {
    min-width: 30px !important;
  }
  #message-container {
    margin: -21px 0px 30px 0px !important;
    > div {
      width: 100% !important;
    }
  }
  .react-tel-input {
    .phone-input-field {
      padding-left: 50px !important;
      width: 100% !important;
      border: unset !important;
      background-color: #f3f3f3 !important;
    }
    .country-list {
      width: 250px;
    }
    .flag-dropdown {
      width: 43px;
      .search-box {
        width: 96% !important;
        &::placeholder {
          color: #666669;
        }
      }
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
    input {
      background-color: #f3f3f3 !important;
      border: none !important;
    }
    input.error {
      background-color: #ff8585 !important;
    }
  }
}
.user-form-label-bottom {
  float: left !important;
  font-style: italic !important;
  font-size: 10px !important;
  margin-top: 12px;
  > strong {
    color: red !important;
  }
}
#user-form {
  > div {
    > div {
      > label {
        font-weight: normal !important;
      }
      > div {
        > input {
          background-color: #f3f3f3;
          border: none;
          background-color: #f3f3f3;
          border: none;
        }
        > i.dropdown.icon {
          color: #3d87ab !important;
          padding-top: 24px;
          &:before {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #3d87ab;
            content: "";
          }
        }
        > i.dropdown.icon.clear {
          &:before {
            content: "\f00d";
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            border-top: 0px solid #3d87ab;
            position: absolute;
            top: 12px;
            right: 23px;
          }
        }
        > div {
          div.item {
            &:hover {
              background-color: #9b9b9b !important;
            }
          }
        }
      }
      > label.error {
        color: #9f3a38;
      }
      > div.error {
        > input {
          background-color: #ff8585 !important;
        }
      }
      > .dropdown.error {
        background-color: #ff8585 !important;
      }
      > div.ui.selection.dropdown {
        margin-left: 0px !important;
        background-color: #f3f3f3;
        border: none !important;
        color: #000 !important;
        min-height: unset !important;
        > div {
          background-color: #f3f3f3;
          border: none !important;
          color: #000 !important;
          > div {
            background-color: #f3f3f3;
            border: none !important;
            color: #000 !important;
            border-top: solid #fcfcfc 1px !important;
            overflow: hidden !important;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
          }
        }
        > div.text {
          width: inherit;
          overflow: hidden !important;
          white-space: nowrap !important;
          text-overflow: ellipsis !important;
          background-color: transparent !important;
        }
      }
    }
    > div.error {
      > .ui.multiple.selection.dropdown {
        background-color: #ff8585 !important;
      }
    }
  }
  .equal {
    &.width {
      &.fields {
        .upload-button {
          img {
            max-height: 30px;
            max-width: 35px;
          }
        }
        .field-label {
          &.error {
            background-color: unset;
          }
        }
        ul {
          li {
            span {
              p {
                margin-bottom: 0;
              }
            }
            img {
              max-width: 30px;
              max-height: 25px;
            }
          }
        }
      }
    }
  }
}
#user-form-switch {
  right: 0;
  position: absolute;
  margin-top: -30px;
  margin-right: -18px;
  > div {
    > div {
      > label {
        margin-left: -33px;
        margin-top: -14px;
        &:before {
          width: 46.5px;
          height: 20px;
          margin-top: 16px !important;
        }
        &:after {
          width: 15px;
          height: 15px;
          margin-top: 18px !important;
          margin-left: 3px !important;
        }
      }
      > input {
        &:checked ~ label {
          &:before {
            width: 46.5px;
            height: 20px;
            margin-top: 16px !important;
            margin-left: 2px !important;
          }
          &:after {
            width: 15px;
            height: 15px;
            margin-top: 18px !important;
            margin-left: 1px !important;
          }
        }
      }
    }
  }
}
.ui.modal {
  > .actions {
    background: #fff;
  }
}
