.bp-portal-submenu {
  .content-component {
    background-color: #fbfbfb;
    padding: 20px;
    overflow: auto;

    .drop-area {
      border: 3px dashed #e0e0e0;
      background-color: #f4f4f4;
      padding: 20px;
      text-align: center;
      color: #a0a0a1;
      font-size: 12px;
      margin-top: 20px;
    }

    .section-container {
      background-color: #ffffff;
      display: grid;
      grid-template-columns: 40px 1fr;
      column-gap: 5px;
      border: 1px solid #e7e7e7;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      margin-bottom: 20px;

      .section-drag {
        background: #f8f8f8;
        justify-content: center;
        height: 100%;
        align-items: center;
        display: flex;
        color: #797979;
        font-size: 10px;
        cursor: grab;
        border-right: 1px solid #e6e6e6;
        filter: grayscale(100%);

        img {
          width: 15px;
          height: 15px;
        }
      }
      .content {
        padding: 5px 5px 5px 0px;
      }

      .section-header {
        background-color: #eef1f2;
        color: #69aab7;
        padding: 15px 0px 15px 25px;
        height: 53px;

        label {
          font-weight: 700;
          font-size: 15px;
        }

        .ui.icon.cog {
          float: right;
          height: 40px;
          width: 40px;
          font-size: 18px;
          margin-top: -7px;
          padding: 5px;
          cursor: pointer;
        }

        .ui.icon.chevron {
          font-size: 18px;
          float: right;
          height: 53px;
          width: 40px;
          margin-top: -15px;
          margin-right: 0px;
          padding-top: 18px;
          background-color: #ffffff;
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }

    .section-plain-container {
      display: grid;
      grid-template-columns: 50px 1fr;
      margin: 10px;
      background: #f8f8f8;
      margin-top: 20px;

      .section-plain-drag {
        background: transparent;
        justify-content: center;
        height: 100%;
        align-items: center;
        display: flex;
        color: #b3b8bb;
        font-size: 10px;
        cursor: grab;
        height: 65px;
        img {
          width: 15px;
          height: 15px;
          filter: grayscale(100%);
        }
      }
    }

    .field-container {
      display: grid;
      column-gap: 20px;
      background-color: #f8f8f8;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-right: 20px;

      &.one {
        grid-template-columns: 1fr;
      }
      &.two {
        grid-template-columns: 1fr 1fr;
      }
      &.three {
        grid-template-columns: 1fr 1fr 1fr;
      }
      &.four {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      .field {
        border: 1px solid #d1d9dd;
        background-color: white;
        padding: 10px;
        > img.field-icon {
          display: inline;
        }
        > span {
          display: inline;
          margin-left: 10px;
          color: #797979;
        }
        > .delete-btn {
          float: right;
          height: 18px;
          margin-top: 3px;
        }
        > .unlink-btn {
          float: right;
          height: 18px;
          margin-top: 3px;
          margin-right: 10px;
        }
      }
    }
  }
}
