#table-field-modal {
  max-width: 980px !important;
  .column-field-selection {
    input.search {
      padding-left: 10px;
    }
    .visible.menu {
      max-height: 200px;
    }
  }
  .type-section.disabled {
    pointer-events: none;
    opacity: 0.45;
  }
  .default-value {
    height: 10px;
    &.disabled {
      pointer-events: none;
    }
    label {
      margin: 0px 0px 0px 35px;
      &:before,
      &:after {
        margin-left: -25px;
        border-color: #3c85a8;
        color: #3c85a8;
      }
    }
  }
  .table-type-selection {
    .title {
      margin-bottom: 12px;
    }
    input[type="radio"] {
      min-width: 20px;
      max-width: 20px;
      margin-right: 5px;
    }
    img {
      width: 80px;
      height: 60px;
      margin-right: 10px;
    }
    .default-value {
      height: 10px;
      &:after {
        content: "";
      }
    }
    .table-checkbox-config {
      display: grid;
      margin-top: 5px;
      .checkbox {
        margin-bottom: 5px;
      }
    }
  }
  .type-selection {
    width: 150px;
    .selection.dropdown {
      width: 100%;
      display: inline-block;
      min-height: 30px;
      max-height: 30px;
      padding: 5px 10px;
      margin-left: 0px !important;
      margin-right: 10px !important;
      color: #000000 !important;
      border: 1px solid #f3f3f3 !important;
      margin: 0px 5px 0px 5px !important;
      background-color: #ffffff;
    }
    .dropdown {
      i.dropdown.icon {
        background-color: transparent !important;
        &:before {
          width: 0 !important;
          height: 0 !important;
          border-left: 5px solid transparent !important;
          border-right: 5px solid transparent !important;
          border-top: 10px solid #3d87ab !important;
          content: "" !important;
          position: absolute !important;
          top: 10px !important;
          background-color: transparent !important;
        }
      }
      i.dropdown.icon.clear {
        &:before {
          content: "\f00d" !important;
          width: 0 !important;
          height: 0 !important;
          border-left: 0px solid transparent !important;
          border-right: 0px solid transparent !important;
          border-top: 0px solid #3d87ab !important;
          position: absolute !important;
          top: 7px !important;
          right: 22px !important;
          background-color: transparent !important;
          font-size: 13px;
        }
      }
    }
    label {
      text-align: center;
      display: block;
      margin-bottom: 20px;
    }
  }
  .checkbox-config {
    display: grid;
    margin-top: 30px;
    .checkbox {
      height: 20px;
      label {
        margin: 0px 0px 0px 35px;
        &:before,
        &:after {
          margin-left: -25px;
          border-color: #3c85a8;
          color: #3c85a8;
        }
      }
    }
  }

  .min-max-record {
    label {
      text-align: center;
      display: block;
      margin-bottom: 20px;
    }
    margin-left: 10px;
    input {
      width: 80px;
      max-width: 80px;
      min-width: 80px;
      line-height: 8pt;
      height: 30px;
      margin-left: 10px;
    }
  }
  hr {
    border-color: #3c85a8;
  }
  .table-column-field {
    padding: 0px 8px;
    .table-type {
      color: #3c85a8;
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        border-bottom: 2px solid #3c85a8;
        font-weight: 500;
        font-size: 17px;
        &.error {
          border-bottom: 2px solid #ff8585 !important;
          color: #ff8585 !important;
        }
      }
      .table-unique-columns {
        margin-top: 1px;
        .icon {
          margin-left: 10px;
          position: relative;
          top: 2px;
          cursor: pointer;
        }
      }
    }
    .field-selection,
    .field-type-drag-container {
      &.with-checkbox {
        padding-left: 35px;
        &.Referenced {
          padding-left: unset;
        }
      }
    }
    .dropdown {
      background: none;
    }
    .attribute-selection {
      width: 238px !important;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
      padding-left: 5px;
      border-radius: 0px;
      margin-left: 10px !important;
      input.search {
        padding-left: 5px;
      }
    }
    .aggregate-options {
      width: 130px !important;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
      padding-left: 5px;
      border-radius: 0px;
      margin-left: 10px !important;
      &.empty {
        width: 130px !important;
        margin-left: 15px !important;
      }
    }

    .min-width {
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
      border-radius: 0px;
      max-width: 100px;
      padding: 0.67857143em 7px;
      margin-left: 10px;
    }
    > div {
      > div {
        > div {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    div.field-type-drag-icon {
      > img {
        width: 10px;
        height: 17px;
        margin-left: 10px;
        cursor: grab;
      }
    }
    .field-selection {
      width: 532px;
      &.with-checkbox {
        width: 567px;
      }
      > :nth-child(1) {
        display: inline-block !important;
      }
      > :nth-child(2) {
        display: inline-block !important;
        margin-left: 5px;
        width: 248px;
      }
      > :nth-child(3) {
        display: inline-block !important;
        width: 228px;
        margin-left: 15px !important;
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
        padding-left: 10px;
        border-radius: 0px;
        margin-left: 10px !important;
      }
      > :nth-child(4) {
        display: inline-block !important;
      }
      > img {
        width: 15px;
        height: 18px;
        margin-top: -4px;
      }
      &.error {
        background-color: #ff8585 !important;
      }
      .dropdown.icon {
        &:before {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid #3d87ab;
          content: "";
          position: absolute;
          top: 13px;
          right: 10px;
        }
      }
      .dropdown.icon.clear {
        &:before {
          content: "\f00d";
          width: 0;
          height: 0;
          border-left: 0px solid transparent;
          border-right: 0px solid transparent;
          border-top: 0px solid #3d87ab;
          position: absolute;
          top: 12px;
          right: 20px;
        }
      }
    }
    .selected-field {
      border-radius: 5px;
      border: 2px solid #3c85a8;
      width: 228px;
      margin-left: 10px;
      margin-top: 3px;
      vertical-align: middle;
      display: flex;
      align-items: center;
      > div {
        display: inline-block;
      }
      div.field-type-title {
        padding: 5px;
        text-align: left;
        border-left: 2px solid #3c85a8;
        width: 86%;
        display: flex;
        justify-content: space-between;
        .field-title {
          overflow: hidden;
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #4183c4;
          padding-right: 5px;
        }
      }
      div.field-type-image {
        min-width: 30px;
        > img {
          width: 20px;
        }
        padding: 5px;
        float: left;
      }
      div.field-type-action {
        > img {
          margin-top: 10px;
          width: 15px;
          height: 15px;
          display: inline-block;
          margin-right: 5px;
        }
        float: right;
      }
      div.field-type-drag-icon {
        > img {
          width: 10px;
          height: 17px;
        }
      }
    }
    div.field-type-action {
      > img {
        margin-top: 5px;
        margin-left: 5px;
        width: 15px;
        height: 18px;
        display: inline-block;
        margin-right: 5px;
      }
      display: inline-block;
    }
    .table-add-field { 
      cursor: pointer;
      &.with-checkbox {
        i {
          margin-right: -58px;
        }
      }
      i {
        margin-top: 10px;
        margin-right: 30px;
        float: right;
      }
    }
  }
}
