.admin-table-container {
  height: calc(100vh - 75px - 86px - 65px - 155px);
  margin-bottom: 5px !important;
}

.admin-table {
  tr {
    &:nth-child(even) {
      background: #f3f3f3;
    }
    &:nth-child(odd) {
      background: #fff;
    }
  }
  thead {
    .header-action {
      th {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 2;
      }
      .download {
        padding: unset !important;
      }
    }
    .header-name {
      th {
        position: -webkit-sticky;
        position: sticky;
        top: 31px;
        z-index: 2;
      }
    }
    th {
      font-size: 13px;
      padding: 3px 10px;
      background-color: #3a85a8;
      color: #ffffff;
      height: 30px !important;
      &:first-child {
        width: 56px;
      }
      &:last-child {
        position: -webkit-sticky;
        position: sticky;
        right: 0;
        text-align: center;
        background-color: #fff;
        border-color: #fff;
        width: 30px;
        padding-left: 5px;
        cursor: pointer;
      }
    }
    th.th-top-user-info {
      background-color: #d5e0e5;
      i.search {
        color: #000206;
      }
      img {
        display: inline-block !important;
        margin: 0px 2px;
        max-width: unset;
      }
    }
    th.th-top-user-info-other {
      background-color: #eceeef;
      i.search {
        color: #020306;
      }
      i.ellipsis {
        color: #020306;
      }
      img {
        display: inline-block !important;
        margin: 0px 2px;
        max-width: unset;
      }
    }
    th.th-bottom-user-info-other {
      background-color: #7aa0b3;
      &.required {
        span {
          &::after {
            margin: -0.2em 0 0 0.2em;
            content: "*";
            color: #db2828;
          }
        }
      }
      .info-icon {
        position: relative;
        margin-left: 20px;
        margin-right: 0px;
        top: 2px;
        color: #000;
        width: 14px !important;
        height: 14px !important;
        &::before {
          font-size: 11px !important;
          margin-top: -5px;
        }
      }
    }
  }
  .th-bottom-user-info-status {
    width: 62px;
  }
  tbody {
    td {
      &:last-child {
        position: -webkit-sticky;
        position: sticky;
        right: 0;
        top: 0;
        background-color: #fff;
      }
      &.editOnly {
        .edit-only {
          margin-right: 5px;
        }
      }
    }
    tr {
      td {
        padding: 0px 5px !important;
        border-right: 0.5px solid #fff;
        font-size: 10pt;
        text-align: center;
        .file-component {
          justify-content: center;
        }
      }
    }
  }
  .table-action-icon {
    font-size: 11px !important;
    color: #000;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 4px;
  }
  .table-action-plus {
    font-size: 12px !important;
    background-color: #799eae;
    padding-top: 0px !important;
    padding-bottom: 19px !important;
    padding-left: 4.5px !important;
    padding-right: 15px !important;
    border-radius: 10px;
    height: 10px;
  }
  .table-checkbox-toggle {
    height: 12px !important;
    width: 10px !important;
    z-index: 0;
    label {
      &:before {
        width: 25px !important;
        height: 12px !important;
        margin-top: 6px;
      }
      &:after {
        width: 10px !important;
        height: 10px !important;
        margin-top: 7px;
        margin-left: 2px !important;
      }
    }
    input {
      &:checked ~ label {
        &:before {
          width: 25px !important;
          height: 12px !important;
          margin-top: 6px;
        }
        &:after {
          width: 10px !important;
          height: 10px !important;
          left: 11px !important;
          margin-top: 7px;
          margin-left: 2px !important;
        }
      }
    }
  }
  .disabled{
    background-color: #ddd!important;
    cursor: not-allowed!important;
    color: #000;
  }
}

#delete-error-message {
  width: 495px !important;
  margin: -25px 0px 15px 0px !important;
}
