#cstm-dropdown-parent {
	.transition {
		animation-iteration-count: 1;
		animation-duration: .3s;
		animation-timing-function: ease;
		animation-fill-mode: both;
	}
}
#cstm-dropdown-list {
	>* {
		white-space: nowrap;
	}
	padding: 0 0;
	background: #fff;
	font-size: 1em;
	text-shadow: none;
	text-align: left;
	cursor: pointer;
	z-index: 20;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 16.02857143rem;
	border-color: #96c8da;
	box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
	border-radius: 0 0 .28571429rem .28571429rem;
	border-top-width: 0 !important;
	border: 1px solid rgba(34, 36, 38, .15);
	will-change: transform, opacity;
	.item.selected {
		color: rgb(58, 133, 168) !important;
		background: rgba(0, 0, 0, .03);
	}
	.message {
		padding: .78571429rem 1.14285714rem;
		color: #3A85A8 !important;
		margin: 0 -1px;
		font-weight: 400;
	}
	.item {
		position: relative;
		cursor: pointer;
		display: block;
		border: none;
		height: auto;
		text-align: left;
		border-top: none;
		line-height: 1em;
		color: #000;
		padding: .78571429rem 1.14285714rem !important;
		font-size: 1rem;
		text-transform: none;
		font-weight: 400;
		box-shadow: none;
		-webkit-touch-callout: none;
		border-top: 1px solid #fafafa;
		padding: .78571429rem 1.14285714rem !important;
		white-space: normal;
		word-wrap: normal;
		&:hover {
			background: rgba(0, 0, 0, .05);
			color: rgba(0, 0, 0, .95);
			z-index: 13;
		}
	}
}
