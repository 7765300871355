@import "../../../../../../css/theme-variables";

.automation-module-container {
  background: #3A85A8!important;
  .admin-item-menu{
    box-shadow: 0 0 0 1px #f3f4f5 inset !important;
    color: #fff!important;
  }
  .automation-active-subroute{
    color: #3b86a8 !important;
    background-color: rgb(255, 255, 255) !important;
  }
}