.gantt-chart-filter {
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 15px;
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 0.3fr auto 1fr;
  grid-column-gap: 15px;
  .border {
    width: 1px;
    background: #e0e0e0;
    margin-top: -2px;
    height: 70px;
    margin-bottom: -6px;
  }
  .filter-select {
    .title {
      font-weight: 500;
      margin-bottom: 5px;
    }
    .ui.selection.dropdown {
      margin-left: unset !important;
      text-align: left;
      width: 100%;
      min-height: 37px;
      max-height: 37px;
      .ui.label {
        box-shadow: 0 0 0 1px rgb(58, 133, 168) inset !important;
      }
      .visible.menu.transition {
        max-height: 150px;
      }
      div {
        div {
          text-align: center;
        }
      }
      input.search {
        padding: 8px;
      }
      .text {
        top: -2px;
      }
      i.dropdown.icon {
        color: #3d87ab !important;
        padding-top: 22px;
        &::before {
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 11px solid #3d87ab;
          content: "";
        }
        &.clear {
          &::before {
            content: "\f00d";
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            border-top: 0px solid #3d87ab;
            position: absolute;
            top: 10px;
            right: 22px;
            font-size: 10pt !important;
          }
        }
      }
    }
  }
  .dropbox-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    > div {
      height: 44px;
      margin-top: auto;
    }
    .img-drag {
      margin-top: 15px !important;
    }
    .dropbox {
      background-color: #fcfcfc;
      border: dashed 2px #e2e2e2;
      text-align: left;
      padding: 20px 5px;
      .field {
        vertical-align: middle;
        color: #697073;
        span {
          font-family: auto;
        }
        img {
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
    .dropbox-range {
      background-color: white;
      grid-column: span 2;
      .dropbox {
        border: none;
        text-align: left;
        padding: 11px 12px !important;
        margin-left: 20px;
        border: solid 1px #3b86a8;
        border-radius: 4px;
      }
    }
    .dropbox.with-item {
      border: solid 1px #3b86a8;
      height: 44px;
      padding: 12px;
      margin-left: 20px;
      border-radius: 4px;
    }
  }
}
