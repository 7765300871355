#status-visibility-modal {
	border-radius: 5px;
	box-shadow: 0px 2px 6px #000000;
	width: 140px;
	padding: 5px;
	position: absolute;
	z-index: 5;
	background-color: #F3F3F3;
	.header-name {
		border-radius: 3px;
		border: 1px solid #558397;
		background-color: #D5E0E5;
		padding-left: 3px;
		font-size: 12px;
		margin-bottom: 5px;
	}
	.static-button-container {
		display: grid;
		grid-template-columns: 35px 35px 35px;
		grid-gap: 10px;
		padding: 5px 0px;
		img {
			background-color: #FFFFFF;
			padding: 5px 3px;
			cursor: pointer;
			border-radius: 5px;
			padding: 8px;
			height: 34px;
			width: 34px;
			&:first-child {
				height: 34px;
				width: 34px;
				padding-right: 10px;
				padding-left: 10px;
			}
		}
		img.active {
			background-color: #3C85A5;
		}
		img.inactive {
			filter: grayscale(200%);
		}
	}
}
