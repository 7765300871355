#slider-config-container {
  text-align: center;
  color: rgb(58, 133, 168) !important;
  font-weight: 500;
  &.table-level {
    display: flex;
    padding-left: 40px;
    &.no-width {
      width: 100%;
      padding-right: 100px;
    }
  }
  ul {
    margin: 5px 0px 0;
    padding: 0;
    list-style: none;
    min-width: 230px;
    li {
      position: relative;
      float: left;
      min-width: calc(100% / 4);
      text-align: center;
      font-size: 12px;
      cursor: pointer;
    }
  }
  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 80%;
    background-color: #d6d6d6;
    border-radius: 5px;
    margin-top: 3px;
    height: 7px;
    &:focus {
      outline: none;
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px;
      cursor: pointer;
      animation: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: #d7d7d7;
      border-radius: 10px;
      border: 0px solid #010101;
    }
    &::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px #000031;
      border: 0px solid #00001e;
      height: 18px;
      width: 18px;
      border-radius: 15px;
      background: #3b86a8;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -6px;
    }
    &::-moz-focus-outer {
      border: 0;
    }
  }
  .hide-thumb {
    &::-webkit-slider-thumb {
      display: none;
    }
  }
}

input[type="range"] {
  &::-moz-range-thumb {
    border: 1px solid #3b86a8;
    border-radius: 10px;
    background: #3b86a8;
    cursor: pointer;
  }
}
