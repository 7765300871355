.list-side-view {
  background-color: #eef1f2;
  padding: 10px 15px;
  padding-top: 20px;
  overflow: hidden auto;
  color: #888d90;
  .sv-tab-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #707578;
    .sv-tab {
      font-size: 13px;
      cursor: pointer;
      &.active {
        color: #61a7b3;
      }
    }
  }
  .list-sv-field-item {
    margin-bottom: 10px;
    display: inline-block;
    color: #697073;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    padding: 5px 10px;
    width: 170px;
    background-color: #ffffff;
    .sv-field {
      display: flex;
      align-items: center;
      .label {
        margin-right: auto;
        font-weight: 400;
        text-transform: capitalize;
        font-size: 12px;
        color: #697073;
        white-space: nowrap;
        max-width: 100px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      img {
        &.icon {
          float: left;
          margin-right: 10px;
          width: 14px;
        }
        &.drag-icon {
          width: 10px;
          float: right;
        }
      }
    }
  }
  .sv-column-tab {
    margin-top: 12px;
    hr {
      width: 170px;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #e1e1e1;
      padding: 0;
    }
    .ui.icon.input {
      input {
        padding: 5px 5px 5px 30px;
        border-radius: 3px !important;
        border-color: #d5d5d5;
        width: 170px;
      }
      i.icon {
        right: unset;
        color: #61a7b3;
      }
    }
  }
}
