.form-builder-mobile-container {
  .mobile-section-container {
    &:not(:first-child) {
      margin-top: 10px;
    }
    .mobile-section {
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
      padding: 10px 20px;
      box-sizing: border-box;
      border: 1px solid #e0e0e0;
      min-height: 45px;
      &.disabled {
        opacity: 0.5;
        .action,
        .content {
          pointer-events: none;
        }
      }
      .title {
        font-weight: 700;
        cursor: grab;
        display: flex;
        align-items: center;
        .ui.toggle.checkbox {
          cursor: pointer;
          margin-right: 7px;
          label {
            min-height: unset;
          }
          label:before {
            background-color: #b4b6b7 !important;
            width: 40px;
            height: 17px;
            top: 3px;
          }
          input:checked ~ label:before {
            background-color: #0089d0 !important;
          }
          input:checked ~ label:after {
            width: 15px;
            height: 15px;
            top: 4px;
            left: 1.7rem;
          }
          input ~ label:after {
            width: 15px;
            height: 15px;
            top: 4px;
            right: 2px;
            left: 2px;
          }
        }
        .collapse-icon {
          font-size: 15px;
          color: #3b86a8;
          margin-left: auto;
          margin-right: unset;
        }
        .name {
          position: relative;
          top: 2px;
          font-weight: 500;
        }
      }
      .content {
        padding: 20px 10px 10px;
        .input-dropdown {
          border: unset;
          background: #f3f3f3;
          margin: unset !important;
          min-width: unset !important;
          min-height: 35px !important;
          max-height: 35px !important;
          padding-top: 10px !important;
          width: 100%;
          font-size: 13px;
          div.text {
            color: #757575;
            width: inherit;
            overflow: hidden !important;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
            background-color: transparent !important;
          }
          .ui.label {
            background-color: #f8f8f8 !important;
            font-size: 12px;
            margin-top: 5px;
          }
          i.dropdown.icon.clear {
            &:before {
              content: "\f00d" !important;
              width: 0 !important;
              height: 0 !important;
              border-left: 0px solid transparent !important;
              border-right: 0px solid transparent !important;
              border-top: 0px solid #3d87ab !important;
              position: absolute !important;
              top: 3px;
              right: 18px !important;
              background-color: transparent !important;
              font-size: 13px;
            }
          }
          i.dropdown.icon {
            &:before {
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 10px solid #3d87ab;
              content: "";
              position: absolute;
              top: 2px;
              right: 0px;
            }
            margin: unset !important;
          }
          .active.item {
            background-color: unset;
          }
        }
        .upper-widget-content {
          .upper-widget {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 12px;
            &.input-footer,
            &.input-header {
              input {
                background: #f3f3f3;
                border-radius: 4px;
                border: unset;
                min-height: 35px;
                max-height: 35px;
                padding: 5px 10px;
                color: #545454;
                width: 100%;
                &:focus {
                  outline: 0;
                }
                font-size: 13px;
              }
            }
            &.field-widget-big {
              .field-widget {
                background: #f3f3f3;
                border-radius: 4px;
                min-height: 132px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                .field.icon {
                  filter: grayscale(1);
                  max-width: 95px;
                  max-height: 50px;
                  position: absolute;
                  top: 32px;
                  &.Switch {
                    max-width: 70px;
                    max-height: 40px;
                    top: 40px;
                  }
                  &.Number {
                    max-width: 70px;
                    max-height: 40px;
                    top: 48px;
                  }
                  &.FX {
                    max-width: 70px;
                    max-height: 40px;
                    top: 40px;
                  }
                  &.Multiline {
                    max-width: 70px;
                    max-height: 40px;
                    top: 40px;
                  }
                  &.Referenced,
                  &.Dropdown {
                    max-width: 73px;
                    max-height: 43px;
                    top: 35px;
                  }
                  &.URL,
                  &.Email,
                  &.Time,
                  &.Date,
                  &.Checklist {
                    top: 30px;
                  }
                }
                .circular.icon {
                  margin-top: 10px;
                  background: #d1dce0;
                  color: #fff;
                  box-shadow: unset;
                  width: 2.7em !important;
                  height: 2.7em !important;
                  padding: 10px !important;
                  margin-bottom: 2px;
                  &::before {
                    font-size: 17px;
                  }
                  margin-right: 0px;
                }
                .ui.selection.dropdown {
                  position: relative;
                  top: 5px;
                  min-width: 130px !important;
                  max-width: 130px !important;
                  z-index: 25;
                  div.text {
                    color: #3a86a7 !important;
                  }
                  &.active {
                    box-shadow: unset !important;
                  }
                  .menu {
                    border-radius: 0.28571429rem !important;
                    border: 1px solid #ddd8d8 !important;
                    box-shadow: unset !important;
                    min-width: 180px !important;
                    left: -20px !important;
                    max-height: 200px;
                    .item {
                      &:hover {
                        color: #3a86a7 !important;
                      }
                    }
                  }
                  i.dropdown.icon {
                    &:before {
                      top: 4px;
                      left: 15px;
                    }
                  }
                  i.dropdown.icon.clear {
                    &:before {
                      top: 2px;
                      left: 17px;
                    }
                  }
                  &.hasValue {
                    top: 30px;
                    div.text {
                      color: #333333 !important;
                    }
                  }
                }
              }
            }
            &.field-widget-big,
            &.input-footer {
              margin-top: 11px;
              .input-dropdown-container {
                position: relative;
                img.field.icon {
                  position: absolute;
                  z-index: 20;
                  left: 5px;
                  max-width: 23px;
                  max-height: 23px;
                  filter: grayscale(1);
                  top: 5px;
                  &.FX {
                    max-width: 15px;
                    top: 10px;
                    left: 9px;
                  }
                }
              }
              .input-dropdown.hasValue {
                padding-left: 30px;
                input {
                  padding-left: 30px;
                }
              }
              i.dropdown.icon.clear {
                &:before {
                  top: 1px;
                  left: 10px;
                }
              }
            }
          }
        }
        .lower-widget-content {
          margin-top: 20px;
          .title {
            font-weight: 500;
          }
          .lower-widget {
            margin-top: 8px;
            margin-left: 5px;
            .dropdown-select-list {
              display: grid;
              grid-template-columns: 1fr 36px;
              width: 55%;
              grid-column-gap: 12px;
              align-items: center;
              &:not(:first-child) {
                margin-top: 8px;
              }
              svg {
                position: relative;
                top: -2px;
              }
              .field-select {
                display: grid;
                grid-template-columns: 30px 1fr;
                align-items: center;
                .input-dropdown {
                  width: 100%;
                  i.dropdown.icon.clear {
                    &::before {
                      top: 1px;
                      right: 11px !important;
                    }
                  }
                  &.active.visible {
                    z-index: 30;
                  }
                }
              }
              .btn-plus {
                cursor: pointer;
                background: #3a86a7;
                border: 1px solid #3a85a8;
                box-sizing: border-box;
                border-radius: 3px;
                width: 32px;
                height: 32px;
                align-items: center;
                display: flex;
                justify-content: center;
                svg {
                  position: unset;
                  top: unset;
                }
              }
            }
          }
        }
      }
      .action {
        display: flex;
        padding: 0px 5px;
        margin-bottom: 5px;
        margin-top: 5px;
        justify-content: flex-end;
        button {
          width: 100px;
          color: #ffffff;
          font-size: 12px;
          padding: 8px;
          margin-left: 5px;
          &.red {
            background-color: #d01919;
          }
        }
      }
    }
  }
  .button-container {
    background: transparent;
    height: 41px;
    padding: 10px 5px;
    button {
      width: 120px;
      float: right;
      color: #ffffff;
      margin-right: 10px;
      font-size: 12px;
    }
  }
}
