.automation-form-container {
  .automation-form-outline {
    overflow: auto;
    .outline-header {
      display: block;
      text-align: left;
      color: white;
      background: #3a85a8;
      padding: 15px 15px 15px 30px;
      border-radius: 5px;
      margin-right: -2px;
      font-weight: 700;
      width: 99%;
    }
    .outline-detail {
      .trash.alternate.outline.icon {
        position: absolute;
        right: 10px;
        color: black;
      }

      .child-title {
        > label {
          width: calc(100% - 50px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
        }
      }

      .rule-outline {
        list-style: none;
        padding-inline-start: 20px;
        padding-inline-end: 5px;

        > li {
          position: relative;
          display: grid;
          grid-template-columns: 35px 180px;
          cursor: pointer;

          &:before {
            margin-right: 10px;
            font-size: 20px;
          }
          &:after {
            position: absolute;
            left: 3px;
            top: 0;
            content: "";
            border-left: 2px solid #3a85a8;
            margin-left: 8px;
            height: 60px;
            z-index: 0;
            height: calc(100% + 50px);
          }

          &.outline-widget {
            margin-top: 40px;
            .has-border {
              height: 26px;
              width: 26px;
              border: 1px solid #3a85a8;
              border-radius: 50%;
              background-color: white;
              z-index: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .content {
              display: grid;
              grid-template-columns: 1fr;

              .title {
                font-weight: 700;
                font-size: 12px;
              }

              .description {
                font-size: 10px;
                position: absolute;
                margin-top: 17px;
                width: 67%;
              }

              .description-container {
                .title {
                  font-weight: 700;
                  font-size: 10px;
                }
                .content {
                  font-size: 10px;
                }
              }
            }
          }

          &.add-component {
            margin-top: 40px;

            &:hover {
              background-color: transparent;
            }

            i {
              font-size: 22px;
              color: #868686;
              position: absolute;
              z-index: 1;
              background-color: white;
              margin-top: 5px;
              cursor: pointer;

              &.icon.plus.circle:before {
                margin-top: -5px;
                position: absolute;
                margin-left: -11px;
              }
            }
            .content {
              margin-top: 5px;
              .title {
                font-size: 12px;
              }
            }
            &:after {
              border: none;
            }
          }
        }
      }
      .rule-outline-child {
        list-style: none;
        margin-left: -40px;
        padding-top: 10px;

        > li {
          position: relative;
          display: grid;
          grid-template-columns: 20px 150px;
          cursor: pointer;

          > div {
            > i {
              color: #3a85a8;
            }
          }

          &:before {
            margin-right: 10px;
            font-size: 20px;
          }
          &:after {
            position: absolute;
            left: -1px;
            top: 0;
            content: "";
            border-left: 2px solid #3a85a8;
            margin-left: 8px;
            height: 60px;
            z-index: 0;
            height: calc(100% + 50px);
          }

          &.add-else {
            margin-top: 40px;
            &:after {
              border: none;
            }
          }

          &.add-action {
            margin-top: 40px;
            &:after {
              border: none;
            }
          }
        }
      }
    }
  }
}
