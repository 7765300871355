#relation-config-modal {
  min-width: 340px;
  max-width: 340px;
  box-shadow: 0px 0px 10px 0px grey;
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  background-color: #ffffff;
  z-index: 50;
  small {
    font-style: italic;
    color: #808080;
  }
  .bp-selected-header {
    padding: 8px 12px;
    background-color: #3a86a7;
    color: #ffffff;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn-cancel {
    width: 20px;
    position: absolute;
    right: 20px;
    top: 17px;
    cursor: pointer;
  }
  .header-label {
    display: flex;
    justify-content: space-between;
    label.required {
      &::after {
        margin: -0.2em 0 0 0.2em;
        content: "*";
        color: #db2828;
      }
    }
  }
  .initial {
    border: 1px dashed #9c9c9c;
    background: #f8f8f8;
    min-height: 40px;
  }
  .header-container {
    .header-label {
      .label {
        margin-right: 4px;
      }
    }
    .header-field {
      padding: 5px;
      padding-right: 0px;
      margin-top: 5px;
      > label {
        font-weight: 700;
        color: #3a86a7;
        font-size: 13px;
      }
      &.error {
        border-color: #ff8585 !important;
      }
    }
  }
  .content-field-main {
    &:not(:first-child) {
      margin-top: 7px;
    }
    cursor: grab;
    .content-field-container {
      display: grid;
      grid-template-columns: auto 40px;
      grid-column-gap: 6px;
      .field-label {
        cursor: pointer;
        padding: 5px 11px;
        display: flex;
        align-items: center;
        border: 1px solid #0087a9;
        border-radius: 3px;
        .slash {
          position: absolute;
        }
      }
      .content-field {
        color: #3a86a7;
        padding: 7px 5px;
        padding-right: 8px;
        border: 1px solid #3b85a8;
        border-radius: 3px;
        margin-left: 10px;
        min-height: 35px;
        > img {
          float: left;
          margin-left: -22px;
          width: 11px;
          height: 15px;
          cursor: grab;
          margin-top: 2px;
        }
        label {
          color: #000000;
          margin-left: 3px;
          > img {
            width: 12px;
            height: 12px;
            float: right;
            margin-top: 4px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .selected-field-container {
    margin-top: 15px;
    .header-label {
      color: #808080;
      font-size: 11px;
      .label {
        margin-right: 7px;
      }
    }
    .selected-field {
      padding: 5px 10px;
      padding-right: 0px;
      max-height: 200px;
      overflow: auto;
      &.initial {
        margin-top: 5px;
      }
      &.error {
        border-color: #ff8585 !important;
      }
    }
  }
  .field-selection {
    display: flex;
    margin-top: 15px;
    margin-bottom: 5px;
    .selection.dropdown {
      margin-left: 0px !important;
      margin-right: 7px !important;
      background-color: #f3f3f3 !important;
      border: unset !important;
      border-radius: 2px !important;
      .dropdown.icon {
        &.clear {
          &::before {
            font-size: 15px;
            top: 2px;
          }
        }
        &::before {
          font-size: 20px;
          position: relative;
          top: 1px;
        }
      }
    }
    .add {
      min-width: 36px;
      max-height: 38px;
      font-size: 12px;
      background-color: #3a86a7;
    }
    &.multiple {
      a.ui.label {
        background: #4b6a77 !important;
        border-radius: 100px !important;
        color: #fff;
        font-weight: normal !important;
        font-size: 12px;
        padding: 7px 10px;
        .delete.icon {
          position: relative;
          top: -4px;
          float: right;
          &::before {
            content: "\00d7";
            font-size: 20px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .action {
    margin-top: 15px;
    button {
      float: right;
      padding: 7px 11px;
      width: 80px;
      color: white;
      &.btn-del {
        margin-right: 10px;
      }
    }
  }
  .toggle-checkbox {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 5px;
  }
  .ui.toggle.checkbox {
    label:before {
      background-color: #c4c4c4 !important;
      width: 40px;
      height: 18px;
    }
    input:checked ~ label:before {
      background-color: #0089d0 !important;
    }
    input:checked ~ label:after {
      width: 17px;
      height: 16px;
      top: 1px;
      right: 5px;
      left: 1.56rem;
    }
    input ~ label:after {
      width: 17px;
      height: 16px;
      top: 1px;
      right: 2px;
      left: 1px;
    }
  }
}
