.related-apps-main {
  margin-top: 12px;
  .related-apps-container {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(3, 450px);
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    max-width: 80%;
    &.two {
      grid-template-columns: repeat(2, 450px);
    }
    &.one {
      grid-template-columns: repeat(1, 450px);
    }
  }
  .related-apps-main {
    padding: unset !important;
    background: #f3f3f3;
    .separator {
      background: linear-gradient(to right, white 0%, #bdbdbd 50%, white 100%) !important;
      height: 1px;
    }
    .related-apps-title {
      padding: 0px !important;
      padding-left: 20px !important;
      min-height: 37px !important;
      border: unset !important;
      cursor: default !important;
      .ra-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .ra-urn {
          display: flex;
          align-items: center;
          cursor: default;
          .urn {
            &:hover {
              text-decoration: underline;
            }
            cursor: pointer;
            &.not-allowed {
              &:hover {
                cursor: not-allowed;
                text-decoration: none;
              }
            }
          }
          .urn {
            font-weight: 600;
            color: #469de4;
            text-decoration: underline;
          }
          .label {
            font-weight: 600;
          }
          .label {
            margin-right: 5px;
          }
          svg {
            margin-right: 15px;
            width: 20px;
            height: 20px;
          }
          .user {
            svg {
              width: 12px;
            }
          }
          .ra-header-details {
            margin-right: 5px;
            color: #000;
            font-size: 13px;
            line-height: 15px;
            &.expanded {
              max-width: 305px;
            }
          }
        }
        .ra-status-container {
          display: flex;
          justify-content: center;
          height: 100%;
          align-items: center;
          .ra-status {
            height: 100%;
            justify-content: center;
            .status-container {
              height: 100% !important;
              margin-left: 0px !important;
              min-width: 80px;
              max-width: 150px;
              padding: 6px 10px;
              .status-label {
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .my-icon {
            cursor: pointer;
          }
          i {
            margin-left: 10px;
            font-size: 17px;
            margin-right: 10px;
            color: #000 !important;
          }
        }
      }
    }
    .related-apps-content {
      padding: 15px !important;
      padding-bottom: 30px !important;
      height: max-content;
      width: 100%;
      background: #f3f3f3;
      .ra-content-list {
        margin-left: 25px;
        .label {
          font-weight: 500;
        }
      }
      .collapse {
        cursor: pointer;
        font-size: 17px;
        float: right;
      }
    }
  }
}
