@import "../../css/mixins";
@import "../../css/theme-variables";

#auth-main-container {
  width: 100%;
  height: 100%;
  #auth-wrapper {
    display: grid;
    grid-template-columns: 35% 58%;
    flex-direction: row;
    align-items: center;
    padding: 0px 50px;
    height: 100%;
    margin: 0px auto;
    margin-top: -30px;
    max-width: 1280px;
    &.forgot-password {
      display: grid !important;
    }
    .column {
      flex: 0 0 40%;
    }
    .login-logo {
      width: 100%;
      height: max-content;
      margin-top: 2em;
      .logo {
        background: unset;
        display: flex;
        align-items: center;
        margin-left: auto;
        height: 100%;
        &.border-y {
          border-right: 1px solid transparent !important;
          border-image: linear-gradient(to bottom, transparent, #ebb68a, transparent);
          border-image-slice: 1 !important;
        }
        &.border-x {
          border-bottom: 1px solid transparent !important;
          border-image: linear-gradient(to right, transparent, #ebb68a, transparent);
          border-image-slice: 1 !important;
        }
        .image {
          width: 300px;
          margin: auto;
          margin-right: 50px;
        }
      }
    }
    #auth-form {
      padding: 20px;
      padding-left: 40px;
      &.disabled {
        opacity: 0.7;
      }
      & #auth-message {
        .message {
          &.negative {
            color: $app-error;
            box-shadow: 0 0 0 1px $app-error inset, 0 0 0 0 transparent;
            .header {
              font-size: 12px;
              color: $app-error;
            }
          }
          &.success {
            color: $app-success;
            box-shadow: 0 0 0 1px $app-success inset, 0 0 0 0 transparent;
          }
          .header {
            font-size: 12px;
            color: $app-success;
          }
        }
      }
      .auth-message-dark {
        & div {
          background-color: #222;
        }
      }
      .auth-message-light {
        & div {
          background-color: #fff;
        }
      }
      .form {
        margin-top: 10px;
        .input-container {
          margin-bottom: 50px;
          .eye.icon {
            font-size: 18px;
            color: #f1ce6c !important;
          }
          .input-field {
            background: transparent !important;
            padding: 10px 0px;
            border-image: linear-gradient(
              90deg,
              rgba(92, 133, 166, 1) 0%,
              rgba(164, 211, 187, 1) 26%,
              rgba(179, 39, 75, 1) 53%,
              rgba(239, 200, 107, 1) 78%,
              rgba(213, 123, 76, 1) 100%
            ) !important;
            border-image-slice: 1 !important;
            border-bottom: 2px solid transparent !important;
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            font-size: 20px;
            color: #fff;
            filter: none;

            @include placeholder {
              color: #fff;
              opacity: 1;
            }
            &:-webkit-autofill {
              box-shadow: unset !important;
              &::first-line {
                font-size: 20px;
              }
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              transition: background-color 5000s ease-in-out 0s;
              -webkit-text-fill-color: #fff;
              -webkit-text-size-adjust: inherit;
            }
          }
        }
        .checkbox-container {
          display: flex;
          flex-direction: row;
          margin-bottom: 40px;
          .remember-me {
            color: rgb(58, 133, 168) !important;
            margin-right: auto;
            .ui.checkbox {
              label {
                font-weight: unset !important;
                color: #fff;
                font-size: 18px;
                min-width: 200px;
                cursor: pointer;
              }
            }
            .ui.checkbox {
              label {
                &::after {
                  content: "\2713";
                  color: #eec669;
                  font-size: 18px;
                }
                &::before {
                  background: unset !important;
                  border-radius: unset !important;
                  border: 1px solid #eec669;
                }
              }
            }
            .ui.checkbox + label {
              font-weight: unset !important;
              color: #fff;
              font-size: 18px;
              min-width: 200px;
            }
            .ui.checkbox {
              width: 135px;
            }
          }
          .forgot-password {
            color: rgb(58, 133, 168) !important;
            label {
              font-size: 18px;
              color: #fff;
              cursor: pointer;
            }
          }
        }
        .action-container {
          margin-top: 20px;
          .action {
            display: flex;
            flex-direction: column;
            .recaptcha-main {
              margin-bottom: 40px;
              .recaptcha-error {
                color: $app-error !important;
                float: left;
                margin: 10px 0px -10px 0px;
              }
            }
            .login {
              .login-btn {
                width: 301px;
                border-radius: 3px !important;
                background: rgb(242, 208, 109) !important;
                background: linear-gradient(
                  180deg,
                  rgba(242, 208, 109, 1) 0%,
                  rgba(228, 172, 95, 1) 29%,
                  rgba(222, 155, 87, 1) 44%,
                  rgba(214, 128, 78, 1) 67%,
                  rgba(208, 102, 69, 1) 100%
                ) !important;
                font-size: 19px;
                color: #222;
              }
            }
          }
        }
      }
    }
  }
}

#auth-footer {
  &.mobile.landscape.small.small-landscape,
  &.portrait {
    &.mobile {
      .footer-main {
        flex-direction: column !important;
        align-items: center !important;
        .mobbiz {
          font-size: 25px !important;
        }
        .apps {
          font-size: 8px !important;
          margin-left: 15px !important;
          letter-spacing: 10px !important;
        }
        .info {
          left: 0px !important;
          font-size: 8px !important;
        }
      }
    }
  }
  &.tablet {
    .footer-main {
      .mobbiz {
        font-size: 35px !important;
      }
      .apps {
        font-size: 11px !important;
        margin-left: 11px !important;
        letter-spacing: 9px !important;
      }
      .info {
        left: 0px !important;
        font-size: 10px !important;
      }
    }
  }
  position: absolute;
  bottom: 0px;
  padding: unset;
  width: 100%;
  .footer-main {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: row;
    .mobbiz {
      font-size: 40px;
      font-weight: bolder;
      color: #cecece;
    }
    .apps {
      font-weight: bolder;
      letter-spacing: 14px;
      font-size: 14px;
      position: relative;
      bottom: 4px;
      margin-left: 14px;
      top: -10px;
      color: #cecece;
    }
    .info {
      font-weight: bold;
      font-size: 12px;
      position: relative;
      bottom: 4px;
      right: 5px;
      left: 20px;
      color: #7a7a7a;
    }
  }
}

#auth-main-container {
  @include breakpoints(240px, 1366px) {
    #auth-form {
      .action {
        align-items: center;
      }
    }
  }
  &.portrait {
    #auth-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0px 20px;
      width: 100%;
      height: 100%;
      align-items: center;
      .column {
        flex: 0 0 0;
      }
      .login-logo {
        * {
          margin: auto;
        }
        margin-bottom: 4em;
        margin-top: 4em;
        .logo {
          height: max-content;
          width: 50%;
          flex-direction: column;
          .border {
            margin-top: 15px;
            &.horizontal {
              background-image: linear-gradient(90deg, transparent, #ebb68a, transparent);
              background-size: 100% 50%;
              height: 0.15em;
              width: 100%;
            }
          }
          .image {
            margin: 0px;
          }
        }
      }
      #auth-form {
        width: 100%;
        padding: 0px;
      }
    }
    &.tablet {
      #auth-wrapper {
        .login-logo {
          margin-top: 8em;
          .logo {
            width: 30% !important;
          }
        }
        #auth-form {
          margin-top: 3em;
          width: 55% !important;
          .checkbox-container,
          .input-container {
            margin-bottom: 3.5em !important;
          }
        }
      }
    }
    &.mobile {
      #auth-wrapper {
        & #auth-message {
          .message {
            i {
              top: unset;
            }
            .header {
              font-size: 11px !important;
              margin-right: 10px;
            }
          }
        }
        .login-logo {
          margin-bottom: 2.5em;
          .logo {
            width: 40%;
          }
        }
        #auth-form {
          width: 98%;
          .recaptcha-main,
          .checkbox-container,
          .input-container {
            margin-bottom: 22px !important;
            .input-field {
              font-size: 15px;
              &:-webkit-autofill {
                &::first-line {
                  font-size: 15px;
                }
              }
            }
          }
          .checkbox-container {
            * {
              font-size: 12px !important;
            }
          }
          .recaptcha-main {
            margin-top: -10px;
            div {
              div {
                transform: scaleX(1) scaleY(0.95);
                margin-bottom: -10px;
              }
            }
            .recaptcha-error {
              font-size: 13px;
            }
          }
          .login-btn {
            font-size: 15px !important;
          }
        }
      }
      &.small {
        #auth-wrapper {
          & #auth-message {
            z-index: 10;
            position: absolute;
            right: 20px;
            top: 20px;
            .message {
              i {
                top: unset;
              }
              .header {
                font-size: 11px !important;
                margin-right: 10px;
              }
            }
          }
          .login-logo {
            margin-bottom: 1.5em;
            .logo {
              width: 30%;
            }
          }
        }
        #auth-form {
          width: 95% !important;
          .checkbox-container,
          .input-container {
            margin-bottom: 15px !important;
            .input-field {
              font-size: 13px !important;
              &:-webkit-autofill {
                &::first-line {
                  font-size: 13px !important;
                }
              }
            }
          }
          .action-container {
            margin-top: -10px !important;
          }
          .recaptcha-main {
            max-height: 65px !important;
            div {
              div {
                transform: scaleX(0.95) scaleY(0.9) !important;
              }
            }
            .recaptcha-error {
              margin: -2px !important;
              margin-left: 25px !important;
              font-size: 11px !important;
            }
          }
          .login-btn {
            width: 258px !important;
            font-size: 13px !important;
          }
        }
      }
    }
  }
  &.landscape {
    &.tablet {
      #auth-wrapper {
        grid-template-columns: 40% 55%;
        .logo {
          width: 90%;
        }
      }
      @include breakpoints(600px, 1000px) {
        #auth-wrapper {
          .login-logo {
            .logo {
              padding-right: 20px;
            }
            .image {
              margin-right: 10px;
            }
          }
        }
        #auth-form {
          .input-container {
            margin-bottom: 30px !important;
          }
          .checkbox-container {
            * {
              font-size: 14px !important;
            }
          }
          .recaptcha-main {
            max-height: 70px;
            div {
              div {
                transform: scaleX(0.96) !important;
              }
            }
          }
          .login-btn {
            width: 260px !important;
            margin-left: 5px;
          }
        }
      }
    }
    &.small-landscape {
      #auth-wrapper {
        display: flex !important;
        padding: unset !important;
        margin-top: 0px;
        .login-logo {
          margin-top: -2em !important;
          .logo {
            width: 70% !important;
          }
        }
        #auth-form {
          margin-top: -50px !important;
          & #auth-message {
            z-index: 10;
            position: absolute;
            right: 20px;
            top: 20px;
            .message {
              i {
                top: unset;
              }
              .header {
                margin-right: 10px;
              }
            }
          }
          .checkbox-container {
            margin-bottom: 10px !important;
            margin-top: 15px;
            * {
              font-size: 12px !important;
            }
          }
          .input-container {
            margin-bottom: 8px !important;
            -field {
              font-size: 13px !important;
              &:-webkit-autofill {
                &::first-line {
                  font-size: 14px !important;
                }
              }
            }
          }
          .action-container {
            margin-top: -15px !important;
            .recaptcha-main {
              margin-bottom: 10px !important;
              div {
                div {
                  transform: scaleX(0.95) scaleY(0.85) !important;
                }
              }
              .recaptcha-error {
                margin-top: -17px !important;
                margin-left: 25px !important;
                font-size: 10px !important;
              }
            }
            .login-btn {
              width: 260px !important;
              font-size: 13px !important;
            }
          }
        }
      }
    }
    &.mobile.landscape.small.small-landscape {
      #auth-wrapper {
        height: calc(100vh - 60px);
        overflow: hidden auto;
        flex-direction: column !important;
        &::-webkit-scrollbar {
          width: 8px !important;
        }
        .login-logo {
          margin-top: 0em !important;
          .logo {
            margin: 10px auto !important;
            margin-bottom: 20px !important;
            width: 30% !important;
            border-bottom: 1px solid transparent !important;
            border-image: linear-gradient(to right, transparent, #ebb68a, transparent);
            border-image-slice: 1 !important;
          }
        }
        #auth-form {
          margin-top: 15px !important;
          .input-container {
            margin-bottom: 20px !important;
          }
          .action-container {
            margin-top: 0px !important;
            .recaptcha-main {
              margin-bottom: 10px !important;
            }
            .login-btn {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
}
