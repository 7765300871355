.list-main-view {
  padding: 20px 0 20px 25px;
  .list-header-container,
  .list-filter-container {
    border: dashed 2px #e0e0e0;
    background-color: #f4f4f4;
  }
  .list-filter-main-container {
    .list-filter-container {
      padding: 15px;
      .dropbox-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
        &:not(:first-child) {
          margin-top: 10px;
        }
        .dropbox {
          border: solid 1px #b2c1c8;
          height: 50px;
          padding: 20px;
          text-align: center;
          color: #598592;
          background: #ffffff;
        }
      }
    }
  }
  .list-header-main-container {
    margin-top: 25px;
    .list-header-container {
      padding-top: 12px;
      min-height: 50px;
      overflow: auto;
      white-space: nowrap;
      max-width: calc(100vw - 380px);
      .list-header-field {
        overflow: auto hidden;
        display: inline-block;
        color: #697073;
        border: 1px solid #d5d5d5;
        border-radius: 3px;
        padding: 5px 10px;
        min-width: 180px;
        background-color: #ffffff;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 10px;
        .field {
          display: flex;
          align-items: center;
          .label {
            margin-right: auto;
            font-weight: 400;
            text-transform: capitalize;
            font-size: 12px;
            color: #697073;
            white-space: nowrap;
            max-width: 100px;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          img {
            &.icon {
              float: left;
              margin-right: 10px;
              width: 14px;
            }
            &.drag-icon {
              width: 10px;
              float: right;
            }
          }
        }
      }
    }
  }
}
