.companies-table-container {
  .e-pager {
    border-top: 0px;
    .e-numericcontainer {
      .e-numericitem.e-active {
        background-color: #39aec1 !important;
        color: #ffffff !important;
      }
    }
  }
  .e-grid {
    .e-spinner-pane {
      display: none;
    }
    .e-gridcontent {
      max-height: calc(100vh - 75px - 86px - 328px);
      overflow: auto;
    }
    .e-headercell {
      .e-headercelldiv {
        padding: 0 1.8em 0 20px !important;
        .e-headertext {
          color: #fff !important;
        }
      }
      .e-icons {
        color: #fff !important;
      }
    }
    .e-custom-commandcolumn {
      cursor: pointer;
      .e-icons {
        color: #39aec1 !important;
      }
      .e-user-list {
        width: 13px;
        display: inline-block;
        margin-right: 5px;
      }
    }
    .e-input-group {
      &::after,
      &::before {
        background: #39aec1 !important;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
    .e-toolbar-items {
      .e-search-toolbar {
        position: absolute;
        right: 0;
      }
      .e-toolbar-right {
        .e-clear-btn {
          position: absolute;
          right: 16px;
          bottom: 4px;
          min-width: unset !important;
          button {
            background-color: unset;
            .e-btn-icon {
              min-width: unset !important;
              min-height: unset !important;
            }
            .e-close {
              font-size: 11px;
              &:hover {
                filter: alpha(opacity=75);
                opacity: 0.75;
              }
              &.e-focus {
                color: #000 !important;
              }
            }
          }
        }
      }
    }
    .table-checkbox-toggle {
      height: 12px !important;
      width: 10px !important;
      z-index: 0;
      label {
        &:before {
          width: 25px !important;
          height: 12px !important;
          margin-top: 6px;
        }
        &:after {
          width: 10px !important;
          height: 10px !important;
          margin-top: 7px;
          margin-left: 2px !important;
        }
      }
      input {
        &:checked ~ label {
          &:before {
            width: 25px !important;
            height: 12px !important;
            margin-top: 6px;
          }
          &:after {
            width: 10px !important;
            height: 10px !important;
            left: 11px !important;
            margin-top: 7px;
            margin-left: 3px !important;
          }
        }
      }
    }
  }
}
