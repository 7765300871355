.table-relation {
  display: block;
  .selected-table-list {
    margin-top: 10px;
    label {
      color: #3B86A8;
      font-size: 15px;
      font-weight: 700;
    }
  }
  .table-list-container {
    display: block;
    border: 1px solid #3B86A8;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    .table-list {
      width: 100%;
      thead {
        tr {
          th {
            text-align: center;
            padding: 5px;
            font-size: 12px;
          }
        }
      }
      tbody {
        tr {
          td {
            text-align: center;
            padding: 5px;
            background-color: #F2F2F2;
            font-size: 12px;
            &:nth-child(2) {
              text-align: left;
            }
          }
        }
      }
    }
  }
  button {
    font-size: 12px !important;
    padding: 10px !important;
    color: #FFFFFF !important;
    width: 120px;
  }
}
