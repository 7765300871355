.pagination-container {
  .ui.menu {
    .item {
      &:before {
        display: none;
      }
    }
  }
  .ui.pagination.menu {
    border: none;
    .item {
      font-weight: 700;
      &:hover {
        background-color: inherit;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  &.mobile {
    position: fixed;
    padding-left: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: #fff;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ui.pagination.menu {
      font-size: 12px;
      margin-top: -5px;
      border: none;
      .item {
        font-weight: 700;
      }
    }
  }
}
