.display-data-list-main {
  height: calc(100vh - 165px - 120px - 65px - 75px);
  overflow: hidden auto;
  padding: 0px 7px;
  .display-data-list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 20px;
    .display-data-selected {
      max-width: 385px;
      .display-data-header {
        text-align: center;
        padding: 10px;
        background-color: #3a86a7;
        color: #ffffff;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        cursor: pointer;
        position: relative;
        display: flex;
        text-align: center;
        justify-content: space-between;
        span {
          margin-left: auto;
          margin-right: auto;
        }
        i {
          float: right;
          position: relative;
          top: 2px;
        }
        .ellipsis {
          font-size: 13px;
          display: flex;
          justify-content: center;
          height: max-content;
          cursor: pointer;
          position: absolute;
          right: 7px;
          top: 0px;
          bottom: 0;
          margin: auto 0px;
        }
      }
    }
    .display-data-content {
      padding: 10px 15px;
      border: #3a86a7 1px solid;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      label {
        display: block;
        color: #808080;
        font-size: 13px;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.header-field {
          font-weight: 500;
          color: #000;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
      .meta-container {
        margin-top: 8px;
        .meta-field {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 100%;
          font-weight: 400;
          font-size: 11px;
          span {
            line-height: 12px;
            &.right {
              text-align: right;
            }
            &.left {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.applied-display-data-container {
  height: calc(100vh - 165px - 120px - 65px - 75px);
  overflow: auto;
  max-width: 100%;
  margin-bottom: 10px;
  .applied-display-data-table {
    border-collapse: collapse;
    table-layout: auto;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    thead {
      tr {
        th {
          background-color: #3a86a7;
          padding: 10px 30px;
          border-right: 1px solid #fff;
          color: #fff;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 20;
          &.contents {
            max-width: 400px;
          }
        }
        th:last-child {
          background-color: #fff;
          padding: 10px;
        }
      }
    }
    tbody {
      tr:nth-child(even) {
        background: #f3f3f3;
      }
      tr:nth-child(odd) {
        background: #fff;
      }
      tr {
        .contents {
          max-width: 400px;
          width: 25%;
          white-space: normal;
          .contents-data {
            text-align: left;
            margin-left: 10px;
            p {
              margin: 0;
              &.header-field {
                font-weight: 500;
              }
              &.description-field {
                color: #808080;
                font-size: 13px;
              }
            }
          }
        }
        td {
          vertical-align: middle;
          padding: 5px;
          .delete {
            background-color: transparent;
            &:focus,
            &:hover {
              background-color: transparent;
            }
          }
          .ui.toggle.checkbox {
            label:before {
              background-color: #c4c4c4 !important;
            }
            input:checked ~ label:before {
              background-color: #0089d0 !important;
            }
            input:checked ~ label:after {
              width: 18px;
              height: 17px;
              top: 2px;
              right: 2px;
              left: 2.1rem;
            }
            input ~ label:after {
              width: 18px;
              height: 17px;
              top: 2px;
              right: 2px;
              left: 3px;
            }
          }
          .label-dropdown {
            width: 100% !important;
            margin: unset !important;
            .default.text {
              color: #3d87ab !important;
            }
          }
          .label-dropdown,
          .status-dropdown,
          .component-dropdown {
            .ui.label {
              background-color: #f8f8f8 !important;
              font-size: 12px;
              margin-top: 5px;
            }
            i.dropdown.icon.clear {
              &::before {
                top: 2px;
                content: "\00d7" !important;
                right: 13px;
                border: unset !important;
                font-size: 25px;
              }
            }
            i.dropdown.icon {
              &:before {
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 10px solid #3d87ab;
                content: "";
                position: absolute;
                top: 5px;
                right: 0px;
              }
              color: #3d87ab !important;
              padding-top: 23px;
              padding-right: 7px !important;
              margin: unset !important;
            }
          }
          .label-dropdown,
          .status-dropdown,
          .component-dropdown {
            background-color: transparent !important;
            border: 1px solid transparent !important;
            width: 100% !important;
            margin: unset !important;
            > div {
              border: none !important;
              color: #3d87ab !important;
              > div {
                border: none !important;
                color: #3d87ab !important;
              }
            }
          }
          &:last-child {
            background-color: #fff;
          }
        }
        td.status {
          max-width: 300px;
        }
        td:nth-child(6),
        td:nth-child(7) {
          text-align: center;
        }
        td:last-child {
          width: 25px;
        }
        .display-data-content {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          text-align: left;
          padding: 2px 5px;
          label {
            display: block;
            color: #808080;
            font-size: 13px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.header-field {
              font-weight: 500;
              color: #000;
              font-size: 14px;
              margin-bottom: 3px;
            }
          }
          .meta-container {
            margin-top: 8px;
            .meta-field {
              display: grid;
              grid-template-columns: 1fr 1fr;
              width: 100%;
              font-weight: 400;
              font-size: 11px;
              span {
                line-height: 12px;
                &.right {
                  text-align: right;
                }
                &.left {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
    .add-row {
      background-color: #3a86a7;
      border-radius: 50%;
      font-size: 20px;
      &:focus,
      &:hover {
        background-color: #3a86a7;
      }
    }
  }
}
