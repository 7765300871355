#shortcut-data-creation-popup {
  .close-icon {
    width: 25px;
    cursor: pointer;
    margin: 30px 20px 0px auto;
  }

  .document-container {
    padding: 0;
  }
}
