.portal-sh-profile-form {
  border-radius: 5px !important;
  width: 860px !important;
  .borderless {
    border-radius: 5px !important;
  }
  .header {
    padding: 1.2rem 1.5rem 1rem !important;
    div {
      padding-left: 15px !important;
      font-weight: 600 !important;
    }
    .bg-primary {
      height: 42px;
      background: #61a7b3 !important;
    }
  }
  .content {
    padding: 1rem 2.6rem !important;
    .content-header {
      display: flex;
      .name {
        font-size: 16px;
        margin-right: 10px;
      }
      .action {
        svg {
          margin-top: 4px;
        }
      }
    }
    .new-el-btn {
      display: flex;
      align-items: center;
      margin-top: 15px;
      .icon {
        background: #61a7b3;
        border-radius: 50%;
        padding: 1px 5px;
        cursor: pointer;
      }
      .label {
        font-weight: 600;
        color: #61a7b3;
        margin-left: 5px;
      }
    }
    .portal-sh-field {
      margin: 12px 0px;
      .field-detail {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0.2fr;
        grid-gap: 10px;
        align-items: center;
        width: 100%;
        .action-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f4f4f4;
          border-radius: 4px;
          width: 100%;
          height: 80%;
        }
        .ui.selection.dropdown {
          border: 1px solid #8a9296;
          box-sizing: border-box;
          border-radius: 4px;
          color: #8a9296 !important;
          min-width: 300px;
          margin: unset !important;
          i.dropdown.icon {
            color: #8a9296 !important;
            margin: unset !important;
            &::before {
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 10px solid #8a9296;
              content: "";
              position: absolute;
              top: 3px;
              right: 0px;
            }
          }
        }
        .checkbox-toggle {
          display: flex;
          align-items: center;
          .ui.checkbox + label {
            font-style: unset !important;
            color: #000 !important;
            font-weight: 600 !important;
            margin-left: 2px;
          }
          .ui.checkbox {
            label {
              &:before {
                width: 40px !important;
                height: 18px !important;
                margin-top: 1px;
              }
              &:after {
                width: 14px !important;
                height: 14px !important;
                margin-top: 2px;
                margin-left: 2px !important;
                margin-top: 3px;
              }
            }
            input {
              &:checked ~ label {
                &:before {
                  width: 40px !important;
                  height: 18px !important;
                  margin-top: 1px;
                }
                &:after {
                  width: 14px !important;
                  height: 14px !important;
                  left: 22px !important;
                  margin-top: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
  .actions {
    padding: unset !important;
    button {
      min-width: 110px !important;
      min-height: 30px !important;
      margin: 0px 5px;
    }
  }
}
