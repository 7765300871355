.document-form-container,
.automation-field {
  #table-mobile-action {
    .content {
      display: grid;
      .action {
        padding: 3px;
      }
      .separator {
        border: 0;
        height: 1px;
        background: linear-gradient(to right, #ffffff -30%, #e6e6e6 50%, #ffffff 130%);
      }
    }
  }
  .vertical-table-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;
    &.mobile {
      .vertical-table-title {
        font-size: 13px;
      }
      .vertical-table-content {
        &::-webkit-scrollbar {
          width: 3px;
        }
      }
      table {
        thead {
          tr {
            th {
              width: 100% !important;
              border: 1px solid #aeaeae !important;
              max-width: unset !important;
            }
          }
        }
        tbody {
          tr {
            &:not(:last-child) {
              td {
                width: 100%;
                border-right: 1px solid #aeaeae;
                border-bottom: 1px solid #aeaeae;
                i.vertical {
                  float: right;
                  margin-right: -4px;
                }
              }
            }
          }
        }
      }
    }
    .vertical-table-navigation {
      padding-right: 38px;
      &.mobile {
        padding-right: 0px;
      }
      &.one {
        padding-right: 0px;
      }
      i {
        font-size: 16px !important;
        cursor: pointer;
      }
      i.left {
        margin-right: auto;
      }
      i.right {
        margin-left: auto;
      }
    }
    .vertical.table.field.error {
      width: 100%;
      text-align: center;
    }
    .vertical-table-title {
      text-align: center;
      font-weight: 700;
      width: calc(100% - 40px);
      padding: 5px;
      &.full-width {
        width: 100%;
      }
    }
    .vertical-table-content {
      overflow: auto;
      max-width: 100%;
      &.hiddenScroll {
        overflow: hidden;
        th {
          &:not(:last-child) {
            min-width: 180px !important;
          }
        }
      }
    }
    .vertical-table-field-type {
      table-layout: auto;
      white-space: nowrap;
      width: 100%;
      overflow-x: auto;
      text-align: center;
      border-spacing: 0;
      border-collapse: collapse;

      th {
        border: 1px solid #aeaeae;
        &:first-child {
          border-left: 1px solid #aeaeae;
        }
        border-right: 1px solid #aeaeae;
        background-image: linear-gradient(to top, #dad8d8, #ffffff) !important;
        min-width: 150px;
        padding: 10px;
        border-top: 1px solid #aeaeae;
        font-size: 12px;
        .required-indicator {
          display: inline-block;
          color: red;
        }
        .info-icon {
          float: right;
          margin-top: 0.1em;
        }
      }

      td {
        border: 1px solid #aeaeae;
        padding: 5px 10px 10px;

        &.with-input-click {
          position: relative;
          .input-click {
            position: absolute;
            background-color: transparent;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0px;
            left: 0px;
          }
        }

        .react-datepicker-wrapper {
          div {
            input {
              border-image: none !important;
            }
          }
        }

        .field-input.ui {
          border-image: none !important;

          &.valid,
          &.error {
            border-image: none !important;
          }

          &.dropdown {
            background: none !important;

            .text {
              color: #8c8c8c;
            }
          }

          textarea,
          input {
            padding-bottom: 0.1em;
            color: #8c8c8c;
            border-image-slice: 0 !important;
          }
        }
      }
      .inactive {
        filter: opacity(0.5);
      }
      .active {
        td {
          .react-datepicker-wrapper {
            div {
              input {
                border-bottom: 0.2em solid #d8d8d8 !important;
              }
            }
          }
          .field-input.Email {
            input {
              padding-left: 18px;
              padding-right: 5px !important;
            }
          }
          .field-input.URL {
            input {
              padding-left: 20px;
              padding-right: 5px !important;
            }
          }
          .field-input.ui {
            &.dropdown,
            &.input {
              border-bottom: 0.2em solid #d8d8d8 !important;
              border-radius: 0;

              textarea,
              input,
              .text {
                color: #3d87ab;
              }
            }
          }
          .react-datepicker__input-container {
            input {
              color: #3d87ab;
            }
          }
        }
      }

      tbody {
        &:last-child {
          td {
            border-bottom: 1px solid #aeaeae;
          }
        }
        tr {
          &:last-child {
            &:hover {
              background: none !important;
            }
          }
          td {
            &:last-child {
              position: -webkit-sticky;
              position: sticky;
              right: 0;
              background: #ffffff;
              border: none;
              border-left: 1px solid #aeaeae;
              min-width: 40px;
              max-width: 40px;
              width: 40px;
              z-index: 0;
            }
          }
        }
        &.two {
          tr {
            &:not(:last-child) {
              td {
                width: calc(100% / 2);
              }
            }
          }
        }
        &.three {
          tr {
            &:not(:last-child) {
              td {
                width: calc(100% / 3);
              }
            }
          }
        }
        &.four {
          tr {
            &:not(:last-child) {
              td {
                width: calc(100% / 4);
              }
            }
          }
        }
        &.five {
          tr {
            &:not(:last-child) {
              td {
                width: calc(100% / 5);
              }
            }
          }
        }
      }
      thead {
        tr {
          th {
            &:last-child {
              background: #ffffff;
              background-image: none !important;
              position: -webkit-sticky;
              position: sticky;
              right: 0;
              min-width: 40px;
              max-width: 40px;
              width: 40px;
              border: none;
              border-left: 1px solid #aeaeae;
            }
            &.required {
              label {
                &::after {
                  content: "*";
                  color: #db2828;
                  margin-left: 2px;
                }
              }
            }
          }
          .download {
            padding: unset !important;
          }
        }
        &.two {
          tr {
            th {
              width: calc(100% / 2);
            }
          }
        }
        &.three {
          tr {
            th {
              width: calc(100% / 3);
            }
          }
        }
        &.four {
          tr {
            th {
              width: calc(100% / 4);
            }
          }
        }
        &.five {
          tr {
            th {
              width: calc(100% / 5);
            }
          }
        }
      }
      .trash {
        cursor: pointer;
      }
      .btn-add-row {
        background-color: #fff !important;
        height: 15px;
        width: 100%;
        text-align: center;
        font-style: italic;
        color: #c3c3c3;
        font-size: 12px;
        font-weight: normal;
      }
      .plus {
        cursor: pointer;
        font-size: 8px;
        background-color: black;
        color: white;
      }
      .checklist-element {
        display: block;
        margin-bottom: 2px;
      }
      .field-label {
        display: none !important;
      }
      .field-input {
        background-color: #ffffff !important;
        input {
          background-color: #ffffff !important;
        }
        textarea {
          background-color: #ffffff !important;
        }
        .ui.label {
          background: #ffffff !important;
          padding: 15px 0 0 0;
        }
      }
      .ui.dropdown {
        border-left: unset;
        border-right: unset;
      }
      .ui.multiple.selection.dropdown {
        .ui.label {
          background-color: #e8e8e8 !important;
          padding: 0.35714286em 0.78571429em !important;
        }
      }
    }
    .field.error {
      margin-bottom: 0 !important;
    }
  }
  .download-popup-container {
    height: 20px !important;
    border-radius: unset !important;
    width: 33px !important;
    margin-left: -5px;
    .dynamic-actions-collapse {
      font-size: 25px;
      display: flex !important;
      justify-content: center;
      height: 100% !important;
      cursor: pointer;
      position: relative;
      top: -5px;
    }
    .btn-download {
      background-color: transparent !important;
      width: 33px !important;
      &:hover,
      &:focus {
        background-color: #e8e8e8 !important;
      }
    }
  }
}
