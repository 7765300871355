@import "../../../../css/theme-variables";
@import "../../../../css/mixins.scss";

.chatbox-icon.mobile {
  width: 43px;
  height: 43px;
  margin-top: 10px;
  svg {
    width: 25px;
    height: 30px;
  }
}

#document-header-popup {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  .created,
  .updated {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .detail {
    font-size: 11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    label {
      font-weight: 700;
    }
    .at,
    .info {
      margin-left: 10px;
    }
    .info {
      margin-right: 20px;
    }
  }
}

.document-form-container {
  &.mobile-landscape {
    overflow: hidden auto;
    height: unset;
    .document-form-section-main {
      overflow: unset;
      height: unset;
    }
  }
  &.mobile {
    .form-action-message {
      .close.icon {
        margin-left: 10px;
      }
      .response-message-widget {
        padding: 8px 10px !important;
      }
      * {
        font-size: 12px !important;
      }
    }
    .document-inner-form {
      .document-form-section {
        .document-form-section-container {
          &:not(:first-child) {
            margin-top: 15px;
          }
          .document-form-section-header {
            height: max-content;
            h3 {
              font-size: 14px !important;
              margin-bottom: 5px !important;
              margin-right: 5px;
            }
            .collapse-icon {
              font-size: 14px;
            }
          }
          .document-form-section-body {
            .form {
              .field-info {
                label {
                  font-size: 12px;
                }
                .info-icon {
                  margin-top: -5px;
                }
              }
            }
          }
        }
      }
      .document-form-action {
        height: max-content;
        &.withChatBoxIcon {
          ul {
            margin: 5px;
            padding: unset;
          }
        }
        ul {
          li {
            margin-right: 20px;
            padding: unset;
            font-size: 12px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .document-close-icon {
      position: relative;
      width: 11px;
      height: 11px;
      margin-right: -15px;
      margin-top: 12px;
    }
    @include breakpoints(240px, 480px) {
      padding: 0px 8px;
      .related-apps-main {
        .related-apps-title {
          padding-left: 15px !important;
          cursor: default !important;
        }
        .related-apps-container {
          margin-left: 0px;
          &.one {
            grid-template-columns: repeat(1, 1fr);
          }
          .ra-title {
            .ra-urn {
              * {
                font-size: 13px !important;
              }
              max-width: 55vw;
            }
            .ra-status-container {
              i {
                font-size: 14px !important;
              }
              .ra-status {
                .status-container {
                  min-width: 60px;
                  max-width: 75px;
                }
              }
            }
          }
          .content {
            .ra-content-list {
              font-size: 13px;
              line-height: 16px;
            }
          }
        }
        .active {
          .ra-title {
            .ra-urn {
              max-width: 64vw !important;
              min-width: 60vw !important;
            }
          }
        }
      }
      .document-form-section-main,
      .document-inner-form {
        &::-webkit-scrollbar {
          width: 4px !important;
        }
        .field-input {
          min-width: 100% !important;
        }
      }
      .field-row-container {
        margin-top: 15px !important;
        .field-container {
          grid-template-columns: 1fr;
          grid-row-gap: unset;
          margin-top: 5px;
          &.field-type-enum,
          &.field-type-number,
          &.field-type-string {
            .field.error {
              margin-top: 5px !important;
            }
          }
          &.field-type-time,
          &.field-type-date {
            .field.error {
              margin-top: 5px;
            }
          }
          &.Table {
            .field.error {
              margin-left: unset;
              float: left;
              white-space: normal;
              margin-top: 5px;
            }
          }
          &.field-type-file {
            .field.error {
              margin-top: 5px !important;
            }
            pre {
              grid-column: 1 !important;
            }
            &.Signature {
              .ui.button {
                font-size: 0.8em !important;
                margin-right: 8px;
                margin-top: 3px;
              }
            }
          }
          &.field-type-array {
            grid-row-gap: 3px;
            .multi-line-container {
              display: grid;
              grid-template-columns: 1fr 20px;
              grid-column-gap: 10px;
              i.plus {
                &.more {
                  margin-top: 15px !important;
                  margin-bottom: 0px !important;
                }
              }
            }
            &.File {
              grid-template-columns: 1fr;
              .gap {
                display: none !important;
              }
              pre {
                grid-column: 1 !important;
              }
            }
          }
          .field-info {
            height: 20px !important;
            .info-icon {
              position: absolute !important;
              top: 0 !important;
              right: 0;
              margin-top: unset !important;
            }
            label {
              text-align: left;
              width: 100%;
            }
          }
        }
      }
    }
    .document-header-main {
      min-height: 35px;
      margin: 15px 2px 7px 2px;
      &.mobile {
        margin-top: 8px;
        @include breakpoints(720px, 890px) {
          .status-container {
            max-width: 120px;
            margin-left: 15px;
            min-height: 30px;
            max-height: 30px;
            margin-right: 2px;
          }
        }
        @include breakpoints(480px, 720px) {
          .status-container {
            max-width: 90px;
            margin-left: 10px;
            font-size: 11px !important;
            border-radius: 3px;
            min-height: 25px;
            max-height: 25px;
            margin-right: 2px;
          }
          .document-form-header {
            * {
              font-size: 0.91em;
            }
            .copy-btn {
              padding: 3px 4px;
              border-radius: 50%;
              height: 19px;
              top: 0px;
              margin-right: 10px;
              svg {
                height: 12px;
                width: 11px;
                top: -1px;
                left: 1px;
                position: relative;
              }
            }
            .urn {
              margin-right: 0px;
              .info {
                margin-left: 15px;
              }
              .form-name {
                margin-left: 15px;
                &::before {
                  margin-right: 15px;
                }
              }
            }
            .detail {
              margin-right: 10px;
              .at,
              .info {
                margin-left: 5px;
              }
            }
            .copy-btn {
              margin: 0px 10px !important;
            }
            .btn-trigger-popup {
              margin-right: 5px;
            }
            .document-follower {
              svg {
                top: 3px;
              }
            }
          }
        }
        @include breakpoints(240px, 480px) {
          margin-top: 5px;
          margin-bottom: 5px;
          .document-form-header {
            * {
              font-size: 0.88em;
            }
            .copy-btn {
              padding: 3px 4px;
              border-radius: 50%;
              height: 19px;
              top: 0px;
              margin-right: 10px;
              svg {
                height: 12px;
                width: 11px;
                top: -1px;
                left: 1px;
                position: relative;
              }
            }
            .urn {
              margin-right: 0px;
              .info {
                margin-left: 5px;
              }
              .form-name {
                margin-left: 10px;
                padding-left: 10px;
              }
            }
            .detail {
              margin-right: 10px;
              .at,
              .info {
                margin-left: 5px;
              }
            }
            .copy-btn {
              margin: 0px 5px !important;
            }
            .btn-trigger-popup {
              margin-right: 5px;
            }
            .document-follower {
              svg {
                width: 15px;
                height: 15px;
                position: relative;
                top: 3px;
              }
            }
          }
          .status-container {
            max-width: 70px;
            margin-left: 10px;
            font-size: 10px !important;
            max-height: 20px;
            border-radius: 3px;
            min-height: 20px;
            padding: 1px 10px;
          }
        }
      }
    }
  }
}
