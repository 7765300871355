.user-field-container {
	margin-top: -10px;
	.field-row-container {
		grid-template-columns: repeat(2, 1fr);
		display: grid;
		grid-column-gap: 40px;
		grid-row-gap: 10px;
		margin-top: 25px;
		.field-icon {
			margin-left: -25px;
			margin-bottom: auto;
			margin-top: 12px;
		}
		.field-container {
			display: grid;
			grid-template-columns: 120px 1fr;
			grid-gap: 10px;
		}
		.sixteen.wide.field {
			width: 100% !important;
			span {
				position: relative;
				label {
					position: absolute;
					bottom: 0;
					font-weight: 800;
				}
			}
			.field-input {
				.ui.label {
					display: none !important;
				}
			}
			input {
				&:-webkit-autofill {
					-webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset !important;
					box-shadow: 0 0 0px 1000px #f8f8f8 inset !important;
				}
			}
		}
		.sixteen.wide.field.required {
			span {
				label {
					&::after {
						color: #000 !important;
					}
				}
			}
		}
		input {
			border: none !important;
			background-color: #f8f8f8 !important;
			width: 100%;
			box-shadow: none !important;
			&:focus {
				border: none !important;
				background-color: #f8f8f8 !important;
				width: 100%;
				box-shadow: none !important;
			}
			&:active {
				border: none !important;
				background-color: #f8f8f8 !important;
				width: 100%;
				box-shadow: none !important;
			}
		}
		textarea {
			border: none !important;
			background-color: #f8f8f8 !important;
			width: 100%;
			box-shadow: none !important;
			&:focus {
				border: none !important;
				background-color: #f8f8f8 !important;
				width: 100%;
				box-shadow: none !important;
			}
		}
		.manager-container {
			font-weight: 400;
			.manager-photo {
				margin-right: 20px;
			}
			.manager-photo.default {
				border: 1px solid black;
			}
		}
	}
	.field-container {
		div {
			input {
				border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
				&:focus {
					border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
					border-image-slice: 1 !important;
					border-bottom: 1px solid transparent !important;
				}
			}
			textarea {
				border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
		}
		.ui.selection.dropdown {
			border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		.checklist-container {
			border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		div.error {
			>input {
				border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
			>textarea {
				border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
		}
		.checklist-container.error {
			border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		.ui.selection.dropdown.error {
			border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		div.valid {
			>input {
				border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
			>textarea {
				border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
		}
		.ui.selection.dropdown.valid {
			border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		.checklist-container.valid {
			border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		.react-datepicker-wrapper {
			div {
				>input.valid {
					border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
					border-image-slice: 1 !important;
					border-bottom: 1px solid transparent !important;
				}
			}
		}
	}
	.field-container.required {
		div {
			>input {
				border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
			>textarea {
				border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
		}
		.checklist-container {
			border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		.ui.selection.dropdown {
			border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		.react-datepicker-wrapper {
			div {
				>input.error {
					border-image: linear-gradient(to right, rgb(255, 255, 255) -10%, #E34B22 50%, rgb(255, 255, 255) 110%) !important;
					border-image-slice: 1 !important;
					border-bottom: 1px solid transparent !important;
				}
				>input.valid {
					border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
					border-image-slice: 1 !important;
					border-bottom: 1px solid transparent !important;
				}
			}
		}
		div.valid {
			>input {
				border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
			>textarea {
				border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
				border-image-slice: 1 !important;
				border-bottom: 1px solid transparent !important;
			}
		}
		.checklist-container.valid {
			border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
		.ui.selection.dropdown.valid {
			border-image: linear-gradient(to right, #f3f4f5 0%, #66b283 23%, #e34b22 47%, #dda749 71%, #f3f4f5 91%, #f3f4f5 91%) !important;
			border-image-slice: 1 !important;
			border-bottom: 1px solid transparent !important;
		}
	}
}
